/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthHeader from "./auth-header";
import UrlService from "./url.service";

const API_URL = UrlService.API_URL + "auth/";

const register = (
  firstname: any,
  lastname: any,
  email: any,
  password: any,
  specialmode: any,
  emailused: any
) => {
  return axios.post(API_URL + "signup", {
    firstname,
    lastname,
    email,
    password,
    specialmode,
    emailused,
  });
};

const registerprovider = (
  providerbusinessname: any,
  firstname: any,
  lastname: any,
  contactnumber: any,
  email: any,
  password: any,
  specialmode: any,
  emailused: any
) => {
  return axios.post(API_URL + "signupprovider", {
    providerbusinessname,
    firstname,
    lastname,
    contactnumber,
    email,
    password,
    specialmode,
    emailused,
  });
};

const login = (email: any, password: any, specialmode: any) => {
  return axios
    .post(API_URL + "signin", {
      email,
      password,
      specialmode,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const activateaccount = (activationcode: any) => {
  return axios
    .post(API_URL + "activateaccount", {
      activationcode,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const forgotpassword = (email: any, specialmode: any) => {
  return axios.post(API_URL + "forgotpassword", {
    email,
    specialmode,
  });
};

const checkresetpasswordcode = (resetpasswordcode: any) => {
  return axios
    .post(API_URL + "checkresetpasswordcode", {
      resetpasswordcode,
    })
    .then((response) => {
      return response.data;
    });
};

const resetpassword = (
  password: any,
  confirmpassword: any,
  resetpasswordcode: any
) => {
  return axios.post(API_URL + "resetpassword", {
    password,
    confirmpassword,
    resetpasswordcode,
  });
};

const editaccountdetails = (
  providerbusinessname: any,
  providerbusinessdescription: any,
  providerbusinessaddress: any,
  firstname: any,
  lastname: any,
  contactno1: any,
  contactno2: any,
  city: any,
  state: any,
  zipcode: any,
  citylocationlat: any,
  citylocationlng: any,
  birthmonth: any,
  birthday: any,
  email: any,
  licensedtooperate: any,
  servicetype1: any,
  servicetype2: any,
  servicetype3: any,
  servicetype4: any,
  servicetype5: any,
  servicetype6: any,
  servicetype7: any,
  servicetype8: any,
  servicetype9: any,
  servicetype10: any,
  servicetype11: any,
  servicetype12: any,
  servicetype13: any,
  servicetype14: any,
  servicetype15: any,
  servicetypeavailability1: any,
  servicetypeavailability2: any,
  servicetypeavailability3: any,
  servicetypeavailability4: any,
  servicetypeavailability5: any,
  servicetypeavailability6: any,
  servicetypeavailability7: any,
  servicetypeavailability8: any,
  servicetypeavailability9: any,
  servicetypeavailability10: any,
  servicetypeavailability11: any,
  servicetypeavailability12: any,
  servicetypeavailability13: any,
  servicetypeavailability14: any,
  servicetypeavailability15: any,
  detailslisting1: any,
  detailslisting2: any,
  detailslisting3: any,
  specialmode: any,
  detailslistingmode: any,
  locationcoordinates: any,
  foronhold: any
) => {
  return axios
    .post(
      API_URL + "editaccountdetails",
      {
        providerbusinessname,
        providerbusinessdescription,
        providerbusinessaddress,
        firstname,
        lastname,
        contactno1,
        contactno2,
        city,
        state,
        zipcode,
        citylocationlat,
        citylocationlng,
        birthmonth,
        birthday,
        email,
        licensedtooperate,
        servicetype1,
        servicetype2,
        servicetype3,
        servicetype4,
        servicetype5,
        servicetype6,
        servicetype7,
        servicetype8,
        servicetype9,
        servicetype10,
        servicetype11,
        servicetype12,
        servicetype13,
        servicetype14,
        servicetype15,
        servicetypeavailability1,
        servicetypeavailability2,
        servicetypeavailability3,
        servicetypeavailability4,
        servicetypeavailability5,
        servicetypeavailability6,
        servicetypeavailability7,
        servicetypeavailability8,
        servicetypeavailability9,
        servicetypeavailability10,
        servicetypeavailability11,
        servicetypeavailability12,
        servicetypeavailability13,
        servicetypeavailability14,
        servicetypeavailability15,
        detailslisting1,
        detailslisting2,
        detailslisting3,
        specialmode,
        detailslistingmode,
        locationcoordinates,
        foronhold,
      },
      { headers: AuthHeader.authHeader() }
    )
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const changepassword = (
  password: any,
  newpassword: any,
  confirmnewpassword: any,
  specialmode: any
) => {
  return axios.post(
    API_URL + "changepassword",
    {
      password,
      newpassword,
      confirmnewpassword,
      specialmode,
    },
    { headers: AuthHeader.authHeader() }
  );
};

const editmap = (citylocationlat: any, citylocationlng: any) => {
  return axios
    .post(
      API_URL + "editmap",
      {
        citylocationlat,
        citylocationlng,
      },
      { headers: AuthHeader.authHeader() }
    )
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const uploadprofilepicture = (fileData: any) => {
  return axios.post(
    API_URL + "uploadprofilepicture",
    {
      fileData,
    },
    { headers: AuthHeader.authHeaderwithFile() }
  );
};

const logout = () => {
  localStorage.removeItem("user");
};

const galleryview = (filtercondition1: any) => {
  return axios
    .post(API_URL + "galleryview", {
      filtercondition1,
    })
    .then((response) => {
      localStorage.setItem("galleryraw", JSON.stringify(response.data.data));
      return response.data.data;
    });
};

const galleryviewbyuseruniquenumber = (idoftheprovider: any) => {
  return axios
    .post(API_URL + "galleryviewbyuseruniquenumber", {
      idoftheprovider,
    })
    .then((response) => {
      localStorage.setItem("galleryraw", JSON.stringify(response.data.data));
      return response.data.data;
    });
};

const adminlist1 = (filtercondition1: any) => {
  return axios
    .post(
      API_URL + "adminlist1",
      { filtercondition1 },
      { headers: AuthHeader.authHeader() }
    )
    .then((response) => {
      localStorage.setItem("adminlist1raw", JSON.stringify(response.data.data));
      localStorage.setItem(
        "adminlist1raw2",
        JSON.stringify(response.data.data2)
      );
      return response.data;
    });
};

const adminlist2 = (filtercondition1: any) => {
  return axios
    .post(
      API_URL + "adminlist2",
      { filtercondition1 },
      { headers: AuthHeader.authHeader() }
    )
    .then((response) => {
      localStorage.setItem("adminlist2raw", JSON.stringify(response.data.data));
      return response.data.data;
    });
};

const adminlist3 = (filtercondition1: any) => {
  return axios
    .post(
      API_URL + "adminlist3",
      { filtercondition1 },
      { headers: AuthHeader.authHeader() }
    )
    .then((response) => {
      localStorage.setItem("adminlist3raw", JSON.stringify(response.data.data));
      return response.data.data;
    });
};

const saveadminlist1 = (
  subscribedandapproved: any,
  useruniquenumber: any,
  paidtypeannually: any
) => {
  return axios
    .post(
      API_URL + "saveadminlist1",
      {
        subscribedandapproved,
        useruniquenumber,
        paidtypeannually,
      },
      { headers: AuthHeader.authHeader() }
    )
    .then((response) => {
      localStorage.setItem("adminlist1raw", JSON.stringify(response.data.data));
      localStorage.setItem(
        "adminlist1raw2",
        JSON.stringify(response.data.data2)
      );
      return response.data;
    });
};

const saveadminlist2 = (useruniquenumber: any) => {
  return axios
    .post(
      API_URL + "saveadminlist2",
      { useruniquenumber },
      { headers: AuthHeader.authHeader() }
    )
    .then((response) => {
      localStorage.setItem("adminlist2raw", JSON.stringify(response.data.data));
      return response.data.data;
    });
};

const saveadminlist3 = (donotsendemail: any, useruniquenumber: any) => {
  return axios
    .post(
      API_URL + "saveadminlist3",
      { donotsendemail, useruniquenumber },
      { headers: AuthHeader.authHeader() }
    )
    .then((response) => {
      localStorage.setItem("adminlist3raw", JSON.stringify(response.data.data));
      return response.data.data;
    });
};

const contactrequestform = (
  firstname: any,
  lastname: any,
  email: any,
  contactnumber: any,
  bestdaymon: any,
  bestdaytue: any,
  bestdaywed: any,
  bestdaythu: any,
  bestdayfri: any,
  bestdaysat: any,
  bestdaysun: any,
  besttime: any,
  provideruseruniquenumber: any
) => {
  return axios.post(API_URL + "contactrequestform", {
    firstname,
    lastname,
    email,
    contactnumber,
    bestdaymon,
    bestdaytue,
    bestdaywed,
    bestdaythu,
    bestdayfri,
    bestdaysat,
    bestdaysun,
    besttime,
    provideruseruniquenumber,
  });
};

const schedule = (
  useruniquenumber: any,
  providerbusinessname: any,
  dateandtime: any,
  name: any,
  email: any,
  contactnumber: any
) => {
  return axios.post(API_URL + "schedule", {
    useruniquenumber,
    providerbusinessname,
    dateandtime,
    name,
    email,
    contactnumber,
  });
};

const trackvisitors = (
  country_code: any,
  country_name: any,
  city: any,
  postal: any,
  latitude: any,
  longitude: any,
  IPv4: any,
  state: any
) => {
  return axios.post(API_URL + "trackvisitors", {
    country_code,
    country_name,
    city,
    postal,
    latitude,
    longitude,
    IPv4,
    state,
  });
};

export default {
  register,
  registerprovider,
  login,
  activateaccount,
  forgotpassword,
  checkresetpasswordcode,
  resetpassword,
  editaccountdetails,
  changepassword,
  editmap,
  uploadprofilepicture,
  logout,
  galleryview,
  galleryviewbyuseruniquenumber,
  adminlist1,
  adminlist2,
  adminlist3,
  saveadminlist1,
  saveadminlist2,
  saveadminlist3,
  contactrequestform,
  schedule,
  trackvisitors,
};
