/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

/* Components */
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./RegisterProvider.css";
/* Icons */
import { personCircle } from "ionicons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
/* Actions */
import { registerSuccess, registerFailed } from "../actions/auth";
import { setMessage } from "../actions/message";
/* Services */
import AuthService from "../services/auth.service";
/* Others */
import { history } from "../helpers/history";
import { isEmail } from "validator";

const eyeshown = <FontAwesomeIcon icon={faEye} />;
const eyehidden = <FontAwesomeIcon icon={faEyeSlash} />;

function validatePassword(password: string) {
  const re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*^()_-`~{}|]).{8,}/;
  return re.test(password);
}
const RegisterProvider: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  /* const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [providerbusinessname, setProviderbusinessname] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [contactnumber, setContactnumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cpassword, setCpassword] = useState<string>("");
  const [showpassword, setShowpassword] = useState<boolean>(false);
  const [showcpassword, setShowcpassword] = useState<boolean>(false);
  const [iserror, setIserror] = useState<boolean>(false);
  const [showloading, setShowloading] = useState<boolean>(false);
  const [showmodal, setShowmodal] = useState<boolean>(false);
  const [isregister, setIsregister] = useState<boolean>(false);
  const [messagelocal, setMessagelocal] = useState<string>("");
  //const inputRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setMessagelocal(
        "A User is currently logged on. Please logout first then try again."
      );
      setIserror(true);
    }
  }, []);

  useEffect(() => {
    /* var timerId: NodeJS.Timeout;
    history.listen((location: any) => {
      if (location.pathname === "/registerprovider") {
        if (inputRef.current) {
          timerId = setTimeout(() => inputRef.current.setFocus(), 100);
        }
      }
      if (location.pathname !== "/registerprovider") {
        return () => clearTimeout(timerId);
      }
    }); */
  }, []);

  useEffect(() => {
    /* var timeout: NodeJS.Timeout;
    timeout = setTimeout(() => {
      inputRef.current.setFocus();
    }, 1000);
    return () => clearTimeout(timeout); */
  }, []);

  let pagename = "Register";

  const togglePasswordVisiblity = () => {
    setShowpassword(showpassword ? false : true);
  };
  const toggleCPasswordVisiblity = () => {
    setShowcpassword(showcpassword ? false : true);
  };

  const handleRegister = () => {
    /* if (!providerbusinessname) {
      setMessagelocal("Please enter your Care Home Provider Business Name");
      setIserror(true);
      return;
    } */
    if (providerbusinessname.length > 50) {
      setMessagelocal(
        "Care Home Provider Business Name should not be more than 50 characters"
      );
      setIserror(true);
      return;
    }
    if (!firstname) {
      setMessagelocal("Please enter your First Name");
      setIserror(true);
      return;
    }
    if (firstname.length > 50) {
      setMessagelocal("First Name should not be more than 50 characters");
      setIserror(true);
      return;
    }
    if (!lastname) {
      setMessagelocal("Please enter your Last Name");
      setIserror(true);
      return;
    }
    if (lastname.length > 50) {
      setMessagelocal("Last Name should not be more than 50 characters");
      setIserror(true);
      return;
    }
    if (!contactnumber) {
      setMessagelocal("Please enter your Contact Number");
      setIserror(true);
      return;
    }
    if (!email) {
      setMessagelocal("Please enter your Email / Username");
      setIserror(true);
      return;
    }
    /* if (isEmail(email) === false) {
      setMessagelocal("Your email is invalid");
      setIserror(true);
      return;
    } */
    if (!password) {
      setMessagelocal("Please enter your password");
      setIserror(true);
      return;
    }
    if (password.length < 8 || password.length > 12) {
      setMessagelocal(
        "Password should not be less than 8 and not more than 12 characters"
      );
      setIserror(true);
      return;
    }
    if (validatePassword(password) === false) {
      setMessagelocal(
        "Password must contain at least one number, special character and one uppercase and lowercase letter, and at least 8 up to 12 characters"
      );
      setIserror(true);
      return;
    }
    if (!cpassword) {
      setMessagelocal("Please enter again your password to confirm");
      setIserror(true);
      return;
    }
    if (cpassword.length < 8 || cpassword.length > 12) {
      setMessagelocal(
        "Confirm Password should not be less than 8 and not more than 12 characters"
      );
      setIserror(true);
      return;
    }
    if (validatePassword(cpassword) === false) {
      setMessagelocal(
        "Confirm Password must contain at least one number, special character and one uppercase and lowercase letter, and at least 8 up to 12 characters"
      );
      setIserror(true);
      return;
    }
    if (password !== cpassword) {
      setMessagelocal("Passwords do not match. Please try again.");
      setIserror(true);
      return;
    }

    const emailused = isEmail(email);

    setShowloading(true);

    if (providerbusinessname === "seniorHUBsolutions") {
      const specialmode = true; //bbbb ? true : false;
      AuthService.register(
        firstname,
        lastname,
        email,
        password,
        specialmode,
        emailused
      ).then(
        (response: any) => {
          dispatch(registerSuccess());
          //dispatch(setMessage(response.data.message));
          setMessagelocal(response.data.message);
          setShowloading(false);
          setIsregister(true);
          //return Promise.resolve();
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(registerFailed());
          dispatch(setMessage(message));
          setMessagelocal(message);
          setShowloading(false);
          setIserror(true);
          //return Promise.reject();
        }
      );
    } else {
      const specialmode = false; //bbbb ? true : false;
      AuthService.registerprovider(
        providerbusinessname,
        firstname,
        lastname,
        contactnumber,
        email,
        password,
        specialmode,
        emailused
      ).then(
        (response: any) => {
          dispatch(registerSuccess());
          //dispatch(setMessage(response.data.message));
          setMessagelocal(response.data.message);
          setShowloading(false);
          setIsregister(true);
          //return Promise.resolve();
        },
        (error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          dispatch(registerFailed());
          dispatch(setMessage(message));
          setMessagelocal(message);
          setShowloading(false);
          setIserror(true);
          //return Promise.reject();
        }
      );
    }
  };

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <IonHeader className="fixed-top">
        <IonToolbar
          color="primary"
          id="backgroundwhitebrand"
          style={{
            height: "0px",
          }}
        ></IonToolbar>
        <nav
          className="navbar navbar-expand-md"
          aria-label="Main navigation"
          style={{
            height: "66px",
            backgroundImage: "url(" + require("../assets/Background.jpg").default + ")",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></nav>
        <IonToolbar
          id="backgroundwhitebrand"
          /* color="primary" */
          style={{
            backgroundColor: "white",
          }}
        >
          <IonButtons
            slot="start"
            style={{
              width: "100vw",
            }}
          >
            <IonButton
              onClick={() => {
                history.push("/valuedcareproviders");
              }}
              style={{
                color: "black",
              }}
            >
              Back
            </IonButton>
            <IonTitle
              style={{
                textAlign: "center",
              }}
            >
              <IonLabel
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "Gill Sans Nova",
                  color: "black",
                  fontSize: "20px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;senior
                <a
                  style={{
                    fontFamily: "Gill Sans Nova",
                    color: "black",
                    fontSize: "1.1em",
                    fontWeight: "bold",
                  }}
                >
                  HUB
                </a>
                solutions
              </IonLabel>
            </IonTitle>
            {/* {platformusedglobal !== "ios" && ( */}
            <a
              style={{
                color: "white", //#ffcc00
                width: "57px",
                marginLeft: "2px",
                marginRight: "2px",
                textAlign: "center",
                fontSize: "medium",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {/* )} */}
          </IonButtons>
        </IonToolbar>
        <div className="border-top border-bottom" />
      </IonHeader>

      <div style={{ height: "20vh" }}></div>
      <div
        className="container text-center"
        style={{ color: "black", maxWidth: "520px" }}
      >
        <IonModal
          id="heightmodal"
          isOpen={showmodal}
          onDidDismiss={() => setShowmodal(false)}
        >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle
                className="ion-text-center"
                style={{ fontSize: "large", color: "#8f6b00" }}
              >
                Terms & Conditions
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonItem
            id="backgroundwhiteitem"
            style={{ width: "100%", height: "100%", padding: "0" }}
          >
            <IonCard
              id="backgroundwhiteitem"
              style={{
                width: "100%",
                height: "100%",
                padding: "0",
              }}
            >
              <IonCardContent id="backgroundwhiteitem">
                <div className="container">
                  <div style={{ height: "24px" }}></div>
                  <h1
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    <b>I HAVE READ AND AGREE TO THE TERMS AND CONDITIONS</b>
                  </h1>
                  <div style={{ height: "12px" }}></div>
                  <p
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    By purchasing this annual member service from Senior Hub
                    Solutions, you agree that we can contact you in the future,
                    use your information to deliver services or related content.
                    You waive all rights to recourse against Senior Hub
                    Solutions, our members, and affiliates because of your
                    actions. Below is the detail of that agreement.
                  </p>
                  <div style={{ height: "6px" }}></div>
                  <p
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    When you submit information and photos to us, we use it in
                    an ongoing nature to ensure you receive the information or
                    purchases you have made with us to deliver relevant content
                    via email, newsletters, instant messaging, marketing
                    platforms, i.e., to deliver your business content on our
                    site. By giving us your contact information now you are
                    granting us the right to contact you in the future in any
                    manner necessary at our discretion for ongoing professional
                    development. Should you have any questions, contact Senior
                    Hub Solutions via email at
                    memberservices@seniorhubsolutions.com
                  </p>
                  <div style={{ height: "6px" }}></div>
                  <p
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    All services by our company are for informational purposes
                    only. Your level of success in attaining any stated ideas or
                    strategies on our site is dependent upon several factors
                    that differ per individual therefore, we cannot guarantee
                    your specific success or results in any area of your
                    business endeavor.&nbsp;
                    <b>
                      We make no guarantees that you will achieve any specific
                      results from our member services.
                    </b>
                    &nbsp; We offer no licensed or professional medical,
                    therapeutic, tax or financial advice. The information
                    contained herein cannot replace or substitute for the
                    services of trained professionals in any field, including,
                    but not limited to, medical, psychological, financial, or
                    legal matters. You alone are responsible and accountable for
                    your decisions, actions, and results. By your registration
                    here today and use of our member services at any time, you
                    agree not to attempt to hold us liable for any such
                    decisions, actions, or results at any time under any
                    circumstance.
                  </p>
                </div>
              </IonCardContent>
              <IonButton
                expand="block"
                onClick={() => setShowmodal(false)}
                style={{
                  margin: "0px",
                  color: "#8f6b00",
                  width: "100%",
                  fontSize: "medium",
                  /* height: "56px", */
                }}
              >
                I Agree
              </IonButton>
            </IonCard>
          </IonItem>
          <IonLabel id="backgroundwhiteitem" className="border-top">
            &nbsp;
          </IonLabel>
        </IonModal>

        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => {
            setIserror(false);
            if (currentUser) {
              history.push("/valuedcareproviders");
            }
          }}
          cssClass="ionbackgroundclass"
          header={"Error!"}
          message={messagelocal}
          buttons={["Dismiss"]}
        />
        <IonAlert
          isOpen={isregister}
          /* onDidDismiss={() => setIsregister(false)} */
          cssClass="ionbackgroundclass"
          header={"Create Account"}
          message={messagelocal}
          buttons={[
            {
              text: "OK",
              handler: () => {
                setIsregister(false);
                history.push("/valuedcareproviders");
              },
            },
          ]}
        />
        <IonLoading
          cssClass="ionbackgroundclass"
          isOpen={showloading}
          onDidDismiss={() => setShowloading(false)}
          message={"Please wait..."}
          duration={60000}
        />
        <main className="form-signin">
          <form>
            <IonIcon
              className="mb-4"
              color="primary"
              style={{ fontSize: "70px" }}
              icon={personCircle}
            />
            <h1
              className="h3 mb-3 fw-normal"
              style={{ fontFamily: "Abadi MT Std" }}
            >
              Get Started
            </h1>
            {/* <div className="form-floating">
              <input
                //ref={(ref) => (inputRef.current = ref)}
                type="text"
                className="form-control"
                //id="floatingInput"
                //placeholder="ABC Company, Inc."
                value={providerbusinessname}
                onChange={(e) => setProviderbusinessname(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              />
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Care Home Provider Business Name
              </label>
            </div> */}
            {/* <div style={{ height: "6px" }}></div> */}
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                /* id="floatingInput" */
                /* placeholder="John" */
                value={firstname}
                onChange={(e) => setFirstname(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              />
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * First Name
              </label>
            </div>
            <div style={{ height: "6px" }}></div>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                /* id="floatingInput" */
                /* placeholder="Doe" */
                value={lastname}
                onChange={(e) => setLastname(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              />
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Last Name
              </label>
            </div>
            <div style={{ height: "6px" }}></div>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                /* id="floatingInput" */
                /* placeholder="09154832628" */
                value={contactnumber}
                onChange={(e) => setContactnumber(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              />
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Contact Number
              </label>
            </div>
            <div style={{ height: "6px" }}></div>
            <div className="form-floating">
              <input
                type="email"
                className="form-control"
                /* id="floatingInput" */
                /* placeholder="name@example.com" */
                value={email}
                onChange={(e) => setEmail(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              />
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Email / Username
              </label>
            </div>
            <div style={{ height: "6px" }}></div>
            <div className="form-floating">
              <input
                type={showpassword ? "text" : "password"}
                className="form-control"
                /* id="floatingPassword" */
                /* placeholder="Password" */
                value={password}
                onChange={(e) => setPassword(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              ></input>
              <i
                onClick={togglePasswordVisiblity}
                /* style={{ position: "absolute", top: "-6px", height: "16px" }} */
              >
                {showpassword ? eyehidden : eyeshown}
              </i>
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Password
              </label>
            </div>
            <div style={{ height: "6px" }}></div>
            <div className="form-floating">
              <input
                type={showcpassword ? "text" : "password"}
                className="form-control"
                /* id="floatingPassword" */
                /* placeholder="Confirm Password" */
                value={cpassword}
                onChange={(e) => setCpassword(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              ></input>
              <i
                onClick={toggleCPasswordVisiblity}
                /* style={{ position: "absolute", top: "-6px", height: "16px" }} */
              >
                {showcpassword ? eyehidden : eyeshown}
              </i>
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Confirm Password
              </label>
            </div>

            <div style={{ height: "12px" }}></div>
            <p
              style={{
                fontSize: "small",
                padding: "0px",
                margin: "0px",
                color: "black",
                fontFamily: "Abadi MT Std",
              }}
            >
              {/* By clicking Join Now!, you agree to seniorHUBsolutions Member
              Service &nbsp; */}
              <b>"Call Today!"</b> 808.999.9830
              {/* <a
                style={{
                  textDecorationLine: "underline",
                  cursor: "pointer",
                  fontFamily: "Abadi MT Std",
                }}
                onClick={() => setShowmodal(true)}
              >
                Terms & Conditions
              </a> */}
            </p>
            <p
              style={{
                fontSize: "small",
                padding: "0px",
                margin: "0px",
                color: "black",
                fontFamily: "Abadi MT Std",
              }}
            >
              A seniorHUBsolutions team member will be happy to speak with you.
            </p>
            <div style={{ height: "6px" }}></div>

            <IonButton
              expand="block"
              onClick={handleRegister}
              style={{ color: "#8f6b00", height: "56px", fontSize: "large" }}
            >
              See All Features
            </IonButton>
            <div style={{ height: "6px" }}></div>
            <p
              style={{
                fontSize: "small",
                padding: "0px",
                margin: "0px",
                color: "black",
                fontFamily: "Abadi MT Std",
              }}
            >
              I agree to receive recurring information and promotional texts at
              my mobile number and/or my email address from seniorHUBsolutions.
              Msg & Data rates may apply, check with your phone provider for
              more details.
            </p>
            <div style={{ height: "6px" }}></div>
          </form>
        </main>
      </div>

      <div style={{ height: "24px" }}></div>

      <div>
        <FooterMain />
        <Footer />
        {localStorage.getItem("isnative") &&
          localStorage.getItem("isnative") === "true" && <FooterMobile />}
      </div>
    </IonContent>
  );
};

export default RegisterProvider;
