/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import { IonContent } from "@ionic/react";

/* Components */
import NavBar from "../components/NavBar";
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./ValuedCareProviders.css";
/* Others */
import { history } from "../helpers/history";

const ValuedCareProviders: React.FC = () => {
  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [hover1, setHover1] = useState<boolean>(false);
  const [hover2, setHover2] = useState<boolean>(false);

  useEffect(() => {
    const platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);

    const isnative: any = localStorage.getItem("isnative")
      ? localStorage.getItem("isnative")
      : "";

    setIsnativeusedglobal(isnative);

    window.addEventListener("message", (event) => {
      if (event.data && event.data.sender === "links") {
        localStorage.setItem("links", event.data.message);
        window.location.reload();
      }
    });
  }, []);

  async function launchLink1() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.google.com/",
        },
        "*"
      );
    } else {
      window.open("https://www.google.com/", "_system");
    }
  }
  async function launchLink2() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.google.com/",
        },
        "*"
      );
    } else {
      window.open("https://www.google.com/", "_system");
    }
  }

  function togglehover1() {
    setHover1(!hover1);
  }
  function togglehover2() {
    setHover2(!hover2);
  }

  function toggleactive1() {
    history.push("/login");
  }
  function toggleactive2() {
    history.push("/registerprovider");
  }

  let pagename = "Valued Care Providers";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <NavBar />

      <div style={{ height: "20vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <div className="container">
          <div className="container-fluid py-5">
            {/* <h1
              className="featurette-heading"
              style={{
                marginTop: "10px",
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                color: "#8A1F61",
                textShadow: "-1px 1px 2px #000",
              }}
            >
              {pagename}
            </h1> */}
            {/* <div
              style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
            <div style={{ height: "24px" }}></div> */}

            <div
              className="container row align-items-center"
              style={{ margin: "0px", padding: "0px", height: "100%" }}
            >
              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>

              <div
                className="col-lg-10 text-center"
                style={{
                  margin: "0px",
                  padding: "0px",
                  border: "10px solid #00B3B3",
                }}
              >
                <div
                  id="vcpimage"
                  className="container"
                  style={{
                    /* backgroundImage:
                    "url('" +
                    require("../assets/ValuedCareProviders.jpg").default +
                    "')", */
                    backgroundSize: "100% 100%",
                    objectFit: "cover",
                    /* height: "90vh", */
                    border: "6px solid gray",
                  }}
                >
                  <div
                    className="container row align-items-center"
                    style={{ margin: "0px", padding: "0px", height: "100%" }}
                  >
                    <div
                      className="col-lg-6 text-center"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <div style={{ height: "0px" }}></div>
                    </div>
                    <div
                      className="col-lg-6 text-center"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <div style={{ height: "12px" }}></div>
                      <h1
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          color: "black",
                          /* textShadow: "-1px 1px 2px #000", */
                        }}
                      >
                        {pagename}
                      </h1>
                      <div style={{ height: "12px" }}></div>
                      <p
                        id="vcpcontentindentation"
                        className="lead"
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "large",
                        }}
                      >
                        <b>
                          Our network of Valued Care Providers fosters safe
                          personal care for the residents in the Adult
                          Residential Care Home and Adult Foster Care Home
                          Communities.
                        </b>
                      </p>
                      <div style={{ height: "12px" }}></div>
                      <p
                        id="vcpcontentindentation"
                        className="lead"
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "large",
                        }}
                      >
                        <b>
                          These homes provide individuality, dignity, choice,
                          independence, and resident privacy. Valued Care
                          Providers are highly skilled and organized. Experience
                          with Alzheimer’s and dementia care, empathy, and the
                          ability to care for the resident’s social, emotional,
                          and physical needs, and the understanding of what to
                          do in an emergency. They recognize the rights and
                          responsibilities of the residents and provide care
                          that can be culturally sensitive.
                        </b>
                      </p>
                      <div style={{ height: "12px" }}></div>
                      <p
                        id="vcpcontentindentation"
                        className="lead"
                        style={{
                          color: "black",
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "large",
                        }}
                      >
                        <b>
                          The Valued Care Providers collaborate closely with
                          physicians and case managers to target the appropriate
                          care needed so that the resident’s outcome is a
                          positive one.
                        </b>
                      </p>

                      <div className="container" style={{ padding: "0px" }}>
                        <p
                          className="nav d-flex align-items-center justify-content-center "
                          style={{ paddingRight: "0px" }}
                        >
                          <a
                            className="btn btn-lg btn-secondary align-items-center"
                            style={
                              hover2
                                ? {
                                    fontSize: "large",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "212px",
                                    margin: "0px",
                                    textAlign: "center",
                                    backgroundColor: "#ffe685",
                                    marginTop: "12px",
                                    marginLeft: "6px",
                                    marginRight: "6px",
                                  }
                                : {
                                    fontSize: "large",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "212px",
                                    margin: "0px",
                                    textAlign: "center",
                                    backgroundColor: "#ffcc00",
                                    marginTop: "12px",
                                    marginLeft: "6px",
                                    marginRight: "6px",
                                  }
                            }
                            onMouseEnter={togglehover2}
                            onMouseLeave={togglehover2}
                            onClick={toggleactive2}
                          >
                            Get Started
                          </a>
                          {/* <a
                            style={{
                              textAlign: "center",
                              color: "white",
                              width: "50px",
                              marginTop: "12px",
                              fontSize: "20px",
                            }}
                          >
                            <b
                              style={{
                                color: "black",
                                fontSize: "large",
                                textShadow: "-1px 1px 2px #000",
                              }}
                            >
                              &nbsp;&nbsp;
                            </b>
                          </a> */}
                          <a
                            className="btn btn-lg btn-secondary align-items-center"
                            style={
                              hover1
                                ? {
                                    fontSize: "large",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "212px",
                                    margin: "0px",
                                    textAlign: "center",
                                    backgroundColor: "#ffe685",
                                    marginTop: "12px",
                                    marginLeft: "6px",
                                    marginRight: "6px",
                                  }
                                : {
                                    fontSize: "large",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "212px",
                                    margin: "0px",
                                    textAlign: "center",
                                    backgroundColor: "#ffcc00",
                                    marginTop: "12px",
                                    marginLeft: "6px",
                                    marginRight: "6px",
                                  }
                            }
                            onMouseEnter={togglehover1}
                            onMouseLeave={togglehover1}
                            onClick={toggleactive1}
                          >
                            Manage My Account
                          </a>
                        </p>
                      </div>
                      <div style={{ height: "12px" }}></div>
                      <div className="container" style={{ padding: "0px" }}>
                        <p
                          className="nav d-flex align-items-center justify-content-center "
                          style={{
                            paddingRight: "0px",
                            fontSize: "x-large",
                            color: "black",
                          }}
                        >
                          Create your FREE Valued Care Provider Profile Now!
                        </p>
                      </div>
                      <div style={{ height: "24px" }}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>
            </div>

            <div style={{ height: "24px" }}></div>

            <div
              className="container row align-items-center"
              style={{ margin: "0px", padding: "0px", height: "100%" }}
            >
              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>

              {/* <div
                className="col-lg-10 text-center"
                style={{
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <iframe
                  src="https://www.vcita.com/widgets/sidebar/fttf72utppextiiv?frontage_iframe=true"
                  width="100%"
                  height="200px"
                  scrolling="no"
                  style={
                    {
                      
                    }
                  }
                ></iframe>
              </div> */}

              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>
            </div>

            <div style={{ height: "36px" }}></div>
          </div>
        </div>

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default ValuedCareProviders;
