/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import { IonContent } from "@ionic/react";

/* Components */
import NavBar from "../components/NavBar";
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./CaseManagement.css";
/* Others */
//import { history } from "../helpers/history";

const CaseManagement: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  useEffect(() => {}, []);

  let pagename = "Case Management";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <NavBar />

      <div style={{ height: "20vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <div className="container">
          <div className="container-fluid py-5">
            {/* <h1
              className="featurette-heading"
              style={{
                marginTop: "10px",
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                color: "#8A1F61",
                textShadow: "-1px 1px 2px #000",
              }}
            >
              {pagename}
            </h1> */}
            {/* <div
              style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
            <div style={{ height: "24px" }}></div> */}

            <div
              className="container row align-items-center"
              style={{ margin: "0px", padding: "0px", height: "100%" }}
            >
              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>

              <div
                className="col-lg-10 text-center"
                style={{
                  margin: "0px",
                  padding: "0px",
                  border: "10px solid #65A047",
                }}
              >
                <div
                  id="cmimage"
                  className="container"
                  style={{
                    /* backgroundImage:
                    "url('" +
                    require("../assets/CaseManagement.jpg").default +
                    "')", */
                    backgroundSize: "100% 100%",
                    objectFit: "cover",
                    /* height: "90vh", */
                    /* border: "6px solid gray", */
                  }}
                >
                  <div
                    className="container row align-items-center"
                    style={{ margin: "0px", padding: "0px", height: "100%" }}
                  >
                    <div
                      className="col-lg-7 text-center"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <div style={{ height: "0px" }}></div>
                    </div>
                    <div
                      className="col-lg-5 text-center"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <div style={{ height: "12px" }}></div>
                      <h1
                        id="cmheadercolor"
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          /* color: "#ffcc00", */
                          /* textShadow: "-1px 1px 2px #000", */
                        }}
                      >
                        {pagename}
                      </h1>
                      <div style={{ height: "12px" }}></div>
                      <p
                        id="cmcontentindentation"
                        className="lead"
                        style={{
                          /* color: "#ffcc00", */
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "medium",
                        }}
                      >
                        <b>
                          Certified case managers are specialists who assist
                          seniors, people with special needs, and their families
                          in planning for and implementing ways to allow for the
                          greatest degree of health, safety, independence, and
                          quality of life.
                        </b>
                      </p>
                      <div style={{ height: "12px" }}></div>
                      <p
                        id="cmcontentindentation"
                        className="lead"
                        style={{
                          /* color: "#ffcc00", */
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "medium",
                        }}
                      >
                        <b>
                          Case managers perform many roles and tasks, but their
                          chief ones are to present options to the seniors and
                          their family. For seniors at a nursing home level of
                          care and choose to live in an Adult Foster Care Home
                          (AFCH) or Expanded Adult Residential Care Home
                          (E-ARCH), case management services are required. The
                          case manager will devise a personalized care plan that
                          is based on each person&#39;s unique situation. The
                          case manager will continue to monitor the care
                          provided to the client and communicate with the family
                          and caregiver(s) to ensure that the doctor&#39;s
                          orders are being followed. Case managers will visit as
                          scheduled or as requested by family or caregiver(s).
                          Each visit will consist of an evaluation of the client
                          status and appropriateness of placement as well as a
                          review of chart notes. The case manager will address
                          any concerns or issues and collaborate with family and
                          caregiver(s) to meet a client’s health requirements
                          and provide quality care efficiently.
                        </b>
                      </p>
                      {/* <div style={{ height: "12px" }}></div>
                      <p
                        id="cmcontentindentation"
                        className="lead"
                        style={{
                          color: "#ffcc00",
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "medium",
                        }}
                      >
                        <b>
                          The Valued Care Providers collaborate closely with
                          physicians and case managers to target the appropriate
                          care needed so that the resident’s outcome is a
                          positive one.
                        </b>
                      </p> */}

                      <div style={{ height: "24px" }}></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>
            </div>

            <div style={{ height: "36px" }}></div>
          </div>
        </div>

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default CaseManagement;
