/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import { IonContent } from "@ionic/react";

/* Components */
import NavBar from "../components/NavBar";
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./HealthCareProfessionalResources.css";
/* Others */
import { history } from "../helpers/history";

const ProfessionalResources: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [hover1, setHover1] = useState<boolean>(false);
  const [hover2, setHover2] = useState<boolean>(false);

  useEffect(() => {}, []);

  function togglehover1() {
    setHover1(!hover1);
  }
  function togglehover2() {
    setHover2(!hover2);
  }

  function toggleactive1() {
    history.push("/login");
  }
  function toggleactive2() {
    history.push("/registerprovider");
  }

  let pagename = "Health Care Professional Resources";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <NavBar />

      <div style={{ height: "20vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <div className="container">
          <div className="container-fluid py-5">
            {/* <h1
              className="featurette-heading"
              style={{
                marginTop: "10px",
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                color: "#8A1F61",
                textShadow: "-1px 1px 2px #000",
              }}
            >
              {pagename}
            </h1> */}
            {/* <div
              style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
            <div style={{ height: "24px" }}></div> */}

            <div
              className="container row align-items-center"
              style={{ margin: "0px", padding: "0px", height: "100%" }}
            >
              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>

              <div
                className="col-lg-10 text-center"
                style={{
                  margin: "0px",
                  padding: "0px",
                  border: "10px solid #8A1F61",
                }}
              >
                <div
                  id="hcpimage"
                  className="container"
                  style={{
                    /* backgroundImage:
                    "url('" +
                    require("../assets/HealthCareProfessionalResources.jpg").default +
                    "')", */
                    backgroundSize: "100% 100%",
                    objectFit: "cover",
                    /* height: "90vh", */
                    border: "6px solid white",
                  }}
                >
                  <div
                    className="container row align-items-center"
                    style={{ margin: "0px", padding: "0px", height: "100%" }}
                  >
                    {/* <div
                    className="col-lg-6 text-center"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    <div style={{ height: "0px" }}></div>
                  </div> */}
                    <div
                      className="col-lg-12 text-center"
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <div id="hcpblockcontainer1"></div>
                      <div style={{ height: "12px" }}></div>
                      <h1
                        id="hcpcontentindentation"
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          color: "white",
                          /* textShadow: "-1px 1px 2px #000", */
                        }}
                      >
                        {pagename}
                      </h1>
                      <div style={{ height: "12px" }}></div>
                      {/* <p
                        id="hcpcontentindentation"
                        className="lead"
                        style={{
                          color: "white",
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "large",
                        }}
                      >
                        <b>
                          Who are our Valued Health Care Professional Resources?
                        </b>
                      </p>
                      <div style={{ height: "12px" }}></div> */}
                      <p
                        id="hcpcontentindentation"
                        className="lead"
                        style={{
                          color: "white",
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "large",
                        }}
                      >
                        <b>
                          Our network of Health Care Professional Resources
                          includes geriatricians, physicians, hospitalists,
                          nurses, physician assistants, social workers, and a
                          multitude of other disciplines with unique skills for
                          evaluating and managing health and care for seniors.
                        </b>
                      </p>
                      <div style={{ height: "12px" }}></div>
                      <p
                        id="hcpcontentindentation"
                        className="lead"
                        style={{
                          color: "white",
                          textAlign: "justify",
                          fontFamily: "Abadi MT Std",
                          margin: "0px",
                          fontSize: "large",
                        }}
                      >
                        <b>
                          Caring for Hawaii’s Kūpuna extends beyond the
                          boundaries of standard care, and we are grateful to
                          our Health Care Professional Resources for being a
                          vital source of information. Of equal importance,
                          Health Care Professional Resources are critical in
                          helping our seniors and their families navigate
                          through unfamiliar and sometimes confusing details
                          about Adult Residential Care Homes, Adult Foster Care
                          Homes, and other Care Options.
                        </b>
                      </p>

                      <div style={{ height: "24px" }}></div>
                      <div id="hcpblockcontainer2"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>
            </div>

            <div style={{ height: "24px" }}></div>

            <div
              className="container row align-items-center"
              style={{ margin: "0px", padding: "0px", height: "100%" }}
            >
              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>

              {/* <div
                className="col-lg-10 text-center"
                style={{
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <iframe
                  id="hcpvcitaheight"
                  src="https://www.vcita.com/widgets/contact_form/fttf72utppextiiv?frontage_iframe=true"
                  width="100%"
                  scrolling="no"
                  style={
                    {
                      
                    }
                  }
                >
                  <p>Please contact me via my contact form at vcita:</p>
                  <a href="https://www.vcita.com/v/fttf72utppextiiv/contact?frontage_iframe=true&invite=vr_cf_pb-fttf72utppextiiv">
                    Contact Form for seniorHUBsolutions
                  </a>
                </iframe>
              </div> */}

              <div
                className="col-lg-1 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>
            </div>

            <div style={{ height: "36px" }}></div>
          </div>
        </div>

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default ProfessionalResources;
