/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { useParams } from "react-router";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

/* Components */
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./Login.css";
/* Icons */
import { personCircle } from "ionicons/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
/* Actions */
import { loginSuccess, loginFailed } from "../actions/auth";
import { setMessage } from "../actions/message";
/* Services */
import AuthService from "../services/auth.service";
/* Others */
import { history } from "../helpers/history";
import { isEmail } from "validator";

const jwt = require("jsonwebtoken");
const config = require("../config/auth.config.js");

const eyeshown = <FontAwesomeIcon icon={faEye} />;
const eyehidden = <FontAwesomeIcon icon={faEyeSlash} />;

const Login: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  /* const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [isfirstload, setIsfirstload] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showpassword, setShowpassword] = useState<boolean>(false);
  const [ischecked, setIschecked] = useState<boolean>(false);
  const [isadmin, setIsadmin] = useState<boolean>(false);
  const [iserror, setIserror] = useState<boolean>(false);
  const [showloading, setShowloading] = useState<boolean>(false);
  const [showmodal, setShowmodal] = useState<boolean>(false);
  const [messagelocal, setMessagelocal] = useState<string>("");
  //const inputRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      setMessagelocal(
        "A User is currently logged on. Please logout first then try again."
      );
      setIserror(true);
    }
  }, []);

  useEffect(() => {
    /* var timerId: NodeJS.Timeout;
    history.listen((location: any) => {
      if (location.pathname === "/login") {
        if (inputRef.current) {
          timerId = setTimeout(() => inputRef.current.setFocus(), 100);
        }
      }
      if (location.pathname !== "/login") {
        return () => clearTimeout(timerId);
      }
    }); */
  }, []);

  useEffect(() => {
    /* var timeout: NodeJS.Timeout;
    timeout = setTimeout(() => {
      inputRef.current.setFocus();
    }, 1000);
    return () => clearTimeout(timeout); */
  }, []);

  useEffect(() => {
    checkRememberme();
  }, []);

  let pagename = "Login";

  const togglePasswordVisiblity = () => {
    setShowpassword(showpassword ? false : true);
  };

  const toggleChecked = () => {
    setIschecked(ischecked ? false : true);
  };

  const toggleAdmin = () => {
    setIsadmin(isadmin ? false : true);
  };

  const checkRememberme = () => {
    if (isfirstload) {
      if (
        localStorage.checkbox &&
        localStorage.useremail !== "" &&
        localStorage.userpass !== ""
      ) {
        setIschecked(true);
        setEmail(localStorage.useremail);

        jwt.verify(
          localStorage.userpass,
          config.secret,
          (err: any, decoded: { pw: React.SetStateAction<string> }) => {
            setPassword(decoded.pw);
          }
        );
      }
    }
    setIsfirstload(false);
  };

  const handleLogin = () => {
    if (!email) {
      setMessagelocal("Please enter your Email / Username");
      setIserror(true);
      return;
    }
    /* if (isEmail(email) === false) {
      setMessagelocal("Your email is invalid");
      setIserror(true);
      return;
    } */
    if (!password) {
      setMessagelocal("Please enter your password");
      setIserror(true);
      return;
    }
    if (password.length < 8 || password.length > 12) {
      setMessagelocal(
        "Password should not be less than 8 and not more than 12 characters"
      );
      setIserror(true);
      return;
    }

    localStorage.useremail = "";
    localStorage.userpass = "";
    localStorage.checkbox = ischecked;
    if (ischecked) {
      if (email !== "") {
        localStorage.useremail = email;
      }
      if (password !== "") {
        localStorage.userpass = jwt.sign({ pw: password }, config.secret);
      }
    }

    setShowloading(true);
  };

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <IonHeader className="fixed-top">
        <IonToolbar
          color="primary"
          id="backgroundwhitebrand"
          style={{
            height: "0px",
          }}
        ></IonToolbar>
        <nav
          className="navbar navbar-expand-md"
          aria-label="Main navigation"
          style={{
            height: "66px",
            backgroundImage: "url(" + require("../assets/Background.jpg").default + ")",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></nav>
        <IonToolbar
          id="backgroundwhitebrand"
          /* color="primary" */
          style={{
            backgroundColor: "white",
          }}
        >
          <IonButtons
            slot="start"
            style={{
              width: "100vw",
            }}
          >
            <IonButton
              onClick={() => {
                history.push("/valuedcareproviders");
              }}
              style={{
                color: "black",
              }}
            >
              Back
            </IonButton>
            <IonTitle
              style={{
                textAlign: "center",
              }}
            >
              <IonLabel
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "Gill Sans Nova",
                  color: "black",
                  fontSize: "20px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;senior
                <a
                  style={{
                    fontFamily: "Gill Sans Nova",
                    color: "black",
                    fontSize: "1.1em",
                    fontWeight: "bold",
                  }}
                >
                  HUB
                </a>
                solutions
              </IonLabel>
            </IonTitle>
            {/* {platformusedglobal !== "ios" && ( */}
            <a
              style={{
                color: "white", //#ffcc00
                width: "57px",
                marginLeft: "2px",
                marginRight: "2px",
                textAlign: "center",
                fontSize: "medium",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {/* )} */}
          </IonButtons>
        </IonToolbar>
        <div className="border-top border-bottom" />
      </IonHeader>

      <div style={{ height: "20vh" }}></div>
      <div
        className="container text-center"
        style={{ color: "black", maxWidth: "520px" }}
      >
        <IonModal
          id="heightmodal"
          isOpen={showmodal}
          onDidDismiss={() => setShowmodal(false)}
        >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle
                className="ion-text-center"
                style={{ fontSize: "large", color: "#8f6b00" }}
              >
                Terms & Conditions
              </IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonItem
            id="backgroundwhiteitem"
            style={{ width: "100%", height: "100%", padding: "0" }}
          >
            <IonCard
              id="backgroundwhiteitem"
              style={{
                width: "100%",
                height: "100%",
                padding: "0",
              }}
            >
              <IonCardContent id="backgroundwhiteitem">
                <div className="container">
                  <div style={{ height: "24px" }}></div>
                  <h1
                    className="text-center"
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    <b>I HAVE READ AND AGREE TO THE TERMS AND CONDITIONS</b>
                  </h1>
                  <div style={{ height: "12px" }}></div>
                  <p
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    By purchasing this annual member service from Senior Hub
                    Solutions, you agree that we can contact you in the future,
                    use your information to deliver services or related content.
                    You waive all rights to recourse against Senior Hub
                    Solutions, our members, and affiliates because of your
                    actions. Below is the detail of that agreement.
                  </p>
                  <div style={{ height: "6px" }}></div>
                  <p
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    When you submit information and photos to us, we use it in
                    an ongoing nature to ensure you receive the information or
                    purchases you have made with us to deliver relevant content
                    via email, newsletters, instant messaging, marketing
                    platforms, i.e., to deliver your business content on our
                    site. By giving us your contact information now you are
                    granting us the right to contact you in the future in any
                    manner necessary at our discretion for ongoing professional
                    development. Should you have any questions, contact Senior
                    Hub Solutions via email at
                    memberservices@seniorhubsolutions.com
                  </p>
                  <div style={{ height: "6px" }}></div>
                  <p
                    style={{
                      color: "black",
                      fontSize: "small",
                      fontFamily: "Abadi MT Std",
                      marginBottom: "0px",
                      textAlign: "justify",
                    }}
                  >
                    All services by our company are for informational purposes
                    only. Your level of success in attaining any stated ideas or
                    strategies on our site is dependent upon several factors
                    that differ per individual therefore, we cannot guarantee
                    your specific success or results in any area of your
                    business endeavor.&nbsp;
                    <b>
                      We make no guarantees that you will achieve any specific
                      results from our member services.
                    </b>
                    &nbsp; We offer no licensed or professional medical,
                    therapeutic, tax or financial advice. The information
                    contained herein cannot replace or substitute for the
                    services of trained professionals in any field, including,
                    but not limited to, medical, psychological, financial, or
                    legal matters. You alone are responsible and accountable for
                    your decisions, actions, and results. By your registration
                    here today and use of our member services at any time, you
                    agree not to attempt to hold us liable for any such
                    decisions, actions, or results at any time under any
                    circumstance.
                  </p>
                </div>
              </IonCardContent>
              <IonButton
                expand="block"
                onClick={() => setShowmodal(false)}
                style={{
                  margin: "0px",
                  color: "#8f6b00",
                  width: "100%",
                  fontSize: "medium",
                  /* height: "56px", */
                }}
              >
                I Agree
              </IonButton>
            </IonCard>
          </IonItem>
          <IonLabel id="backgroundwhiteitem" className="border-top">
            &nbsp;
          </IonLabel>
        </IonModal>

        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => {
            setIserror(false);
            if (currentUser) {
              history.push("/valuedcareproviders");
            }
          }}
          cssClass="ionbackgroundclass"
          header={"Error!"}
          message={messagelocal}
          buttons={["Dismiss"]}
        />
        <IonLoading
          cssClass="ionbackgroundclass"
          isOpen={showloading}
          onDidDismiss={() => {
            const specialmode = isadmin; //bbbb ? true : false;
            AuthService.login(email, password, specialmode)
              .then((data: any) => {
                dispatch(loginSuccess(data));
                history.push("/profile");
                window.location.reload();
                //return Promise.resolve();
              })
              .catch((error: any) => {
                const message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                dispatch(loginFailed());
                dispatch(setMessage(message));
                setMessagelocal(message);
                setIserror(true);
                //return Promise.reject();
              });
            setShowloading(false);
          }}
          message={"Please wait..."}
          duration={2000}
        />
        <main className="form-signin">
          <form>
            <IonIcon
              className="mb-4"
              color="primary"
              style={{ fontSize: "70px" }}
              icon={personCircle}
            />
            <h1
              className="h3 mb-3 fw-normal"
              style={{ fontFamily: "Abadi MT Std" }}
            >
              Welcome Back
            </h1>
            <div className="form-floating">
              <input
                /* ref={(ref) => (inputRef.current = ref)} */
                type="email"
                className="form-control"
                /* id="floatingInput" */
                /* placeholder="name@example.com" */
                value={email}
                onChange={(e) => setEmail(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              />
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Email / Username
              </label>
            </div>
            <div style={{ height: "6px" }}></div>
            <div className="form-floating">
              <input
                type={showpassword ? "text" : "password"}
                className="form-control"
                /* id="floatingPassword" */
                /* placeholder="Password" */
                value={password}
                onChange={(e) => setPassword(e.target.value!)}
                style={{ fontFamily: "Abadi MT Std" }}
              ></input>
              <i
                onClick={togglePasswordVisiblity}
                /* style={{ position: "absolute", top: "-6px", height: "16px" }} */
              >
                {showpassword ? eyehidden : eyeshown}
              </i>
              <label
                style={{
                  fontFamily: "Abadi MT Std",
                  color: "black",
                  textAlign: "left",
                }}
              >
                * Password
              </label>
            </div>

            <div style={{ height: "12px" }}></div>
            <div
              className="ion-text-center"
              style={{ fontSize: "medium", margin: "0px" }}
            >
              <div className="checkbox" style={{ margin: "0px" }}>
                <label
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontFamily: "Abadi MT Std",
                  }}
                >
                  <input
                    id="checkboxitem"
                    type="checkbox"
                    checked={ischecked}
                    onChange={toggleChecked}
                  />
                  <label
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      fontFamily: "Abadi MT Std",
                    }}
                  >
                    &nbsp;
                  </label>
                  Remember me
                </label>
              </div>
            </div>

            <div style={{ height: "12px" }}></div>
            {/* <p
              style={{
                fontSize: "small",
                padding: "0px",
                margin: "0px",
                color: "black",
                fontFamily: "Abadi MT Std",
              }}
            >
              By clicking Log In, you agree to seniorHUBsolutions Member
              Service&nbsp;
              <a
                style={{
                  textDecorationLine: "underline",
                  cursor: "pointer",
                  fontFamily: "Abadi MT Std",
                }}
                onClick={() => setShowmodal(true)}
              >
                Terms & Conditions
              </a>
            </p>
            <div style={{ height: "6px" }}></div> */}

            <IonButton
              expand="block"
              onClick={handleLogin}
              style={{ color: "#8f6b00", height: "56px", fontSize: "large" }}
            >
              Log In
            </IonButton>
          </form>
        </main>
      </div>
      <div className="container text-center">
        <p style={{ fontSize: "small", margin: "0px" }}>
          <a
            style={{
              textDecorationLine: "underline",
              cursor: "pointer",
              color: "black",
              fontFamily: "Abadi MT Std",
            }}
            onClick={() => history.push("/forgotpassword")}
          >
            Forgot Password
          </a>
        </p>
        <div style={{ height: "12px" }}></div>

        <p
          style={{
            fontSize: "medium",
            color: "black",
            margin: "0px",
            fontFamily: "Abadi MT Std",
          }}
        >
          Don't have an account?&nbsp;
          <a
            style={{
              textDecorationLine: "underline",
              cursor: "pointer",
              color: "black",
              fontFamily: "Abadi MT Std",
            }}
            onClick={() => {
              history.push("/registerprovider");
            }}
          >
            Create Account
          </a>
        </p>
      </div>

      <div
        className="ion-text-center"
        style={{ fontSize: "medium", margin: "0px" }}
      >
        <div className="checkbox" style={{ margin: "0px", color: "black" }}>
          <label
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              fontFamily: "Abadi MT Std",
              fontSize: "small",
            }}
          >
            <input
              id="checkboxitem"
              type="checkbox"
              checked={isadmin}
              onChange={toggleAdmin}
            />
            <label
              style={{
                cursor: "pointer",
                textDecoration: "none",
                fontFamily: "Abadi MT Std",
                fontSize: "small",
              }}
            >
              &nbsp;
            </label>
            Admin Log In
          </label>
        </div>
      </div>

      <div style={{ height: "24px" }}></div>

      <div>
        <FooterMain />
        <Footer />
        {localStorage.getItem("isnative") &&
          localStorage.getItem("isnative") === "true" && <FooterMobile />}
      </div>
    </IonContent>
  );
};

export default Login;
