/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useRef, useEffect } from "react";
//import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { IonAlert, IonContent, IonItem, IonLoading } from "@ionic/react";

/* Components */
import NavBar from "../components/NavBar";
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./Gallery.css";
/* Services */
import AuthService from "../services/auth.service";
/* Others */
import { history } from "../helpers/history";

const Gallery: React.FC = () => {
  const { carehome } = useParams<{ carehome: string }>();

  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [datavalue, setDatavalue] = useState<any>([]);
  const [datavaluefiltered, setDatavaluefiltered] = useState<any>([]);

  const [selectedrooms, setSelectedrooms] = useState<string>("All Care Homes");

  const [search, setSearch] = useState<string>("City");
  const [searchlistcity, setSearchlistcity] = useState<any>([]);
  const [searchlistzipcode, setSearchlistzipcode] = useState<any>([]);

  const [selectedsearchcities, setSelectedsearchcities] = useState<string>(
    "All Cities"
  );
  const [selectedsearchstate, setSelectedsearchstate] = useState<string>(
    "All States"
  );
  const [selectedsearchzipcode, setSelectedsearchzipcode] = useState<string>(
    "All Zip Codes"
  );

  const [hoverapplyfilter, setHoverapplyfilter] = useState<boolean>(false);
  const [hoversearch, setHoversearch] = useState<boolean>(false);

  const [iserror, setIserror] = useState<boolean>(false);
  const [showloading, setShowloading] = useState<boolean>(true);

  const [searchtextcity, setSearchtextcity] = useState<boolean>(false);
  const [searchtextzipcode, setSearchtextzipcode] = useState<boolean>(false);
  const [hoveroneoftheitems, setHoveroneoftheitems] = useState<boolean>(false);
  const inputRefContentCity = useRef<any>(null);
  const inputRefContentZipCode = useRef<any>(null);
  const inputRefSearch = useRef<any>(null);

  useEffect(() => {
    const platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);

    const isnative: any = localStorage.getItem("isnative")
      ? localStorage.getItem("isnative")
      : "";

    setIsnativeusedglobal(isnative);

    window.addEventListener("message", (event) => {
      if (event.data && event.data.sender === "links") {
        localStorage.setItem("links", event.data.message);
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    var inputraw1: any = [];
    inputraw1.push("All Cities");
    inputraw1.push("Aiea");
    inputraw1.push("Kapolei");
    inputraw1.push("Ewa Beach");
    inputraw1.push("Haleiwa");
    inputraw1.push("Kailua");
    inputraw1.push("Kaneohe");
    inputraw1.push("Pearl City");
    inputraw1.push("Wahiawa");
    inputraw1.push("Mililani");
    inputraw1.push("Waialua");
    inputraw1.push("Waianae");
    inputraw1.push("Waipahu");
    inputraw1.push("Hauula");
    inputraw1.push("Honolulu");
    setSearchlistcity(inputraw1);
    var inputraw2: any = [];
    inputraw2.push("All Zip Codes");
    inputraw2.push("96701");
    inputraw2.push("96707");
    inputraw2.push("96706");
    inputraw2.push("96712");
    inputraw2.push("96734");
    inputraw2.push("96744");
    inputraw2.push("96782");
    inputraw2.push("96786");
    inputraw2.push("96789");
    inputraw2.push("96791");
    inputraw2.push("96792");
    inputraw2.push("96797");
    inputraw2.push("96717");
    inputraw2.push("96813");
    inputraw2.push("96814");
    inputraw2.push("96815");
    inputraw2.push("96816");
    inputraw2.push("96817");
    inputraw2.push("96818");
    inputraw2.push("96819");
    inputraw2.push("96821");
    inputraw2.push("96822");
    inputraw2.push("96825");
    setSearchlistzipcode(inputraw2);
  }, []);

  useEffect(() => {
    const datavalue1: any = {
      galleryuseruniquenumber: "01",
      galleryimage: {
        galleryimagelink: require("../assets/1default1home.jpg").default,
        galleryimagetoken: "",
      },
      galleryviewname: "Birds of Paradise Home Corp.",
      gallerydescription: "Description set by the Care Home Provider",
      galleryaddress: "1 Street",
      /* gallerycityandstateorlocation: "Honolulu Hi", */
      galleryzipcode: "96789",
      gallerycontactnumber1: "+639178604830", //+1 (808) 225-4182
      gallerycontactnumber2: "",
      galleryemail: "diamondheadsmartlife@yahoo.com",
      gallerylat: 21.4520217982201,
      gallerylng: -158.01517356964638,
      galleryservicetype: {
        galleryservicetype1: true,
        galleryservicetype2: false,
        galleryservicetype3: false,
        galleryservicetypeavailability1: true,
        galleryservicetypeavailability2: false,
        galleryservicetypeavailability3: false,
      },
      gallerycity: "Mililani",
      gallerystate: "Hawaii",
      gallerydetailslistingdesc: {
        gallerydetailslistingsection1: [
          "Private Bedroom",
          "Semi-Private Bedroom",
          "Private Bathroom",
        ],
        gallerydetailslistingsection2: [
          "Air Conditioning",
          "Television",
          "WIFI",
          "Hospital Bed-Regular Single Bed",
          "Ceiling Fan",
          "Nightstand with Lamp",
          "Call Light System",
          "Sitting Area",
          "Closet Area",
          "Emergency Smoke Detector or Sprinkler System",
        ],
        gallerydetailslistingsection3: [
          "24- hour Care Supervision",
          "Meals: Home prepared nutritional cooked meals three times a day including snacks",
          "Special Diets Offered (as per physician’s orders)",
          "Activities of Daily living: assistance with feeding, walking, transfers, bathing, toileting/incontinence care",
          "Medication Management",
          "Exercise: Offered daily and as needed; per physician instructions",
          "Activities: Individualized plan based on client’s preferences",
          "Laundry Service",
          "Housekeeping Service",
          "Transportation Options: Caregiver may provide free transportation as part of their service to doctor’s appointments for client’s able to transfer in and out of car. For clients requiring more assistance, caregiver can arrange for private transport which is an out-of-pocket expense for families/clients.",
        ],
      },
    };
    const datavalue2: any = {
      galleryuseruniquenumber: "02",
      galleryimage: {
        galleryimagelink: require("../assets/1default2home.jpg").default,
        galleryimagetoken: "",
      },
      galleryviewname: "Sample Home Corp. 1",
      gallerydescription: "Description set by the Care Home Provider",
      galleryaddress: "2 Street",
      /* gallerycityandstateorlocation:
        "City, State, Location of the Care Home Provider", */
      galleryzipcode: "96786",
      gallerycontactnumber1: "+1 (808) 000-0000",
      gallerycontactnumber2: "",
      galleryemail: "sample1@yahoo.com",
      gallerylat: 21.503001433650066,
      gallerylng: -158.02634328726037,
      galleryservicetype: {
        galleryservicetype1: true,
        galleryservicetype2: false,
        galleryservicetype3: false,
        galleryservicetypeavailability1: false,
        galleryservicetypeavailability2: false,
        galleryservicetypeavailability3: false,
      },
      gallerycity: "Wahiawa",
      gallerystate: "Hawaii",
      gallerydetailslistingdesc: {
        gallerydetailslistingsection1: ["Private Bedroom", "Private Bathroom"],
        gallerydetailslistingsection2: [
          "Air Conditioning",
          "Television",
          "WIFI",
          "Hospital Bed-Regular Single Bed",
          "Ceiling Fan",
          "Nightstand with Lamp",
          "Call Light System",
        ],
        gallerydetailslistingsection3: [
          "24- hour Care Supervision",
          "Meals: Home prepared nutritional cooked meals three times a day including snacks",
          "Special Diets Offered (as per physician’s orders)",
          "Housekeeping Service",
          "Transportation Options: Caregiver may provide free transportation as part of their service to doctor’s appointments for client’s able to transfer in and out of car. For clients requiring more assistance, caregiver can arrange for private transport which is an out-of-pocket expense for families/clients.",
        ],
      },
    };
    const datavalue3: any = {
      galleryuseruniquenumber: "03",
      galleryimage: {
        galleryimagelink: require("../assets/1default3home.jpg").default,
        galleryimagetoken: "",
      },
      galleryviewname: "Sample Home Corp. 2",
      gallerydescription: "Description set by the Care Home Provider",
      galleryaddress: "3 Street",
      /* gallerycityandstateorlocation:
        "City, State, Location set by the Care Home Provider", */
      galleryzipcode: "96706",
      gallerycontactnumber1: "+1 (808) 000-0000",
      gallerycontactnumber2: "",
      galleryemail: "sample2@yahoo.com",
      gallerylat: 21.316064755415265,
      gallerylng: -158.01366734935598,
      galleryservicetype: {
        galleryservicetype1: true,
        galleryservicetype2: false,
        galleryservicetype3: false,
        galleryservicetypeavailability1: true,
        galleryservicetypeavailability2: false,
        galleryservicetypeavailability3: false,
      },
      gallerycity: "Ewa Beach",
      gallerystate: "Hawaii",
      gallerydetailslistingdesc: {
        gallerydetailslistingsection1: [
          "Private Bedroom",
          "Semi-Private Bedroom",
          "Private Bathroom",
        ],
        gallerydetailslistingsection2: [
          "Air Conditioning",
          "Television",
          "WIFI",
          "Hospital Bed-Regular Single Bed",
          "Ceiling Fan",
          "Nightstand with Lamp",
          "Call Light System",
          "Sitting Area",
          "Closet Area",
          "Emergency Smoke Detector or Sprinkler System",
        ],
        gallerydetailslistingsection3: [
          "24- hour Care Supervision",
          "Meals: Home prepared nutritional cooked meals three times a day including snacks",
          "Special Diets Offered (as per physician’s orders)",
          "Activities of Daily living: assistance with feeding, walking, transfers, bathing, toileting/incontinence care",
          "Medication Management",
          "Exercise: Offered daily and as needed; per physician instructions",
          "Activities: Individualized plan based on client’s preferences",
          "Laundry Service",
          "Housekeeping Service",
          "Transportation Options: Caregiver may provide free transportation as part of their service to doctor’s appointments for client’s able to transfer in and out of car. For clients requiring more assistance, caregiver can arrange for private transport which is an out-of-pocket expense for families/clients.",
        ],
      },
    };
    const datavalue4: any = {
      galleryuseruniquenumber: "04",
      galleryimage: {
        galleryimagelink: require("../assets/1default4home.jpg").default,
        galleryimagetoken: "",
      },
      galleryviewname: "Sample Home Corp. 3",
      gallerydescription: "Description set by the Care Home Provider",
      galleryaddress: "4 Street",
      /* gallerycityandstateorlocation:
        "City, State, Location set by the Care Home Provider", */
      galleryzipcode: "96706",
      gallerycontactnumber1: "+1 (808) 000-0000",
      gallerycontactnumber2: "",
      galleryemail: "sample3@yahoo.com",
      gallerylat: 21.316064755415265,
      gallerylng: -158.01366734935598,
      galleryservicetype: {
        galleryservicetype1: true,
        galleryservicetype2: false,
        galleryservicetype3: false,
        galleryservicetypeavailability1: true,
        galleryservicetypeavailability2: false,
        galleryservicetypeavailability3: false,
      },
      gallerycity: "Ewa Beach",
      gallerystate: "Hawaii",
      gallerydetailslistingdesc: {
        gallerydetailslistingsection1: [
          "Private Bedroom",
          "Semi-Private Bedroom",
          "Private Bathroom",
        ],
        gallerydetailslistingsection2: [
          "Air Conditioning",
          "Television",
          "WIFI",
          "Hospital Bed-Regular Single Bed",
          "Ceiling Fan",
          "Nightstand with Lamp",
          "Call Light System",
          "Sitting Area",
          "Closet Area",
          "Emergency Smoke Detector or Sprinkler System",
        ],
        gallerydetailslistingsection3: [
          "24- hour Care Supervision",
          "Meals: Home prepared nutritional cooked meals three times a day including snacks",
          "Special Diets Offered (as per physician’s orders)",
          "Activities of Daily living: assistance with feeding, walking, transfers, bathing, toileting/incontinence care",
          "Medication Management",
          "Exercise: Offered daily and as needed; per physician instructions",
          "Activities: Individualized plan based on client’s preferences",
          "Laundry Service",
          "Housekeeping Service",
          "Transportation Options: Caregiver may provide free transportation as part of their service to doctor’s appointments for client’s able to transfer in and out of car. For clients requiring more assistance, caregiver can arrange for private transport which is an out-of-pocket expense for families/clients.",
        ],
      },
    };
    const datavalue5: any = {
      galleryuseruniquenumber: "05",
      galleryimage: {
        galleryimagelink: require("../assets/2default1home.jpg").default,
        galleryimagetoken: "",
      },
      galleryviewname: "Celia Olaes",
      gallerydescription: "Description set by the Care Home Provider",
      galleryaddress: "1 Street",
      /* gallerycityandstateorlocation:
        "City, State, Location set by the Care Home Provider", */
      galleryzipcode: "96734",
      gallerycontactnumber1: "+1 (808) 352-7019",
      gallerycontactnumber2: "",
      galleryemail: "noemail@yahoo.com",
      gallerylat: 21.397605672108238,
      gallerylng: -157.7290657445204,
      galleryservicetype: {
        galleryservicetype1: false,
        galleryservicetype2: true,
        galleryservicetype3: false,
        galleryservicetypeavailability1: false,
        galleryservicetypeavailability2: true,
        galleryservicetypeavailability3: false,
      },
      gallerycity: "Kailua",
      gallerystate: "Hawaii",
      gallerydetailslistingdesc: {
        gallerydetailslistingsection1: [
          "Private Bedroom",
          "Semi-Private Bedroom",
          "Private Bathroom",
        ],
        gallerydetailslistingsection2: [
          "Air Conditioning",
          "Television",
          "WIFI",
          "Hospital Bed-Regular Single Bed",
          "Ceiling Fan",
          "Nightstand with Lamp",
          "Call Light System",
          "Sitting Area",
          "Closet Area",
          "Emergency Smoke Detector or Sprinkler System",
        ],
        gallerydetailslistingsection3: [
          "24- hour Care Supervision",
          "Meals: Home prepared nutritional cooked meals three times a day including snacks",
          "Special Diets Offered (as per physician’s orders)",
          "Activities of Daily living: assistance with feeding, walking, transfers, bathing, toileting/incontinence care",
          "Medication Management",
          "Exercise: Offered daily and as needed; per physician instructions",
          "Activities: Individualized plan based on client’s preferences",
          "Laundry Service",
          "Housekeeping Service",
          "Transportation Options: Caregiver may provide free transportation as part of their service to doctor’s appointments for client’s able to transfer in and out of car. For clients requiring more assistance, caregiver can arrange for private transport which is an out-of-pocket expense for families/clients.",
        ],
      },
    };
    const datavalue6: any = {
      galleryuseruniquenumber: "06",
      galleryimage: {
        galleryimagelink: require("../assets/2default2home.jpg").default,
        galleryimagetoken: "",
      },
      galleryviewname: "Sample Home Corp. 1",
      gallerydescription: "Description set by the Care Home Provider",
      galleryaddress: "2 Street",
      /* gallerycityandstateorlocation:
        "City, State, Location set by the Care Home Provider", */
      galleryzipcode: "96782",
      gallerycontactnumber1: "+1 (808) 000-0000",
      gallerycontactnumber2: "",
      galleryemail: "sample1@yahoo.com",
      gallerylat: 18.936291302913276,
      gallerylng: -155.6469075868828,
      galleryservicetype: {
        galleryservicetype1: false,
        galleryservicetype2: true,
        galleryservicetype3: false,
        galleryservicetypeavailability1: false,
        galleryservicetypeavailability2: true,
        galleryservicetypeavailability3: false,
      },
      gallerycity: "Pearl City",
      gallerystate: "Hawaii",
      gallerydetailslistingdesc: {
        gallerydetailslistingsection1: [
          "Private Bedroom",
          "Semi-Private Bedroom",
          "Private Bathroom",
        ],
        gallerydetailslistingsection2: [
          "Air Conditioning",
          "Television",
          "WIFI",
          "Hospital Bed-Regular Single Bed",
          "Ceiling Fan",
          "Nightstand with Lamp",
          "Call Light System",
          "Sitting Area",
          "Closet Area",
          "Emergency Smoke Detector or Sprinkler System",
        ],
        gallerydetailslistingsection3: [
          "24- hour Care Supervision",
          "Meals: Home prepared nutritional cooked meals three times a day including snacks",
          "Special Diets Offered (as per physician’s orders)",
          "Activities of Daily living: assistance with feeding, walking, transfers, bathing, toileting/incontinence care",
          "Medication Management",
          "Exercise: Offered daily and as needed; per physician instructions",
          "Activities: Individualized plan based on client’s preferences",
          "Laundry Service",
          "Housekeeping Service",
          "Transportation Options: Caregiver may provide free transportation as part of their service to doctor’s appointments for client’s able to transfer in and out of car. For clients requiring more assistance, caregiver can arrange for private transport which is an out-of-pocket expense for families/clients.",
        ],
      },
    };
    const datavalue7: any = {
      galleryuseruniquenumber: "07",
      galleryimage: {
        galleryimagelink: require("../assets/3default1home.jpg").default,
        galleryimagetoken: "",
      },
      galleryviewname: "Mariefe Galvez LLC",
      gallerydescription: "Description set by the Care Home Provider",
      galleryaddress: "1 Street",
      /* gallerycityandstateorlocation: "Pearl City Hi", */
      galleryzipcode: "96744",
      gallerycontactnumber1: "+1 (808) 285-2050",
      gallerycontactnumber2: "",
      galleryemail: "mariegil1117@yahoo.com",
      gallerylat: 20.770782062925147,
      gallerylng: -156.45854055234508,
      galleryservicetype: {
        galleryservicetype1: false,
        galleryservicetype2: false,
        galleryservicetype3: true,
        galleryservicetypeavailability1: false,
        galleryservicetypeavailability2: false,
        galleryservicetypeavailability3: true,
      },
      gallerycity: "Kaneohe",
      gallerystate: "Hawaii",
      gallerydetailslistingdesc: {
        gallerydetailslistingsection1: [
          "Private Bedroom",
          "Semi-Private Bedroom",
          "Private Bathroom",
        ],
        gallerydetailslistingsection2: [
          "Air Conditioning",
          "Television",
          "WIFI",
          "Hospital Bed-Regular Single Bed",
          "Ceiling Fan",
          "Nightstand with Lamp",
          "Call Light System",
          "Sitting Area",
          "Closet Area",
          "Emergency Smoke Detector or Sprinkler System",
        ],
        gallerydetailslistingsection3: [
          "24- hour Care Supervision",
          "Meals: Home prepared nutritional cooked meals three times a day including snacks",
          "Special Diets Offered (as per physician’s orders)",
          "Activities of Daily living: assistance with feeding, walking, transfers, bathing, toileting/incontinence care",
          "Medication Management",
          "Exercise: Offered daily and as needed; per physician instructions",
          "Activities: Individualized plan based on client’s preferences",
          "Laundry Service",
          "Housekeeping Service",
          "Transportation Options: Caregiver may provide free transportation as part of their service to doctor’s appointments for client’s able to transfer in and out of car. For clients requiring more assistance, caregiver can arrange for private transport which is an out-of-pocket expense for families/clients.",
        ],
      },
    };

    /* setDatavalue([]);
    datavalue.push(datavalue1);
    datavalue.push(datavalue2);
    datavalue.push(datavalue3);
    datavalue.push(datavalue4);
    datavalue.push(datavalue5);
    datavalue.push(datavalue6);
    datavalue.push(datavalue7);

    setDatavalue(datavalue);
    localStorage.setItem("galleryraw", JSON.stringify(datavalue));
    setIserror(false);
    setShowloading(false); */
    /* toggleactiveapplyfilter(); */
  }, []);

  useEffect(() => {
    AuthService.galleryview(carehome)
      .then((data: any) => {
        setIserror(false);
        setShowloading(false);
        //toggleactiveapplyfilter();
        toggleactivesearch();
      })
      .catch((error: any) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setIserror(true);
        setShowloading(false);
      });
  }, [carehome]);

  useEffect(() => {
    /* var inputraw: any = [];
    if (selectedsearchcities === "All Cities") {
      inputraw.push("All States");
      inputraw.push("Mililani");
      inputraw.push("Wahiawa");
      inputraw.push("Ewa Beach");
      inputraw.push("Beachside");
      inputraw.push("Green Sands");
      inputraw.push("Kihei");
      setState(inputraw);
    } else if (selectedsearchcities === "Central Oahu") {
      inputraw.push("All States");
      inputraw.push("Mililani");
      inputraw.push("Wahiawa");
      setState(inputraw);
    } else if (selectedsearchcities === "Ewa Plain") {
      inputraw.push("All States");
      inputraw.push("Ewa Beach");
      setState(inputraw);
    } else if (selectedsearchcities === "Kailua") {
      inputraw.push("All States");
      inputraw.push("Beachside");
      setState(inputraw);
    } else if (selectedsearchcities === "Kau") {
      inputraw.push("All States");
      inputraw.push("Green Sands");
      setState(inputraw);
    } else if (selectedsearchcities === "Maui") {
      inputraw.push("All States");
      inputraw.push("Kihei");
      setState(inputraw);
    }
    setSelectedsearchstate("All States"); */
  }, [selectedsearchcities]);

  let pagename = "Gallery of Homes";

  var carehomename = "";
  var carehomenamecontent1 = "";
  var carehomenamecontent2 = "";
  var carehomenamecontent3 = "";
  if (carehome === "arch") {
    carehomename = "Adult Residential Care Homes (ARCH)";
    carehomenamecontent1 =
      "An Adult Residential Care Home (ARCH) is a licensed residential care setting that provides 24-hour living accommodations for a fee to adults (unrelated to the family) who require at least minimal assistance in ADLs, known as the activities of daily living.";
    carehomenamecontent2 =
      "ADLs are defined as things we normally do such as feeding ourselves, bathing, dressing, grooming, etc. Health professionals routinely refer to the ability or inability to perform ADLs as a measurement of a person’s functional status.";
    carehomenamecontent3 =
      "Adult Residential Care Homes are highly desirable for loved ones who cherish their independence but need some assistance with daily activities. Loved ones can live in private or semi-private rooms and enjoy complete freedom from home maintenance and household chores.";
  } else if (carehome === "earch") {
    carehomename = "Adult Residential Care Homes (E-ARCH)";
    carehomenamecontent1 =
      "An Expanded Adult Residential Care Home (E-ARCH) is defined as a licensed residential care setting that provides 24-hour living accommodations for a fee to adults (unrelated to the family) who require assistance with activities of daily living (ADLs). An Expanded ARCH (E-ARCH) has the additional provision of health-related services needed by residents who meet nursing facility level of care criteria.";
    carehomenamecontent2 =
      "ADLs are defined as things we normally do such as feeding ourselves, bathing, dressing, grooming, etc. Health professionals routinely refer to the ability or inability to perform ADLs as a measurement of a person’s functional status.";
    carehomenamecontent3 =
      "Expanded Adult Residential Care Homes are highly desirable for loved ones who cherish their independence but need 24 hrs. assistance with daily activities and may require skilled nursing services. Loved ones can live in private or semi-private rooms and enjoy complete freedom from home maintenance and household chores.";
  } else if (carehome === "afch") {
    carehomename = "Adult Foster Care Homes (AFCH)";
    carehomenamecontent1 =
      "Adult Foster Care Homes are licensed and designed in caring for the elderly and disabled. Providing personal care, management and administering of medications, assistance with the activities of daily living (mobility, bathing, toileting, etc.), room and board, 24-hour supervision, and homemaker services (preparation of meals, laundry, shopping, etc.), for their residents. The Adult Foster Care Home provides care for up to three persons, providing a family-like environment for your loved one(s).";
    carehomenamecontent2 =
      "For Medicaid recipients, State and Federal programs help with costs for those with limited income and assets. Private pay individuals are also accepted into an Adult Foster Care Home. Residents are placed into an Adult Foster Care Home at their appropriate level of care by a Case Management Agency and continue to receive case management services by the agency.";
    carehomenamecontent3 = "";
  } else if (carehome === "other") {
    carehomename = "";
    carehomenamecontent1 = "";
    carehomenamecontent2 = "";
    carehomenamecontent3 = "";
  } else if (carehome === "rc") {
    carehomename = "Respite Care";
    carehomenamecontent1 = "";
    carehomenamecontent2 = "";
    carehomenamecontent3 = "";
  } else if (carehome === "hc") {
    carehomename = "Hospice Care";
    carehomenamecontent1 = "";
    carehomenamecontent2 = "";
    carehomenamecontent3 = "";
  } else if (carehome === "mc") {
    carehomename = "Memory Care";
    carehomenamecontent1 = "";
    carehomenamecontent2 = "";
    carehomenamecontent3 = "";
  } else if (carehome === "allcarehomes") {
    carehomename = "All Care Homes";
    carehomenamecontent1 = "";
    carehomenamecontent2 = "";
    carehomenamecontent3 = "";
  }

  function togglehoverapplyfilter() {
    setHoverapplyfilter(!hoverapplyfilter);
  }

  function togglehoversearch() {
    setHoversearch(!hoversearch);
  }

  function toggleactiveapplyfilter() {
    setDatavalue([]);
    const datavalueraw: any = localStorage.getItem("galleryraw");
    JSON.parse(datavalueraw).map((datasub: any) => {
      datavalue.push(datasub);
    });

    setDatavaluefiltered([]);

    var datavaluefiltered1: any = [];
    var datavaluefiltered2: any = [];
    var datavaluefiltered3: any = [];
    var datavaluefiltered4: any = [];
    var datavaluefiltered5: any = [];

    if (carehome === "arch") {
      datavalue.map((data: any) => {
        if (data.galleryservicetype.galleryservicetype1) {
          datavaluefiltered1.push(data);
        }
      });

      if (selectedrooms === "All Care Homes") {
        datavaluefiltered2 = datavaluefiltered1;
      } else {
        datavaluefiltered1.map((data: any) => {
          if (data.galleryservicetype.galleryservicetypeavailability1) {
            datavaluefiltered2.push(data);
          }
        });
      }
    } else if (carehome === "earch") {
      datavalue.map((data: any) => {
        if (data.galleryservicetype.galleryservicetype2) {
          datavaluefiltered1.push(data);
        }
      });

      if (selectedrooms === "All Care Homes") {
        datavaluefiltered2 = datavaluefiltered1;
      } else {
        datavaluefiltered1.map((data: any) => {
          if (data.galleryservicetype.galleryservicetypeavailability2) {
            datavaluefiltered2.push(data);
          }
        });
      }
    } else if (carehome === "afch") {
      datavalue.map((data: any) => {
        if (data.galleryservicetype.galleryservicetype3) {
          datavaluefiltered1.push(data);
        }
      });

      if (selectedrooms === "All Care Homes") {
        datavaluefiltered2 = datavaluefiltered1;
      } else {
        datavaluefiltered1.map((data: any) => {
          if (data.galleryservicetype.galleryservicetypeavailability3) {
            datavaluefiltered2.push(data);
          }
        });
      }
    }

    if (selectedsearchcities === "All Cities") {
      datavaluefiltered3 = datavaluefiltered2;
    } else {
      datavaluefiltered2.map((data: any) => {
        if (data.gallerycity === selectedsearchcities) {
          datavaluefiltered3.push(data);
        }
      });
    }

    if (selectedsearchstate === "All States") {
      datavaluefiltered4 = datavaluefiltered3;
    } else {
      datavaluefiltered3.map((data: any) => {
        if (data.gallerystate === selectedsearchstate) {
          datavaluefiltered4.push(data);
        }
      });
    }

    datavaluefiltered4 = datavaluefiltered4.sort(() => Math.random() - 0.5);

    var tempdata: any = [];
    var rownumber: number = 0;

    datavaluefiltered4.map((data: any) => {
      rownumber = rownumber + 1;
      if (rownumber === 1) {
        tempdata = [];
      }
      tempdata.push(data);
      if (rownumber === 3) {
        rownumber = 0;
        datavaluefiltered5.push({
          data1: tempdata[0],
          data2: tempdata[1],
          data3: tempdata[2],
        });
      }
    });

    if (tempdata) {
      if (tempdata.length === 1) {
        datavaluefiltered5.push({ data1: tempdata[0], data2: "", data3: "" });
      }
      if (tempdata.length === 2) {
        datavaluefiltered5.push({
          data1: tempdata[0],
          data2: tempdata[1],
          data3: "",
        });
      }
    }

    setDatavaluefiltered(datavaluefiltered5);
  }

  function toggleactivesearch() {
    setSearchtextcity(false);
    setSearchtextzipcode(false);

    setDatavalue([]);
    const datavalueraw: any = localStorage.getItem("galleryraw");
    JSON.parse(datavalueraw).map((datasub: any) => {
      datavalue.push(datasub);
    });

    setDatavaluefiltered([]);

    var datavaluefiltered1: any = [];
    var datavaluefiltered2: any = [];
    var datavaluefiltered3: any = [];
    var datavaluefiltered4: any = [];
    var datavaluefiltered5: any = [];

    if (carehome === "arch") {
      datavalue.map((data: any) => {
        if (data.galleryservicetype.galleryservicetype1) {
          datavaluefiltered1.push(data);
        }
      });

      if (selectedrooms === "All Care Homes") {
        datavaluefiltered2 = datavaluefiltered1;
      } else {
        datavaluefiltered1.map((data: any) => {
          if (data.galleryservicetype.galleryservicetypeavailability1) {
            datavaluefiltered2.push(data);
          }
        });
      }
    } else if (carehome === "earch") {
      datavalue.map((data: any) => {
        if (data.galleryservicetype.galleryservicetype2) {
          datavaluefiltered1.push(data);
        }
      });

      if (selectedrooms === "All Care Homes") {
        datavaluefiltered2 = datavaluefiltered1;
      } else {
        datavaluefiltered1.map((data: any) => {
          if (data.galleryservicetype.galleryservicetypeavailability2) {
            datavaluefiltered2.push(data);
          }
        });
      }
    } else if (carehome === "afch") {
      datavalue.map((data: any) => {
        if (data.galleryservicetype.galleryservicetype3) {
          datavaluefiltered1.push(data);
        }
      });

      if (selectedrooms === "All Care Homes") {
        datavaluefiltered2 = datavaluefiltered1;
      } else {
        datavaluefiltered1.map((data: any) => {
          if (data.galleryservicetype.galleryservicetypeavailability3) {
            datavaluefiltered2.push(data);
          }
        });
      }
    }

    if (search === "City") {
      if (
        inputRefSearch.current.value === "All Cities" ||
        inputRefSearch.current.value === ""
      ) {
        datavaluefiltered3 = datavaluefiltered2;
      } else {
        datavaluefiltered2.map((data: any) => {
          //if (data.gallerycity === inputRefSearch.current.value) {
          if (
            data.gallerycity
              .toUpperCase()
              .indexOf(inputRefSearch.current.value.toUpperCase()) > -1
          ) {
            datavaluefiltered3.push(data);
          }
        });
      }
    } else {
      datavaluefiltered3 = datavaluefiltered2;
    }

    if (search === "Zip Code") {
      if (
        inputRefSearch.current.value === "All Zip Codes" ||
        inputRefSearch.current.value === ""
      ) {
        datavaluefiltered4 = datavaluefiltered3;
      } else {
        datavaluefiltered3.map((data: any) => {
          //if (data.galleryzipcode === inputRefSearch.current.value) {
          if (
            data.galleryzipcode
              .toUpperCase()
              .indexOf(inputRefSearch.current.value.toUpperCase()) > -1
          ) {
            datavaluefiltered4.push(data);
          }
        });
      }
    } else {
      datavaluefiltered4 = datavaluefiltered3;
    }

    datavaluefiltered4 = datavaluefiltered4.sort(() => Math.random() - 0.5);

    var tempdata: any = [];
    var rownumber: number = 0;

    datavaluefiltered4.map((data: any) => {
      rownumber = rownumber + 1;
      if (rownumber === 1) {
        tempdata = [];
      }
      tempdata.push(data);
      if (rownumber === 3) {
        rownumber = 0;
        datavaluefiltered5.push({
          data1: tempdata[0],
          data2: tempdata[1],
          data3: tempdata[2],
        });
      }
    });

    if (tempdata) {
      if (tempdata.length === 1) {
        datavaluefiltered5.push({ data1: tempdata[0], data2: "", data3: "" });
      }
      if (tempdata.length === 2) {
        datavaluefiltered5.push({
          data1: tempdata[0],
          data2: tempdata[1],
          data3: "",
        });
      }
    }

    setDatavaluefiltered(datavaluefiltered5);
  }

  async function launchLink1() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://health.hawaii.gov/ohca/state-licensing-section/",
        },
        "*"
      );
    } else {
      window.open(
        "https://health.hawaii.gov/ohca/state-licensing-section/",
        "_system"
      );
    }
  }
  async function launchLink2() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://www.comties.com/_files/ugd/fcb480_bbc6dc78625a42d89408d5edabd8307a.pdf",
        },
        "*"
      );
    } else {
      window.open(
        "https://www.comties.com/_files/ugd/fcb480_bbc6dc78625a42d89408d5edabd8307a.pdf",
        "_system"
      );
    }
  }

  async function launchLink3() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.comties.com/",
        },
        "*"
      );
    } else {
      window.open("https://www.comties.com/", "_system");
    }
  }

  function showcontentfocus() {
    //setSearchtextcity(true);
    changevaluetyping();
  }
  function showcontentblur() {
    if (search === "City") {
      if (
        inputRefSearch.current.value === "" &&
        searchtextcity &&
        !hoveroneoftheitems
      ) {
        /* inputRefContentCity.current.classList.toggle("hide"); */
        setSearchtextcity(false);
      }
    } else if (search === "Zip Code") {
      if (
        inputRefSearch.current.value === "" &&
        searchtextzipcode &&
        !hoveroneoftheitems
      ) {
        /* inputRefContentZipCode.current.classList.toggle("hide"); */
        setSearchtextzipcode(false);
      }
    }
  }

  function changevaluetyping() {
    var filter, a, i;
    filter = inputRefSearch.current.value.toUpperCase();

    if (search === "City") {
      if (inputRefSearch.current.value === "") {
        setSearchtextcity(false);
      } else {
        setSearchtextcity(true);
      }

      a = inputRefContentCity.current.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    } else if (search === "Zip Code") {
      if (inputRefSearch.current.value === "") {
        setSearchtextzipcode(false);
      } else {
        setSearchtextzipcode(true);
      }

      a = inputRefContentZipCode.current.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    }
  }

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <NavBar />

      <div style={{ height: carehomename ? "32vh" : "28vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <IonAlert
          isOpen={iserror}
          /* onDidDismiss={() => setIserror(false)} */
          cssClass="ionbackgroundclass"
          header={"Error!"}
          message={"Failed loading Care Home Providers"}
          buttons={["Dismiss"]}
        />
        <IonLoading
          cssClass="ionbackgroundclass"
          isOpen={showloading}
          message={"Please wait..."}
        />

        <div className="container">
          <div className="container-fluid">
            <h1
              className="featurette-heading"
              style={{
                marginTop: "10px",
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                color: "#008080", //#8A1F61
                /* textShadow: "-1px 1px 2px #000", */
              }}
            >
              {pagename}
            </h1>
            <div style={{ height: "6px" }}></div>
            <h1
              className="featurette-heading"
              style={{
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                color: "#008080",
                margin: "0px",
                /* textShadow: "-1px 1px 2px #000", */
              }}
            >
              {carehomename}
            </h1>
            {carehomenamecontent1 && carehomenamecontent1 !== "" && (
              <div style={{ height: "12px" }}></div>
            )}
            {carehomenamecontent1 && carehomenamecontent1 !== "" && (
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                  margin: "0px",
                }}
              >
                {carehomenamecontent1}
              </p>
            )}
            {carehomenamecontent2 && carehomenamecontent2 !== "" && (
              <div style={{ height: "12px" }}></div>
            )}
            {carehomenamecontent2 && carehomenamecontent2 !== "" && (
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                  margin: "0px",
                }}
              >
                {carehomenamecontent2}
              </p>
            )}
            {carehomenamecontent3 && carehomenamecontent3 !== "" && (
              <div style={{ height: "12px" }}></div>
            )}
            {carehomenamecontent3 && carehomenamecontent3 !== "" && (
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                  margin: "0px",
                }}
              >
                {carehomenamecontent3}
              </p>
            )}
            {/* <div style={{ height: "12px" }}></div>
            {(carehome === "arch" || carehome === "earch") && (
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                  margin: "0px",
                }}
              >
                State Licensing Information: Hawaii Department of Health, Office
                of Health Care Assurance&nbsp;
                <a
                  style={{
                    color: "black",
                    cursor: "pointer",
                    margin: "0px",
                    textDecorationLine: "underline",
                  }}
                  onClick={launchLink1}
                >
                  https://health.hawaii.gov/ohca/state-licensing-section/
                </a>
              </p>
            )}
            {carehome === "afch" && (
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                  margin: "0px",
                }}
              >
                Hawaii Administrative Rules, Department of Health Regulation of
                Home and Community-Based Case Management Agencies and Community
                Care Foster Family Homes&nbsp;
                <a
                  style={{
                    color: "black",
                    cursor: "pointer",
                    margin: "0px",
                    textDecorationLine: "underline",
                  }}
                  onClick={launchLink2}
                >
                  https://www.comties.com/_files/ugd/fcb480_bbc6dc78625a42d8940
                  8d5edabd8307a.pdf
                </a>
              </p>
            )}
            {carehome === "afch" && <div style={{ height: "12px" }}></div>}
            {carehome === "afch" && (
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "1.1em",
                  margin: "0px",
                }}
              >
                Community Ties of America, Inc., Hawaii Operations&nbsp;
                <a
                  style={{
                    color: "black",
                    cursor: "pointer",
                    margin: "0px",
                    textDecorationLine: "underline",
                  }}
                  onClick={launchLink3}
                >
                  https://www.comties.com/
                </a>
              </p>
            )} */}
            <div style={{ height: "12px" }}></div>
            <div
              /* className="border-top border-bottom" */ style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
            <div style={{ height: "24px" }}></div>

            <div
              className="container row text-center align-items-center"
              style={{ margin: "0px", padding: "0px" }}
            >
              <div
                className="col-lg-2 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>

              <div
                className="col-lg-8 text-center"
                style={{
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {/* <div
                  className="container text-center align-contents-center"
                  style={{
                    textAlign: "center",
                    color: "black",
                    maxWidth: "520px",
                    padding: "0px",
                    margin: "0px",
                    position: "relative",
                  }}
                > */}

                <main className="form-signin">
                  <form className="text-center align-contents-center">
                    {/* <div
                      className="container text-center align-contents-center"
                      style={{ padding: "0px", margin: "0px" }}
                    >
                      <div
                        id="withwithoutavailablefilterwidth"
                        className="form-check form-check-inline"
                        style={{
                          color: "black",
                          fontFamily: "AvantGarde Bk BT",
                          textAlign: "left",
                        }}
                      >
                        <input
                          defaultChecked
                          className="form-check-input"
                          type="Radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          style={{
                            color: "black",
                            cursor: "pointer",
                            backgroundColor: "black",
                            borderColor: "black",
                          }}
                          onClick={() => {
                            setSelectedrooms("All Care Homes");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                          style={{
                            color: "black",
                            cursor: "pointer",
                            textAlign: "left",
                          }}
                        >
                          All Care Homes
                        </label>
                      </div>
                      <div
                        className="form-check form-check-inline"
                        style={{
                          color: "black",
                          fontFamily: "AvantGarde Bk BT",
                          minWidth: "200px",
                          textAlign: "left",
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="Radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          style={{
                            color: "black",
                            cursor: "pointer",
                            backgroundColor: "black",
                            borderColor: "black",
                          }}
                          onClick={() => {
                            setSelectedrooms("Current Available Rooms");
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                          style={{
                            color: "black",
                            cursor: "pointer",
                            textAlign: "left",
                          }}
                        >
                          Current Available Rooms
                        </label>
                      </div>
                    </div> */}

                    {/* <div
                      className="border-bottom"
                      style={{ height: "6px" }}
                    ></div>
                    <div style={{ height: "6px" }}></div> */}

                    {/* <div
                      className="text-center form-check form-check-inline container"
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontFamily: "AvantGarde Bk BT",
                        width: "100%",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="dropdown01"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          padding: "0px",
                          paddingLeft: "16px",
                          color: "black",
                          float: "left",
                        }}
                      >
                        Cities: {selectedsearchcities}:
                      </a>

                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdown01"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={(e) => {
                              setSelectedsearchcities("All Cities");
                            }}
                          >
                            All Cities
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSelectedsearchcities("Central Oahu");
                            }}
                          >
                            Central Oahu
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSelectedsearchcities("Ewa Plain");
                            }}
                          >
                            Ewa Plain
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSelectedsearchcities("Kailua");
                            }}
                          >
                            Kailua
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSelectedsearchcities("Kau");
                            }}
                          >
                            Kau
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSelectedsearchcities("Maui");
                            }}
                          >
                            Maui
                          </a>
                        </li>
                      </ul>
                    </div> */}

                    {/* <div
                      className="container text-center align-items-center"
                      style={{
                        padding: "0px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        margin: "0px",
                      }}
                    >
                      <div
                        className="container row text-center align-items-center"
                        style={{ padding: "0px", margin: "0px" }}
                      >
                        <div
                          className="col-lg-9 text-center"
                          style={{
                            textAlign: "center",
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          <div
                            className="col-lg-9 text-center form-check form-check-inline container"
                            style={{
                              textAlign: "center",
                              color: "black",
                              fontFamily: "AvantGarde Bk BT",
                              width: "100%",
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="dropdown01"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                padding: "0px",
                                color: "black",
                                float: "left",
                              }}
                            >
                              Cities: {selectedsearchcities}:
                            </a>

                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdown01"
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={(e) => {
                                    setSelectedsearchcities("All Cities");
                                  }}
                                >
                                  All Cities
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    setSelectedsearchcities("Central Oahu");
                                  }}
                                >
                                  Central Oahu
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    setSelectedsearchcities("Ewa Plain");
                                  }}
                                >
                                  Ewa Plain
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    setSelectedsearchcities("Kailua");
                                  }}
                                >
                                  Kailua
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    setSelectedsearchcities("Kau");
                                  }}
                                >
                                  Kau
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  onClick={() => {
                                    setSelectedsearchcities("Maui");
                                  }}
                                >
                                  Maui
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                    {/* <div
                          className="col-lg-3 text-center"
                          style={{ margin: "0px", padding: "0px" }}
                        >
                          <p
                            className="btn btn-md btn-secondary"
                            style={
                              hoverapplyfilter
                                ? {
                                    left: "4px",
                                    float: "right",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "100%",
                                    maxHeight: "36px",
                                    maxWidth: "180px",
                                    minWidth: "144px",
                                    backgroundColor: "#ffe685",
                                    margin: "0px",
                                    marginBottom: "0px",
                                    padding: "6px",
                                    display: "inline",
                                  }
                                : {
                                    left: "4px",
                                    float: "right",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "100%",
                                    maxHeight: "36px",
                                    maxWidth: "180px",
                                    minWidth: "144px",
                                    backgroundColor: "#ffcc00",
                                    margin: "0px",
                                    marginBottom: "0px",
                                    padding: "6px",
                                    display: "inline",
                                  }
                            }
                            onMouseEnter={togglehoverapplyfilter}
                            onMouseLeave={togglehoverapplyfilter}
                            onClick={toggleactiveapplyfilter}
                          >
                            Apply Filter
                          </p>
                        </div> */}

                    {/* <div
                          className="form-check form-check-inline container"
                          style={{
                            color: "black",
                            padding: "0px",
                            fontFamily: "AvantGarde Bk BT",
                            margin: "0px",
                          }}
                        > */}
                    {/* <div className="container"> */}
                    {/* <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="dropdown01"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              padding: "0px",
                              color: "black",
                            }}
                          >
                            States: {selectedsearchstate}:
                          </a> */}
                    {/* <a style={{ 
                              color: "#008080",
                            }}>{selectedsearchstate}:</a> */}
                    {/* </div> */}
                    {/* <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdown01"
                          >
                            {state.map((data: any, index: any) => {
                              return (
                                <li key={index}>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => {
                                      setSelectedsearchstate(data);
                                    }}
                                  >
                                    {data}
                                  </a>
                                </li>
                              );
                            })}
                          </ul> */}
                    {/* </div> */}
                    {/* </div>
                    </div> */}

                    {/* <div
                      className="border-bottom"
                      style={{ height: "6px" }}
                    ></div> */}
                    <div style={{ height: "6px" }}></div>
                    <div>
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="dropdown01"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          textAlign: "left",
                          padding: "0px",
                          paddingLeft: "16px",
                          color: "black",
                        }}
                      >
                        Search For: {search}:
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdown01"
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSearch("City");
                              inputRefSearch.current.value = "";
                              setSearchtextcity(false);
                              setSearchtextzipcode(false);
                            }}
                          >
                            City
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              setSearch("Zip Code");
                              inputRefSearch.current.value = "";
                              setSearchtextcity(false);
                              setSearchtextzipcode(false);
                            }}
                          >
                            Zip Code
                          </a>
                        </li>
                      </ul>

                      <IonItem
                        id="backgroundlightbrand"
                        className="container-fluid"
                        style={{ padding: "0px", margin: "0px" }}
                        lines="none"
                      >
                        <div
                          className="container row text-center align-items-center"
                          style={{ margin: "0px", padding: "0px" }}
                        >
                          <div
                            className="col-lg-9 text-center"
                            style={{
                              textAlign: "center",
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            <input
                              ref={(ref) => (inputRefSearch.current = ref)}
                              className="form-control"
                              type="text"
                              placeholder={`Enter ${search}`}
                              aria-label={`Enter ${search}`}
                              style={{
                                paddingRight: "6px",
                                paddingLeft: "6px",
                                display: "inline",
                                width: "100%",
                              }}
                              onFocus={showcontentfocus}
                              onBlur={showcontentblur}
                              onKeyUp={changevaluetyping}
                            />
                          </div>
                          <div
                            className="col-lg-3 text-center"
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            <p
                              className="btn btn-md btn-secondary"
                              style={
                                hoversearch
                                  ? {
                                      left: "4px",
                                      float: "right",
                                      textAlign: "center",
                                      cursor: "pointer",
                                      color: "#8f6b00",
                                      width: "100%",
                                      maxHeight: "36px",
                                      maxWidth: "180px",
                                      minWidth: "144px",
                                      backgroundColor: "#ffe685",
                                      margin: "0px",
                                      marginBottom: "0px",
                                      padding: "6px",
                                      display: "inline",
                                    }
                                  : {
                                      left: "4px",
                                      float: "right",
                                      textAlign: "center",
                                      cursor: "pointer",
                                      color: "#8f6b00",
                                      width: "100%",
                                      maxHeight: "36px",
                                      maxWidth: "180px",
                                      minWidth: "144px",
                                      backgroundColor: "#ffcc00",
                                      margin: "0px",
                                      marginBottom: "0px",
                                      padding: "6px",
                                      display: "inline",
                                    }
                              }
                              onMouseEnter={togglehoversearch}
                              onMouseLeave={togglehoversearch}
                              onClick={toggleactivesearch}
                            >
                              Search
                            </p>
                          </div>
                        </div>
                      </IonItem>
                      <div
                        id="myDropdowncontainer"
                        className="container"
                        style={{
                          padding: "0px",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        <div
                          ref={(ref) => (inputRefContentCity.current = ref)}
                          id="myDropdown"
                          className={
                            searchtextcity
                              ? "dropdown-content-show"
                              : "dropdown-content-hide"
                          }
                          style={{
                            padding: "0px",
                            margin: "0px",
                            textAlign: "left",
                          }}
                        >
                          {searchlistcity.map((data: any, index: any) => {
                            return (
                              <a
                                key={index}
                                className="dropdown-content-a"
                                style={{
                                  fontFamily: "Abadi MT Std",
                                  color: "black",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  inputRefSearch.current.value =
                                    searchlistcity[index];
                                  setSearchtextcity(false);
                                }}
                                onMouseEnter={() => {
                                  setHoveroneoftheitems(true);
                                }}
                                onMouseLeave={() => {
                                  setHoveroneoftheitems(false);
                                }}
                              >
                                {data}
                              </a>
                            );
                          })}
                          {/* <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "All Cities";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            All Cities
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Aiea";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Aiea
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Kapolei";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Kapolei
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Ewa Beach";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Ewa Beach
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Haleiwa";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Haleiwa
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Kailua";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Kailua
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Kaneohe";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Kaneohe
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Pearl City";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Pearl City
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Wahiawa";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Wahiawa
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Mililani";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Mililani
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Waialua";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Waialua
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Waianae";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Waianae
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Waipahu";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Waipahu
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Hauula";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Hauula
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "Honolulu";
                              setSearchtextcity(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            Honolulu
                          </a> */}
                        </div>
                      </div>

                      <div
                        id="myDropdowncontainer"
                        className="container"
                        style={{
                          padding: "0px",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        <div
                          ref={(ref) => (inputRefContentZipCode.current = ref)}
                          id="myDropdown"
                          className={
                            searchtextzipcode
                              ? "dropdown-content-show"
                              : "dropdown-content-hide"
                          }
                          style={{
                            padding: "0px",
                            margin: "0px",
                            textAlign: "left",
                          }}
                        >
                          {searchlistzipcode.map((data: any, index: any) => {
                            return (
                              <a
                                key={index}
                                className="dropdown-content-a"
                                style={{
                                  fontFamily: "Abadi MT Std",
                                  color: "black",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  inputRefSearch.current.value =
                                    searchlistzipcode[index];
                                  setSearchtextzipcode(false);
                                }}
                                onMouseEnter={() => {
                                  setHoveroneoftheitems(true);
                                }}
                                onMouseLeave={() => {
                                  setHoveroneoftheitems(false);
                                }}
                              >
                                {data}
                              </a>
                            );
                          })}
                          {/* <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "All Zip Codes";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            All Zip Codes
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96701";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96701
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96707";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96707
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96706";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96706
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96712";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96712
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96734";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96734
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96744";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96744
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96782";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96782
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96786";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96786
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96789";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96789
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96791";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96791
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96792";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96792
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96797";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96797
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96717";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96717
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96813";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96813
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96814";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96814
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96815";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96815
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96816";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96816
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96817";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96817
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96818";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96818
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96819";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96819
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96821";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96821
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96822";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96822
                          </a>
                          <a
                            className="dropdown-content-a"
                            style={{
                              fontFamily: "Abadi MT Std",
                              color: "black",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              inputRefSearch.current.value = "96825";
                              setSearchtextzipcode(false);
                            }}
                            onMouseEnter={() => {
                              setHoveroneoftheitems(true);
                            }}
                            onMouseLeave={() => {
                              setHoveroneoftheitems(false);
                            }}
                          >
                            96825
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </main>
                {/* </div> */}
              </div>

              <div
                className="col-lg-2 text-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <div style={{ height: "0px" }}></div>
              </div>
            </div>
            <div style={{ height: "12px" }}></div>
            <div
              /* className="border-top border-bottom" */ style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
            <div style={{ height: "36px", backgroundColor: "#f4ecd5" }}></div>

            {iserror && (
              <div
                className="container row align-items-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <h1
                  style={{
                    fontSize: "large",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    margin: "0px",
                    padding: "0px",
                    color: "#8A1F61",
                    fontWeight: "bold",
                    backgroundColor: "#f4ecd5",
                  }}
                >
                  ....Failed to load Care Home Providers....
                </h1>
              </div>
            )}

            {datavaluefiltered.length === 0 && !iserror && (
              <div
                className="container row align-items-center"
                style={{ margin: "0px", padding: "0px" }}
              >
                <h1
                  style={{
                    fontSize: "large",
                    textAlign: "center",
                    fontFamily: "AvantGarde Bk BT",
                    margin: "0px",
                    padding: "0px",
                    color: "#8A1F61",
                    fontWeight: "bold",
                    backgroundColor: "#f4ecd5",
                  }}
                >
                  Based on your search query, the Care Home is not available at
                  this time. Please contact seniorHUBsolutions for other Care
                  Home Providers in the area.
                </h1>
              </div>
            )}

            {datavaluefiltered &&
              !iserror &&
              datavaluefiltered.map((data: any, index: any) => {
                return (
                  <React.Fragment
                    key={
                      index
                      /* data.data1.galleryuseruniquenumber +
                      "-" +
                      data.data2.galleryuseruniquenumber +
                      "-" +
                      data.data3.galleryuseruniquenumber */
                    }
                  >
                    <div
                      className="container row align-items-center"
                      style={{
                        margin: "0px",
                        padding: "0px",
                        backgroundColor: "#f4ecd5",
                      }}
                    >
                      <div
                        className="col-lg-4 text-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const datavalue: any = {
                            galleryuseruniquenumber:
                              data.data1.useruniquenumber,
                            galleryimage:
                              data.data1.galleryimage.galleryimagelink +
                              "" +
                              data.data1.galleryimage.galleryimagetoken,
                            galleryviewname: data.data1.galleryviewname,
                            gallerydescription: data.data1.gallerydescription,
                            galleryaddress: data.data1.galleryaddress,
                            /* gallerycityandstateorlocation:
                            data.data1.gallerycityandstateorlocation, */
                            galleryzipcode: data.data1.galleryzipcode,
                            gallerycontactnumber1:
                              data.data1.gallerycontactnumber1,
                            gallerycontactnumber2:
                              data.data1.gallerycontactnumber2,
                            galleryemail: data.data1.galleryemail,
                            gallerylat: data.data1.gallerylat,
                            gallerylng: data.data1.gallerylng,
                            gallerycity: data.data1.gallerycity,
                            gallerystate: data.data1.gallerystate,
                            gallerydetailslistingsection1:
                              data.data1.gallerydetailslistingdesc
                                .gallerydetailslistingsection1,
                            gallerydetailslistingsection2:
                              data.data1.gallerydetailslistingdesc
                                .gallerydetailslistingsection2,
                            gallerydetailslistingsection3:
                              data.data1.gallerydetailslistingdesc
                                .gallerydetailslistingsection3,
                            gallerysubscription: data.data1.gallerysubscription,
                          };
                          localStorage.setItem(
                            "gallery",
                            JSON.stringify(datavalue)
                          );
                          history.push(
                            "/galleryview/" + data.data1.useruniquenumber
                          );
                        }}
                      >
                        <div className="container">
                          <img
                            src={
                              data.data1.galleryimage.galleryimagelink +
                              "" +
                              data.data1.galleryimage.galleryimagetoken
                            }
                            style={{
                              border: "2px solid #ffcc00",
                              borderRadius: "25px",
                              height: "26vh",
                            }}
                          />
                          <div style={{ height: "6px" }}></div>
                          <h1
                            style={{
                              fontSize: "large",
                              textAlign: "center",
                              fontFamily: "AvantGarde Bk BT",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {data.data1.galleryviewname
                              ? data.data1.galleryviewname
                              : "seniorHUBsolutions"}
                          </h1>
                          {!data.data1.galleryviewname && (
                            <a
                              style={{
                                fontSize: "medium",
                                textAlign: "center",
                                fontFamily: "Abadi MT Std",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {`ID: ${data.data1.useruniquenumber}`}
                            </a>
                          )}
                          <div style={{ height: "3px" }}></div>
                          {data.data1.gallerycity &&
                            data.data1.gallerystate &&
                            data.data1.galleryzipcode && (
                              <a
                                style={{
                                  fontSize: "medium",
                                  textAlign: "center",
                                  fontFamily: "Abadi MT Std",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                {`${
                                  data.data1.gallerycity
                                    ? data.data1.gallerycity
                                    : "N/A"
                                }, ${
                                  data.data1.gallerystate
                                    ? data.data1.gallerystate
                                    : "N/A"
                                }, ${
                                  data.data1.galleryzipcode
                                    ? data.data1.galleryzipcode
                                    : "N/A"
                                }`}
                              </a>
                            )}
                          {/* <div style={{ height: "12px" }}></div>
                          <div
                            className="marquee ribbon"
                            style={{ height: "46px" }}
                          >
                            <div
                              className="text"
                              style={{ height: "30px", top: "8px" }}
                            >
                              <div className="marqueediv">
                                <span
                                  className="bold"
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Available Now
                                </span>
                                <span
                                  className="bold"
                                  style={{ color: "black", fontWeight: "bold" }}
                                >
                                  Available Now
                                </span>
                              </div>
                            </div>
                          </div> */}
                          {data.data1.galleryservicetype
                            .galleryservicetypeavailability1 && (
                            <div style={{ height: "12px" }}></div>
                          )}
                          {/* {data.data1.galleryservicetype
                            .galleryservicetypeavailability1 && (
                            <div
                              className="marquee ribbon"
                              style={{ height: "46px" }}
                            > 
                              <div
                                className="text"
                                style={{ height: "30px", top: "8px" }}
                              >
                                <div className="marqueediv">
                                  <span
                                    className="bold"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Available Now
                                  </span>
                                  <span
                                    className="bold"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Available Now
                                  </span>
                                </div>
                              </div>
                            </div>
                          )} */}
                          {data.data1.galleryservicetype
                            .galleryservicetypeavailability2 && (
                            <div style={{ height: "12px" }}></div>
                          )}
                          {/* {data.data1.galleryservicetype
                            .galleryservicetypeavailability2 && (
                            <div
                              className="marquee ribbon"
                              style={{ height: "46px" }}
                            >
                              <div
                                className="text"
                                style={{ height: "30px", top: "8px" }}
                              >
                                <div className="marqueediv">
                                  <span
                                    className="bold"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Available Now
                                  </span>
                                  <span
                                    className="bold"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Available Now
                                  </span>
                                </div>
                              </div>
                            </div>
                          )} */}
                          {data.data1.galleryservicetype
                            .galleryservicetypeavailability3 && (
                            <div style={{ height: "12px" }}></div>
                          )}
                          {/* {data.data1.galleryservicetype
                            .galleryservicetypeavailability3 && (
                            <div
                              className="marquee ribbon"
                              style={{ height: "46px" }}
                            >
                              <div
                                className="text"
                                style={{ height: "30px", top: "8px" }}
                              >
                                <div className="marqueediv">
                                  <span
                                    className="bold"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Available Now
                                  </span>
                                  <span
                                    className="bold"
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Available Now
                                  </span>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </div>
                        <div style={{ height: "12px" }}></div>
                        <div
                          /* className="border-top" */
                          style={{ height: "12px" }}
                        ></div>
                      </div>
                      {/* ======================================================== */}
                      {data.data2 !== "" && (
                        <div
                          className="col-lg-4 text-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const datavalue: any = {
                              galleryuseruniquenumber:
                                data.data2.useruniquenumber,
                              galleryimage:
                                data.data2.galleryimage.galleryimagelink +
                                "" +
                                data.data2.galleryimage.galleryimagetoken,
                              galleryviewname: data.data2.galleryviewname,
                              gallerydescription: data.data2.gallerydescription,
                              galleryaddress: data.data2.galleryaddress,
                              /* gallerycityandstateorlocation:
                              data.data2.gallerycityandstateorlocation, */
                              galleryzipcode: data.data2.galleryzipcode,
                              gallerycontactnumber1:
                                data.data2.gallerycontactnumber1,
                              gallerycontactnumber2:
                                data.data2.gallerycontactnumber2,
                              galleryemail: data.data2.galleryemail,
                              gallerylat: data.data2.gallerylat,
                              gallerylng: data.data2.gallerylng,
                              gallerycity: data.data2.gallerycity,
                              gallerystate: data.data2.gallerystate,
                              gallerydetailslistingsection1:
                                data.data2.gallerydetailslistingdesc
                                  .gallerydetailslistingsection1,
                              gallerydetailslistingsection2:
                                data.data2.gallerydetailslistingdesc
                                  .gallerydetailslistingsection2,
                              gallerydetailslistingsection3:
                                data.data2.gallerydetailslistingdesc
                                  .gallerydetailslistingsection3,
                              gallerysubscription:
                                data.data2.gallerysubscription,
                            };
                            localStorage.setItem(
                              "gallery",
                              JSON.stringify(datavalue)
                            );
                            history.push(
                              "/galleryview/" + data.data2.useruniquenumber
                            );
                          }}
                        >
                          <div className="container">
                            <img
                              src={
                                data.data2.galleryimage.galleryimagelink +
                                "" +
                                data.data2.galleryimage.galleryimagetoken
                              }
                              style={{
                                border: "2px solid #ffcc00",
                                borderRadius: "25px",
                                height: "26vh",
                              }}
                            />
                            <div style={{ height: "6px" }}></div>
                            <h1
                              style={{
                                fontSize: "large",
                                textAlign: "center",
                                fontFamily: "AvantGarde Bk BT",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {data.data2.galleryviewname
                                ? data.data2.galleryviewname
                                : "seniorHUBsolutions"}
                            </h1>
                            {!data.data2.galleryviewname && (
                              <a
                                style={{
                                  fontSize: "medium",
                                  textAlign: "center",
                                  fontFamily: "Abadi MT Std",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                {`ID: ${data.data2.useruniquenumber}`}
                              </a>
                            )}
                            <div style={{ height: "3px" }}></div>
                            {data.data2.gallerycity &&
                              data.data2.gallerystate &&
                              data.data2.galleryzipcode && (
                                <a
                                  style={{
                                    fontSize: "medium",
                                    textAlign: "center",
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`${
                                    data.data2.gallerycity
                                      ? data.data2.gallerycity
                                      : "N/A"
                                  }, ${
                                    data.data2.gallerystate
                                      ? data.data2.gallerystate
                                      : "N/A"
                                  }, ${
                                    data.data2.galleryzipcode
                                      ? data.data2.galleryzipcode
                                      : "N/A"
                                  }`}
                                </a>
                              )}
                            {/* <p className="ribbon">
                                <span className="text">
                                  <strong
                                    className="bold"
                                    style={{ color: "black" }}
                                  >
                                    Available Now
                                  </strong>
                                </span>
                              </p> */}
                            {data.data2.galleryservicetype
                              .galleryservicetypeavailability1 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {/* {data.data2.galleryservicetype
                              .galleryservicetypeavailability1 && (
                              <div
                                className="marquee ribbon"
                                style={{ height: "46px" }}
                              >
                                <div
                                  className="text"
                                  style={{ height: "30px", top: "8px" }}
                                >
                                  <div className="marqueediv">
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )} */}
                            {data.data2.galleryservicetype
                              .galleryservicetypeavailability2 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {/* {data.data2.galleryservicetype
                              .galleryservicetypeavailability2 && (
                              <div
                                className="marquee ribbon"
                                style={{ height: "46px" }}
                              >
                                <div
                                  className="text"
                                  style={{ height: "30px", top: "8px" }}
                                >
                                  <div className="marqueediv">
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )} */}
                            {data.data2.galleryservicetype
                              .galleryservicetypeavailability3 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {/* {data.data2.galleryservicetype
                              .galleryservicetypeavailability3 && (
                              <div
                                className="marquee ribbon"
                                style={{ height: "46px" }}
                              >
                                <div
                                  className="text"
                                  style={{ height: "30px", top: "8px" }}
                                >
                                  <div className="marqueediv">
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )} */}
                          </div>
                          <div style={{ height: "12px" }}></div>
                          <div
                            /* className="border-top" */
                            style={{ height: "12px" }}
                          ></div>
                        </div>
                      )}
                      {/* ======================================================== */}
                      {data.data3 !== "" && (
                        <div
                          className="col-lg-4 text-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const datavalue: any = {
                              galleryuseruniquenumber:
                                data.data3.useruniquenumber,
                              galleryimage:
                                data.data3.galleryimage.galleryimagelink +
                                "" +
                                data.data3.galleryimage.galleryimagetoken,
                              galleryviewname: data.data3.galleryviewname,
                              gallerydescription: data.data3.gallerydescription,
                              galleryaddress: data.data3.galleryaddress,
                              /* gallerycityandstateorlocation:
                              data.data3.gallerycityandstateorlocation, */
                              galleryzipcode: data.data3.galleryzipcode,
                              gallerycontactnumber1:
                                data.data3.gallerycontactnumber1,
                              gallerycontactnumber2:
                                data.data3.gallerycontactnumber2,
                              galleryemail: data.data3.galleryemail,
                              gallerylat: data.data3.gallerylat,
                              gallerylng: data.data3.gallerylng,
                              gallerycity: data.data3.gallerycity,
                              gallerystate: data.data3.gallerystate,
                              gallerydetailslistingsection1:
                                data.data3.gallerydetailslistingdesc
                                  .gallerydetailslistingsection1,
                              gallerydetailslistingsection2:
                                data.data3.gallerydetailslistingdesc
                                  .gallerydetailslistingsection2,
                              gallerydetailslistingsection3:
                                data.data3.gallerydetailslistingdesc
                                  .gallerydetailslistingsection3,
                              gallerysubscription:
                                data.data3.gallerysubscription,
                            };
                            localStorage.setItem(
                              "gallery",
                              JSON.stringify(datavalue)
                            );
                            history.push(
                              "/galleryview/" + data.data3.useruniquenumber
                            );
                          }}
                        >
                          <div className="container">
                            <img
                              src={
                                data.data3.galleryimage.galleryimagelink +
                                "" +
                                data.data3.galleryimage.galleryimagetoken
                              }
                              style={{
                                border: "2px solid #ffcc00",
                                borderRadius: "25px",
                                height: "26vh",
                              }}
                            />
                            <div style={{ height: "6px" }}></div>
                            <h1
                              style={{
                                fontSize: "large",
                                textAlign: "center",
                                fontFamily: "AvantGarde Bk BT",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {data.data3.galleryviewname
                                ? data.data3.galleryviewname
                                : "seniorHUBsolutions"}
                            </h1>
                            {!data.data3.galleryviewname && (
                              <a
                                style={{
                                  fontSize: "medium",
                                  textAlign: "center",
                                  fontFamily: "Abadi MT Std",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                {`ID: ${data.data3.useruniquenumber}`}
                              </a>
                            )}
                            <div style={{ height: "3px" }}></div>
                            {data.data3.gallerycity &&
                              data.data3.gallerystate &&
                              data.data3.galleryzipcode && (
                                <a
                                  style={{
                                    fontSize: "medium",
                                    textAlign: "center",
                                    fontFamily: "Abadi MT Std",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`${
                                    data.data3.gallerycity
                                      ? data.data3.gallerycity
                                      : "N/A"
                                  }, ${
                                    data.data3.gallerystate
                                      ? data.data3.gallerystate
                                      : "N/A"
                                  }, ${
                                    data.data3.galleryzipcode
                                      ? data.data3.galleryzipcode
                                      : "N/A"
                                  }`}
                                </a>
                              )}
                            {data.data3.galleryservicetype
                              .galleryservicetypeavailability1 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {/* {data.data3.galleryservicetype
                              .galleryservicetypeavailability1 && (
                              <div
                                className="marquee ribbon"
                                style={{ height: "46px" }}
                              >
                                <div
                                  className="text"
                                  style={{ height: "30px", top: "8px" }}
                                >
                                  <div className="marqueediv">
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )} */}
                            {data.data3.galleryservicetype
                              .galleryservicetypeavailability2 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {/* {data.data3.galleryservicetype
                              .galleryservicetypeavailability2 && (
                              <div
                                className="marquee ribbon"
                                style={{ height: "46px" }}
                              >
                                <div
                                  className="text"
                                  style={{ height: "30px", top: "8px" }}
                                >
                                  <div className="marqueediv">
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )} */}
                            {data.data3.galleryservicetype
                              .galleryservicetypeavailability3 && (
                              <div style={{ height: "12px" }}></div>
                            )}
                            {/* {data.data3.galleryservicetype
                              .galleryservicetypeavailability3 && (
                              <div
                                className="marquee ribbon"
                                style={{ height: "46px" }}
                              >
                                <div
                                  className="text"
                                  style={{ height: "30px", top: "8px" }}
                                >
                                  <div className="marqueediv">
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                    <span
                                      className="bold"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Available Now
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )} */}
                          </div>
                          <div style={{ height: "12px" }}></div>
                          <div
                            /* className="border-top" */
                            style={{ height: "12px" }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            {/* ======================================================== */}

            <div style={{ height: "36px", backgroundColor: "#f4ecd5" }}></div>
            <div
              /* className="border-top border-bottom" */ style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
            {/* <div style={{ height: "12px" }}></div>
            <h1
              className="text-center"
              style={{
                color: "black",
                fontSize: "small",
                fontFamily: "Abadi MT Std",
                margin: "0px",
                textAlign: "center",
              }}
            >
              <b>Gallery of Homes Disclaimer</b>
            </h1>
            <div style={{ height: "6px" }}></div>
            <p
              style={{
                color: "black",
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
                fontSize: "small",
                margin: "0px",
              }}
            >
              Information about the Adult Residential Care Home and Adult Foster
              Care Home room details, features and services displayed in the
              Gallery of Homes and through this site contain marketing
              information that is sourced and updated by the Valued Care
              Providers. At the time this information is submitted we recognize
              that availability can change and consequently suggest you, the
              reader, user, or browser review and confirm all information.
            </p>
            <div style={{ height: "6px" }}></div>
            <p
              style={{
                color: "black",
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
                fontSize: "small",
                margin: "0px",
              }}
            >
              seniorHUBsolutions makes no representation and assumes no
              responsibility for the accuracy of information contained on, or
              available through this site. Contact the Valued Care Provider
              direct via phone or email with your inquiries.
            </p> */}
            <div style={{ height: "36px" }}></div>
          </div>
        </div>

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default Gallery;
