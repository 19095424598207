/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

/* Components */
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./PrivacyPolicy.css";
/* Others */
import { history } from "../helpers/history";
import { Helmet } from "react-helmet";

const PrivacyPolicy: React.FC = () => {
  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  useEffect(() => {
    var platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);
  }, []);

  let pagename = "seniorHUBsolutions";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <IonHeader className="fixed-top">
        <IonToolbar
          color="primary"
          id="backgroundwhitebrand"
          style={{
            height: "0px",
          }}
        ></IonToolbar>
        <nav
          className="navbar navbar-expand-md"
          aria-label="Main navigation"
          style={{
            height: "66px",
            backgroundImage: "url(" + require("../assets/Background.jpg").default + ")",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></nav>
        <IonToolbar
          id="backgroundwhitebrand"
          /* color="primary" */
          style={{
            backgroundColor: "white",
          }}
        >
          <IonButtons
            slot="start"
            style={{
              width: "100vw",
            }}
          >
            {/* {platformusedglobal !== "ios" && ( */}
            <IonButton
              onClick={() => {
                history.push("/home");
              }}
              style={{
                color: "black",
              }}
            >
              Back
            </IonButton>
            {/* )} */}
            <IonTitle
              style={{
                textAlign: "center",
              }}
            >
              <IonLabel
                style={{
                  verticalAlign: "middle",
                  fontFamily: "Gill Sans Nova",
                  color: "black",
                  fontSize: "20px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;senior
                <b
                  style={{
                    fontFamily: "Gill Sans Nova",
                    color: "black",
                    fontSize: "1.1em",
                    fontWeight: "bold",
                  }}
                >
                  HUB
                </b>
                solutions
              </IonLabel>
            </IonTitle>
            {/* {platformusedglobal !== "ios" && ( */}
            <a
              style={{
                color: "white", //#ffcc00
                width: "57px",
                marginLeft: "2px",
                marginRight: "2px",
                textAlign: "center",
                fontSize: "medium",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {/* )} */}
          </IonButtons>
        </IonToolbar>
        <div className="border-top border-bottom" />
      </IonHeader>

      <div style={{ height: "20vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <div className="container">
          <div className="container-fluid py-5">
            {/* <h1
              className="featurette-heading"
              style={{
                marginTop: "10px",
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                color: "#8A1F61",
                textShadow: "-1px 1px 2px #000",
              }}
            >
              Data Privacy Policy:
            </h1> */}
            <div
              /* className="border-top border-bottom" */ style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
            <div style={{ height: "24px" }}></div>
            {/* <p
              className="lead"
              style={{
                color: "black",
                textAlign: "center",
                fontFamily: "Abadi MT Std",
              }}
            >
              ...content to be uploaded..
            </p> */}
            {/* <div style={{ height: "12px" }} /> */}

            <div
              className="container"
              style={{
                height: "100vh",
                width: "100%",
                margin: "0px",
                padding: "0px",
                backgroundColor: "#f4ecd5",
              }}
            >
              {/* src={require("../../public/dataprivacy.html")} */}
              <iframe
                src="https://www.seniorhubsolutions.com/privacypolicy.html"
                scrolling="yes"
                width="100%"
                height="100%"
              ></iframe>
            </div>

            {/* <Helmet>
              <script
                src="https://app.enzuzo.com/__enzuzo-privacy-app.js?mode=privacy&apiHost=https://app.enzuzo.com&buttonStyle=%0A%7B%0A%20%20%22buttonWidget%22%3A%20%7B%0A%20%20%20%20%22backgroundColor%22%3A%20%22%23ffffff%22%2C%0A%20%20%20%20%22color%22%3A%20%22%23000000%22%2C%0A%20%20%20%20%22%26%3Ahover%22%3A%20%7B%0A%20%20%20%20%20%20%22backgroundColor%22%3A%20%22%23a4a4a4%22%2C%0A%20%20%20%20%20%20%22color%22%3A%20%22%23000000%22%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D%0A&qt=1644437732252&referral=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjozMjA5LCJDdXN0b21lck5hbWUiOiJjdXN0LWd3QjRoN0ZRIiwiQ3VzdG9tZXJMb2dvVVJMIjoiIiwiUm9sZXMiOlsicmVmZXJyYWwiXSwiUHJvZHVjdCI6ImVudGVycHJpc2UiLCJpc3MiOiJFbnp1em8gSW5jLiIsIm5iZiI6MTY0NDQzNzczNH0.-vhriOGpNP0zYXW41kh4m3VM2oV4lM2auYjsoTiwvjk"
                type="text/javascript"
              />
            </Helmet> */}
            {/* <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              Thank you for visiting this Internet web site (“Site”) managed by
              Frontier Management LLC (“[CLIENT]”). This Privacy Policy details
              certain policies implemented throughout [CLIENT] governing
              [CLIENT]’s collection and use of personally identifiable
              information about users of our Site and our services.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              Privacy Policy Updates Due to the Internet’s rapidly evolving
              nature, [CLIENT] may need to update this Privacy Policy from time
              to time. If so, [CLIENT] will post its updated Privacy Policy on
              our Site along with a change notice on the Site. [CLIENT] may also
              send registered users of our services a notice that this Privacy
              Policy has been changed. [CLIENT] encourages you to review this
              Privacy Policy regularly for any changes. Your continued use of
              this Site and/or services and/or your continued provision of
              personally identifiable information to us after the posting of
              such notice will be subject to the terms of the then-current
              Privacy Policy.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Information Collection
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              You can generally visit our Site without revealing any personally
              identifiable information about yourself. However, in certain
              sections of this Site, we may require you to submit your
              personally identifiable information to us and we may invite you to
              submit questions, comments and request information.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              Due to the nature of some of our services, you may provide us with
              personally identifiable information such as your name, email
              address, phone number, and other contact information that you
              voluntarily transmit with your communication to us.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Information Use
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              We may use your personally identifiable information to deliver our
              services or information you have requested, verify that you are a
              customer or prospective customer, send you information that you
              have signed up to receive, notices about our products and/or
              services that you have used or that may be of interest to you and
              notices about events, and improve the content and general
              administration of the Site and our services. If you are a user of
              our services, we may use your personally identifiable information
              to provide the services to you.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Children’s Privacy
            </p>

            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              [CLIENT] recognizes the privacy interests of children and we
              encourage parents and guardians to take an active role in their
              children’s online activities and interests. Neither our Site nor
              our services are intended for children under the age of 13.
              [CLIENT] does not target its services or this Site to children
              under 13. [CLIENT] does not knowingly collect personally
              identifiable information from children under the age of 13.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Third Party Analytics Tools
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              Beyond the personally identifiable information you voluntarily
              provide to us, [CLIENT] utilizes analytics tools including third
              party technologies, such as Google Analytics, to collect
              non-personally identifiable information utilizing cookies. Certain
              types of information, including geolocation, device type, onsite
              website usage and behavior, demographic data, and purchase
              history, is collected and aggregated across [CLIENT]
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Site users for our analysis and advertising efforts.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              Users may learn more about Google Analytics use of cookies by
              visiting the Google Privacy and Terms page at
              https://www.google.com/policies/. You may opt out of being tracked
              by Google Analytics by i) Turning off cookies in the preferences
              settings in your browser ii) Downloading the Google Analytics
              opt-out browser add on available at:
              https://tools.google.com/dlpage/gaoptout/ iii) Opting out of user
              interest and demographic categories in the Settings for Google Ads
              feature to manage or opt out of Google interest based ads at: or
              iv) Managing cookies used for online advertising across multiple
              companies at the US-based Network Advertising Initiative at
              https://www.networkadvertising.org/choices/.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Aggregate Information
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              The Site may track the total number of visitors to our Site, the
              number of visitors to each page of our Site, browser type, IP
              addresses, External Web Sites (defined below) linked to and other
              aggregated data collected through our services and we may analyze
              this data for trends and statistics in the aggregate but such
              information will be maintained, used and disclosed in aggregate
              form only and it will not contain personally identifiable
              information. We may use such aggregate information to analyze
              trends, administer the Site, track users’ movement, and gather
              broad demographic information for aggregate use.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Disclosure of Personally Identifiable Information
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              We may provide your personally identifiable information and the
              data generated by cookies and third party analytics tools and the
              aggregate information to the vendors and service agencies that we
              may engage to assist us in providing our services to you. For
              example, we engage with The Brizendine Group, LLC. to assist us in
              creating and hosting this Site.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              We will disclose your personally identifiable information if we
              reasonably believe we are required to do so by law, regulation or
              other government authority or to assist in any investigation, to
              protect our or our users’ rights or to enforce our terms of use.
              We will not sell your personally identifiable information to any
              company or organization except we may transfer your personally
              identifiable information to a successor entity upon a merger,
              consolidation or other corporate reorganization in which [CLIENT]
              participates or to a purchaser of all or substantially all of
              [CLIENT]’s assets to which this Site relates.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Links to Third Party Sites
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              The Site may provide links to other Web sites or resources over
              which [CLIENT] does not have control (“External Web Sites”). Such
              links do not constitute an endorsement by [CLIENT] of those
              External Web Sites. You acknowledge that [CLIENT] is providing
              these links to you only as a convenience, and further agree that
              [CLIENT] is not responsible for the content of such External Web
              Sites. Your use of External Web Sites is subject to the terms of
              use and privacy policies located on the linked to External Web
              Sites.
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "left",
                fontFamily: "Abadi MT Std",
              }}
            >
              Security
            </p>
            <div style={{ height: "12px" }} />
            <p
              className="lead"
              style={{
                textAlign: "justify",
                fontFamily: "Abadi MT Std",
              }}
            >
              We may employ industry standard procedural and technological
              measures that are reasonably designed to help protect your
              personally identifiable information from loss, unauthorized
              access, disclosure, alteration or destruction. [CLIENT] may use,
              without limitation, firewalls, password protection, secure socket
              layer, and other security measures to help prevent unauthorized
              access to your personally identifiable information.
            </p> */}
            <div style={{ height: "24px" }}></div>
            <div
              /* className="border-top border-bottom" */ style={{
                borderBottom: "4px solid #ffcc00",
              }}
            />
          </div>
        </div>
        <div style={{ height: "36px" }}></div>

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default PrivacyPolicy;
