/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import { IonContent } from "@ionic/react";

/* Components */
import NavBar from "../components/NavBar";
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./Team.css";
/* Others */
//import { history } from "../helpers/history";

const Team: React.FC = () => {
  //const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  //const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  useEffect(() => {}, []);

  let pagename = "Meet your Visionary Team";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <NavBar />

      <div style={{ height: "20vh" }} id="section1"></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <div className="container" color="primary">
          <div className="container">
            <div className="container-fluid py-5">
              <div style={{ height: "36px" }}></div>
              <h1
                className="featurette-heading"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#8A1F61",
                  /* textShadow: "-1px 1px 2px #000", */
                }}
              >
                Our Mission Statement
              </h1>
              {/* <div
                style={{
                  borderBottom: "4px solid #ffcc00",
                }}
              /> */}
              {/* <div style={{ height: "24px" }}></div> */}

              <div
                className="container row align-items-center"
                style={{ margin: "0px", padding: "0px", height: "100%" }}
              >
                <div
                  className="col-lg-1 text-center"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <div style={{ height: "0px" }}></div>
                </div>

                <div
                  className="col-lg-10 text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <p
                    className="lead"
                    style={{
                      color: "black",
                      textAlign: "justify",
                      fontFamily: "Abadi MT Std",
                      margin: "0px",
                      fontSize: "x-large",
                    }}
                  >
                    To empower families facing complex decisions by delivering
                    immediate, continued access to quality and efficient care
                    and a comprehensive understanding of health care options
                    through the company’s platforms.
                  </p>
                </div>

                <div
                  className="col-lg-1 text-center"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <div style={{ height: "0px" }}></div>
                </div>
              </div>
            </div>
            <div style={{ height: "0px" }} id="section2"></div>
          </div>
        </div>

        {/* <hr className="featurette-divider" style={{ color: "black" }} /> */}
        {/* <div style={{ height: "80px" }}></div> */}
        {/* <div
          style={{
            borderTop: "4px solid #ffcc00",
            borderBottom: "4px solid #ffcc00",
            color: "black",
            textAlign: "center",
            height: "33px",
            backgroundImage: "url(" + require("../assets/Background.jpg").default + ")",
            backgroundSize: "contain",
            backgroundRepeat: "repeat",
          }}
        >
          ...pending logo image from Jeanne (with different color/texture)...
        </div> */}
        <div style={{ height: "30px" }}></div>

        <div className="container">
          <div className="container">
            <div className="container-fluid py-5">
              <div style={{ height: "44px" }}></div>
              <h1
                className="featurette-heading"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#8A1F61",
                  /* textShadow: "-1px 1px 2px #000", */
                }}
              >
                Our Vision Statement
              </h1>
              {/* <div
                style={{
                  borderBottom: "4px solid #ffcc00",
                }}
              /> */}
              {/* <div style={{ height: "24px" }}></div> */}

              <div
                className="container row align-items-center"
                style={{ margin: "0px", padding: "0px", height: "100%" }}
              >
                <div
                  className="col-lg-1 text-center"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <div style={{ height: "0px" }}></div>
                </div>

                <div
                  className="col-lg-10 text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <p
                    className="lead"
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontFamily: "Abadi MT Std",
                      margin: "0px",
                      fontSize: "x-large",
                    }}
                  >
                    We strive for improved health outcomes through Ka na’auao me
                    ke aloha!
                  </p>
                  <div style={{ height: "24px" }}></div>
                  <p
                    className="lead"
                    style={{
                      color: "black",
                      textAlign: "center",
                      fontFamily: "Abadi MT Std",
                      margin: "0px",
                      fontSize: "x-large",
                    }}
                  >
                    [Knowledge and wisdom with compassion]
                  </p>
                </div>

                <div
                  className="col-lg-1 text-center"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <div style={{ height: "0px" }}></div>
                </div>
              </div>
            </div>
            <div style={{ height: "0px" }} id="section3"></div>
          </div>
        </div>

        {/* <hr className="featurette-divider" style={{ color: "black" }} /> */}
        {/* <div style={{ height: "80px" }}></div> */}
        {/* <div
          style={{
            borderTop: "4px solid #ffcc00",
            borderBottom: "4px solid #ffcc00",
            color: "black",
            textAlign: "center",
            height: "33px",
            backgroundImage: "url(" + require("../assets/Background.jpg").default + ")",
            backgroundSize: "contain",
            backgroundRepeat: "repeat",
          }}
        >
          ...pending logo image from Jeanne (with different color/texture)...
        </div> */}
        <div style={{ height: "30px" }}></div>

        <div
          /* id="headline" */
          className="container"
          style={{
            /* backgroundImage: "url('" + require("../assets/Headline.png").default + "')", */
            opacity: "100%",
            backgroundRepeat: "repeat",
            backgroundSize: "100% 100%",
          }}
        >
          <div className="container">
            <div className="container-fluid py-5">
              <div style={{ height: "44px" }}></div>
              <h1
                className="featurette-heading"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#8A1F61",
                  /* textShadow: "-1px 1px 2px #000", */
                }}
              >
                {pagename}
              </h1>

              {/* <div
                style={{
                  borderBottom: "4px solid #ffcc00",
                }}
              /> */}

              <div style={{ height: "24px" }}></div>

              <div
                className="container row align-items-center"
                style={{ margin: "0px", padding: "0px", height: "100%" }}
              >
                <div
                  className="col-lg-1 text-center"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <div style={{ height: "0px" }}></div>
                </div>

                <div
                  className="col-lg-10 text-center"
                  style={{
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <div className="container">
                    <p
                      className="lead"
                      style={{
                        color: "black",
                        textAlign: "justify",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        fontFamily: "Abadi MT Std",
                        margin: "0px",
                        fontSize: "x-large",
                      }}
                    >
                      Our multi-disciplinary team applies extensive health care
                      hands-on experience and expertise to a carefully
                      engineered Web Portal that senior
                      <a
                        style={{
                          color: "black",
                          fontSize: "1.1em",
                          fontFamily: "Abadi MT Std",
                          fontWeight: "bold",
                          margin: "0px",
                        }}
                      >
                        HUB
                      </a>
                      solutions has designed with built-in ease and assurance,
                      helping you to plan for immediate or future care needs. We
                      draw upon our core values of Innovation, Integrity, and
                      Trust in delivering directly to your health care options
                      that allow you to plan efficiently and confidently.
                    </p>
                  </div>
                  <div style={{ height: "120px" }}></div>

                  {/* <div className="border-top" /> */}
                  <div
                    /* className="text-muted" */ style={{
                      /* borderTop: "4px solid #ffcc00",
                borderBottom: "4px solid #ffcc00", */
                      color: "black",
                      textAlign: "center",
                      height: "26px",
                      backgroundImage:
                        "url(" + require("../assets/Background.jpg").default + ")",
                      backgroundSize: "contain",
                      backgroundRepeat: "repeat",
                    }}
                  >
                    {/* ...pending logo image from Jeanne (with different
                    color/texture)... */}
                  </div>

                  <div style={{ height: "24px" }}></div>
                  <div className="container">
                    {/* //======================================================= */}
                    <div
                      className="container row align-items-center"
                      style={{ margin: "0px", padding: "0px", height: "100%" }}
                    >
                      <div
                        className="col-lg-8 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div className="row featurette">
                          <div
                            className="col-md-6 text-center"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={require("../assets/Profile-Jeanne.jpg").default}
                              style={{
                                /* border: "2px solid black", */
                                height: "relative",
                                width: "relative",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                          <div className="col-md-6 text-center">
                            <div style={{ height: "8px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "right",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "large",
                              }}
                            >
                              {/* <b>Meet</b> */}
                            </p>
                            <div style={{ height: "18px" }}></div>
                            <div
                              style={{
                                textAlign: "right",
                                height: "12px",
                                borderTop: "4px solid black",
                                width: "50%",
                                position: "relative",
                                right: "-50%",
                              }}
                            ></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "left",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Angelinas",
                                margin: "0px",
                                fontSize: "xxx-large",
                              }}
                            >
                              Jeanne Ching
                            </p>
                            <div style={{ height: "16px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "justify",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "medium",
                              }}
                            >
                              As principal founder and innovator of senior
                              <a
                                style={{
                                  color: "black",
                                  fontSize: "1.1em",
                                  fontFamily: "Abadi MT Std",
                                  fontWeight: "bold",
                                  margin: "0px",
                                }}
                              >
                                HUB
                              </a>
                              solutions,&nbsp; Jeanne Ching’s dedication was
                              inspired by her extensive journey working with
                              Hawaii’s seniors, their families, and caregivers.
                              Appreciating the legacy of ancestral wisdom lined
                              to Kūpuna, Jeanne believes that Kūpuna are to be
                              respected and honored. For this reason, she is
                              actively engaged in exploring ways to be a
                              credible source of information and ensuring that
                              access to information is available to families and
                              their loved ones.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* //======================================================= */}
                      <div
                        className="col-lg-4 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div style={{ height: "0px" }}></div>
                      </div>
                    </div>
                    {/* //======================================================= */}
                  </div>
                  <div style={{ height: "24px" }}></div>

                  {/* <div className="border-top" /> */}
                  {/* <div
                    style={{
                      color: "black",
                      textAlign: "center",
                      height: "26px",
                      backgroundImage:
                        "url(" + require("../assets/Background.jpg").default + ")",
                      backgroundSize: "contain",
                      backgroundRepeat: "repeat",
                    }}
                  >
                  </div> */}

                  {/* <div style={{ height: "24px" }}></div> */}

                  {/* <div className="container">
                    <div
                      className="container row align-items-center"
                      style={{ margin: "0px", padding: "0px", height: "100%" }}
                    >
                      <div
                        className="col-lg-4 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div style={{ height: "0px" }}></div>
                      </div>

                      <div
                        className="col-lg-8 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div className="row featurette">
                          <div className="col-md-6">
                            <div style={{ height: "8px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "left",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "large",
                              }}
                            >
                            </p>
                            <div style={{ height: "18px" }}></div>
                            <div
                              style={{
                                textAlign: "left",
                                height: "12px",
                                borderTop: "4px solid black",
                                width: "50%",
                              }}
                            ></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "left",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Angelinas",
                                margin: "0px",
                                fontSize: "xxx-large",
                              }}
                            >
                              Darlene Canto
                            </p>
                            <div style={{ height: "16px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "justify",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "medium",
                              }}
                            >
                              Influenced at an early age honoring Kūpuna,
                              Darlene Canto committed to a 17-year marketing
                              career in the senior living industry. A BA in
                              psychology made for a successful marketing
                              directorship which readily expanded to include
                              community outreach. This responsibility provided
                              Darlene with a deeper insight to the needs of
                              Hawaii’s Kūpuna. Darlene is resolute in
                              collaboration efforts with all who can improve
                              health care options for seniors.
                            </p>
                          </div>
                          <div
                            className="col-md-6"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={require("../assets/Profile-Darlene.jpg").default}
                              style={{
                                height: "relative",
                                width: "relative",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div style={{ height: "24px" }}></div> */}

                  {/* <div className="border-top" /> */}
                  <div
                    /* className="text-muted" */ style={{
                      /* borderTop: "4px solid #ffcc00",
                borderBottom: "4px solid #ffcc00", */
                      color: "black",
                      textAlign: "center",
                      height: "26px",
                      backgroundImage:
                        "url(" + require("../assets/Background.jpg").default + ")",
                      backgroundSize: "contain",
                      backgroundRepeat: "repeat",
                    }}
                  >
                    {/* ...pending logo image from Jeanne (with different
                    color/texture)... */}
                  </div>

                  <div style={{ height: "24px" }}></div>
                  <div className="container">
                    {/* //======================================================= */}
                    <div
                      className="container row align-items-center"
                      style={{ margin: "0px", padding: "0px", height: "100%" }}
                    >
                      <div
                        className="col-lg-8 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div className="row featurette">
                          <div
                            className="col-md-6 text-center"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={require("../assets/Profile-Randy.jpg").default}
                              style={{
                                /* border: "2px solid black", */
                                height: "relative",
                                width: "relative",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                          <div className="col-md-6 text-center">
                            <div style={{ height: "8px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "right",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "large",
                              }}
                            >
                              {/* <b>Meet</b> */}
                            </p>
                            <div style={{ height: "18px" }}></div>
                            <div
                              style={{
                                textAlign: "right",
                                height: "12px",
                                borderTop: "4px solid black",
                                width: "50%",
                                position: "relative",
                                right: "-50%",
                              }}
                            ></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "left",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Angelinas",
                                margin: "0px",
                                fontSize: "xxx-large",
                              }}
                            >
                              Randy De Vera
                            </p>
                            <div style={{ height: "16px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "justify",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "medium",
                              }}
                            >
                              As principal founder and innovator of senior
                              <a
                                style={{
                                  color: "black",
                                  fontSize: "1.1em",
                                  fontFamily: "Abadi MT Std",
                                  fontWeight: "bold",
                                  margin: "0px",
                                }}
                              >
                                HUB
                              </a>
                              solutions, Randy De Vera, a devoted health care
                              professional of 20 years, is credited with
                              extensive knowledge in long-term care
                              administration, assisted living, memory support,
                              home health, adult, and pediatric care. Randy’s
                              personal goal is to channel his mana’ o and
                              expertise toward health care providers in meeting
                              their values and beliefs. Randy also holds a
                              Nursing degree and a Bachelor of Science in Health
                              Care Administration-Long Term Care.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* //======================================================= */}
                      <div
                        className="col-lg-4 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div style={{ height: "0px" }}></div>
                      </div>
                    </div>
                    {/* //======================================================= */}
                  </div>
                  <div style={{ height: "24px" }}></div>

                  {/* <div className="border-top" /> */}
                  <div
                    /* className="text-muted" */ style={{
                      /* borderTop: "4px solid #ffcc00",
                borderBottom: "4px solid #ffcc00", */
                      color: "black",
                      textAlign: "center",
                      height: "26px",
                      backgroundImage:
                        "url(" + require("../assets/Background.jpg").default + ")",
                      backgroundSize: "contain",
                      backgroundRepeat: "repeat",
                    }}
                  >
                    {/* ...pending logo image from Jeanne (with different
                    color/texture)... */}
                  </div>

                  <div style={{ height: "24px" }}></div>
                  <div className="container">
                    {/* //======================================================= */}
                    <div
                      className="container row align-items-center"
                      style={{ margin: "0px", padding: "0px", height: "100%" }}
                    >
                      <div
                        className="col-lg-4 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div style={{ height: "0px" }}></div>
                      </div>
                      {/* //======================================================= */}
                      <div
                        className="col-lg-8 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div className="row featurette">
                          <div className="col-md-6">
                            <div style={{ height: "8px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "left",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "large",
                              }}
                            >
                              {/* <b>Meet</b> */}
                            </p>
                            <div style={{ height: "18px" }}></div>
                            <div
                              style={{
                                textAlign: "left",
                                height: "12px",
                                borderTop: "4px solid black",
                                width: "50%",
                              }}
                            ></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "left",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Angelinas",
                                margin: "0px",
                                fontSize: "xxx-large",
                              }}
                            >
                              Alan Pajota
                            </p>
                            <div style={{ height: "16px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "justify",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "medium",
                              }}
                            >
                              A business consultant for international companies
                              and social media influencer, Alan Pajota’s
                              extensive knowledge include business analytics and
                              the planning and forecasting of business financial
                              operations. Alan’s love of travel and embracing
                              diverse cultures has led him to strongly believe
                              in a quote by Max Ehrmann, “If you compare
                              yourself to others, you may become vain and
                              bitter; for always there will be greater and
                              lesser persons than yourself”.
                            </p>
                          </div>
                          <div
                            className="col-md-6"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={require("../assets/Profile-Alan.jpg").default}
                              style={{
                                /* border: "2px solid black", */
                                height: "relative",
                                width: "relative",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* //======================================================= */}
                  </div>
                  <div style={{ height: "24px" }}></div>

                  {/* <div className="border-top" /> */}
                  <div
                    /* className="text-muted" */ style={{
                      /* borderTop: "4px solid #ffcc00",
                borderBottom: "4px solid #ffcc00", */
                      color: "black",
                      textAlign: "center",
                      height: "26px",
                      backgroundImage:
                        "url(" + require("../assets/Background.jpg").default + ")",
                      backgroundSize: "contain",
                      backgroundRepeat: "repeat",
                    }}
                  >
                    {/* ...pending logo image from Jeanne (with different
                    color/texture)... */}
                  </div>

                  <div style={{ height: "24px" }}></div>
                  <div className="container">
                    {/* //======================================================= */}
                    <div
                      className="container row align-items-center"
                      style={{ margin: "0px", padding: "0px", height: "100%" }}
                    >
                      <div
                        className="col-lg-8 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div className="row featurette">
                          <div
                            className="col-md-6 text-center"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={require("../assets/Profile-Bobby.jpg").default}
                              style={{
                                /* border: "2px solid black", */
                                height: "relative",
                                width: "relative",
                                borderRadius: "4px",
                              }}
                            />
                          </div>
                          <div className="col-md-6 text-center">
                            <div style={{ height: "8px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "right",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "large",
                              }}
                            >
                              {/* <b>Meet</b> */}
                            </p>
                            <div style={{ height: "18px" }}></div>
                            <div
                              style={{
                                textAlign: "right",
                                height: "12px",
                                borderTop: "4px solid black",
                                width: "50%",
                                position: "relative",
                                right: "-50%",
                              }}
                            ></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "left",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Angelinas",
                                margin: "0px",
                                fontSize: "xxx-large",
                              }}
                            >
                              Bobby Victa
                            </p>
                            <div style={{ height: "16px" }}></div>
                            <p
                              className="lead"
                              style={{
                                color: "black",
                                textAlign: "justify",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                fontFamily: "Abadi MT Std",
                                margin: "0px",
                                fontSize: "medium",
                              }}
                            >
                              Computer science and information technology are
                              just a few of Bobby Victa&#39;s greatest assets.
                              He brings his years of experience to his current
                              role with seniorHUBsolutions. Bobby’s expertise in
                              technology is an integral part in the function and
                              design of the seniorHUBsolutions Web Portal.
                              Bobby’s knowledge in accounting has earned him his
                              degree as a certified public accountant and a
                              certified information systems auditor. Bobby
                              enjoys spending quality time with his family and
                              believes that the road to success comes through
                              hard work, determination, and commitment!
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* //======================================================= */}
                      <div
                        className="col-lg-4 text-center"
                        style={{ margin: "0px", padding: "0px" }}
                      >
                        <div style={{ height: "0px" }}></div>
                      </div>
                    </div>
                    {/* //======================================================= */}
                  </div>
                </div>

                <div
                  className="col-lg-1 text-center"
                  style={{ margin: "0px", padding: "0px" }}
                >
                  <div style={{ height: "0px" }}></div>
                </div>
              </div>

              <div style={{ height: "24px" }}></div>
              {/* <div
                style={{
                  borderBottom: "4px solid #ffcc00",
                }}
              /> */}
            </div>
          </div>
        </div>
        <div style={{ height: "36px" }}></div>

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default Team;
