/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
//import { useParams } from "react-router";
import { IonContent } from "@ionic/react";

/* Components */
import NavBar from "../components/NavBar";
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
/* Theme variables */
import "./HelpfulInformation.css";
/* Others */
//import { history } from "../helpers/history";

const HelpfulInformation: React.FC = () => {
  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  useEffect(() => {
    const platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);

    const isnative: any = localStorage.getItem("isnative")
      ? localStorage.getItem("isnative")
      : "";

    setIsnativeusedglobal(isnative);

    window.addEventListener("message", (event) => {
      if (event.data && event.data.sender === "links") {
        localStorage.setItem("links", event.data.message);
        window.location.reload();
      }
    });
  }, []);

  async function launchLink1() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.vaccines.gov/",
        },
        "*"
      );
    } else {
      window.open("https://www.vaccines.gov/", "_system");
    }
  }
  async function launchLink2() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.cdc.gov/",
        },
        "*"
      );
    } else {
      window.open("https://www.cdc.gov/", "_system");
    }
  }
  async function launchLink3() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.elderlyaffairs.com/",
        },
        "*"
      );
    } else {
      window.open("https://www.elderlyaffairs.com/", "_system");
    }
  }
  async function launchLink4() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.medicare.gov/",
        },
        "*"
      );
    } else {
      window.open("https://www.medicare.gov/", "_system");
    }
  }
  async function launchLink5() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.hi-ltc-ombudsman.org/",
        },
        "*"
      );
    } else {
      window.open("https://www.hi-ltc-ombudsman.org/", "_system");
    }
  }
  async function launchLink6() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://health.hawaii.gov/ohca/state-licensing-section/",
        },
        "*"
      );
    } else {
      window.open(
        "https://health.hawaii.gov/ohca/state-licensing-section/",
        "_system"
      );
    }
  }
  async function launchLink7() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://www.comties.com/_files/ugd/fcb480_bbc6dc78625a42d89408d5edabd8307a.pdf",
        },
        "*"
      );
    } else {
      window.open(
        "https://www.comties.com/_files/ugd/fcb480_bbc6dc78625a42d89408d5edabd8307a.pdf",
        "_system"
      );
    }
  }
  async function launchLink8() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: "https://www.comties.com/",
        },
        "*"
      );
    } else {
      window.open("https://www.comties.com/", "_system");
    }
  }

  let pagename = "Helpful Information";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <NavBar />

      <div style={{ height: "20vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        <div className="container">
          <div className="container-fluid py-5">
            <h1
              className="featurette-heading"
              style={{
                marginTop: "10px",
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                color: "#8A1F61",
                /* textShadow: "-1px 1px 2px #000", */
              }}
            >
              {pagename}
            </h1>
            {/* <div
              style={{
                borderBottom: "4px solid #ffcc00",
              }}
            /> */}
            {/* <div style={{ height: "24px" }}></div> */}

            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <p
                  className="lead"
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Abadi MT Std",
                    padding: "0px",
                    margin: "0px",
                    fontSize: "large",
                  }}
                >
                  *&nbsp;
                  <a
                    style={{
                      color: "black",
                      cursor: "pointer",
                      /* textDecorationLine: "underline", */
                    }}
                    onClick={launchLink1}
                  >
                    www.vaccines.gov
                  </a>
                </p>
              </li>
              <div style={{ height: "6px" }}></div>
              <li className="nav-item">
                <p
                  className="lead"
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Abadi MT Std",
                    padding: "0px",
                    margin: "0px",
                    fontSize: "large",
                  }}
                >
                  *&nbsp;
                  <a
                    style={{
                      color: "black",
                      cursor: "pointer",
                      /* textDecorationLine: "underline", */
                    }}
                    onClick={launchLink2}
                  >
                    www.cdc.gov
                  </a>
                </p>
              </li>
              <div style={{ height: "6px" }}></div>
              <li className="nav-item">
                <p
                  className="lead"
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Abadi MT Std",
                    padding: "0px",
                    margin: "0px",
                    fontSize: "large",
                  }}
                >
                  *&nbsp;
                  <a
                    style={{
                      color: "black",
                      cursor: "pointer",
                      /* textDecorationLine: "underline", */
                    }}
                    onClick={launchLink3}
                  >
                    www.elderlyaffairs.com
                  </a>
                </p>
              </li>
              <div style={{ height: "6px" }}></div>
              <li className="nav-item">
                <p
                  className="lead"
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Abadi MT Std",
                    padding: "0px",
                    margin: "0px",
                    fontSize: "large",
                  }}
                >
                  *&nbsp;
                  <a
                    style={{
                      color: "black",
                      cursor: "pointer",
                      /* textDecorationLine: "underline", */
                    }}
                    onClick={launchLink4}
                  >
                    www.medicare.gov
                  </a>
                </p>
              </li>
              <div style={{ height: "6px" }}></div>
              <li className="nav-item">
                <p
                  className="lead"
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Abadi MT Std",
                    padding: "0px",
                    margin: "0px",
                    fontSize: "large",
                  }}
                >
                  *&nbsp;
                  <a
                    style={{
                      color: "black",
                      cursor: "pointer",
                      /* textDecorationLine: "underline", */
                    }}
                    onClick={launchLink5}
                  >
                    www.hi-ltc-ombudsman.org
                  </a>
                </p>
              </li>
              <div style={{ height: "6px" }}></div>
              <li className="nav-item">
                <p
                  className="lead"
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Abadi MT Std",
                    padding: "0px",
                    margin: "0px",
                    fontSize: "large",
                  }}
                >
                  *&nbsp;
                  <a
                    style={{
                      color: "black",
                      cursor: "pointer",
                      /* textDecorationLine: "underline", */
                    }}
                    onClick={launchLink6}
                  >
                    health.hawaii.gov/ohca/state-licensing-section/
                  </a>
                </p>
              </li>
              <div style={{ height: "6px" }}></div>
              <li className="nav-item">
                <p
                  className="lead"
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Abadi MT Std",
                    padding: "0px",
                    margin: "0px",
                    fontSize: "large",
                  }}
                >
                  *&nbsp;
                  <a
                    style={{
                      color: "black",
                      cursor: "pointer",
                      /* textDecorationLine: "underline", */
                    }}
                    onClick={launchLink7}
                  >
                    www.comties.com/_files/ugd/fcb480_bbc6dc78625a42d89408d5edabd8307a.pdf
                  </a>
                </p>
              </li>
              <div style={{ height: "6px" }}></div>
              <li className="nav-item">
                <p
                  className="lead"
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontFamily: "Abadi MT Std",
                    padding: "0px",
                    margin: "0px",
                    fontSize: "large",
                  }}
                >
                  *&nbsp;
                  <a
                    style={{
                      color: "black",
                      cursor: "pointer",
                      /* textDecorationLine: "underline", */
                    }}
                    onClick={launchLink8}
                  >
                    www.comties.com/
                  </a>
                </p>
              </li>
            </ul>
            <div style={{ height: "24px" }}></div>
            {/* <div
              style={{
                borderBottom: "4px solid #ffcc00",
              }}
            /> */}
          </div>
        </div>
        <div style={{ height: "36px" }}></div>

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default HelpfulInformation;
