/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";

/* Components */
import FooterMain from "../components/FooterMain";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
import Maps from "../components/Maps"
/* Theme variables */
import "./GalleryView.css";
/* Services */
import AuthService from "../services/auth.service";
/* Others */
import { history } from "../helpers/history";
import { isEmail } from "validator";
import { format, parseISO, } from "date-fns";

/* import { Button } from "react-bootstrap"; */
/* import ButtonGroup from 'react-bootstrap/ButtonGroup'; 
import ToggleButton from 'react-bootstrap/ToggleButton'; */

const formatDate = (value: string) => {
  return format(parseISO(value), 'MMM dd yyyy');
};

const FAQs: React.FC = () => {
  const { carehomeid } = useParams<{ carehomeid: string }>();

  const [platformusedglobal, setPlatformusedglobal] = useState<string>("");
  const [isnativeusedglobal, setIsnativeusedglobal] = useState<string>("");
  /* const { user: currentUser } = useSelector((state: any) =>
    state.auth ? state.auth : null
  );
  const { mselected: currentMenu } = useSelector((state: any) =>
    state ? state : null
  );
  const { msubselected: currentsubMenu } = useSelector((state: any) =>
    state ? state : null
  ); */

  const [datavalue, setDatavalue] = useState<any>([]);

  const [galleryuseruniquenumber, setGalleryuseruniquenumber] = useState<string>("");
  const [galleryimage, setGalleryimage] = useState<string>("");
  const [galleryviewname, setGalleryviewname] = useState<string>("");
  const [gallerydescription, setGallerydescription] = useState<string>("");
  const [galleryaddress, setGalleryaddress] = useState<string>("");
  const [galleryzipcode, setGalleryzipcode] = useState<string>("");
  const [gallerycontactnumber1, setGallerycontactnumber1] = useState<string>("");
  const [gallerycontactnumber2, setGallerycontactnumber2] = useState<string>("");
  const [galleryemail, setGalleryemail] = useState<string>("");
  const [gallerycity, setGallerycity] = useState<string>("");
  const [gallerystate, setGallerystate] = useState<string>("");
  const [gallerylocationlat, setGallerylocationlat] = useState<string>("0.0");
  const [gallerylocationlng, setGallerylocationlng] = useState<string>("0.0");
  const [gallerysection1, setGallerysection1] = useState<any>([]);
  const [gallerysection2, setGallerysection2] = useState<any>([]);
  const [gallerysection3, setGallerysection3] = useState<any>([]);
  const [gallerysubscription, setGallerysubscription] = useState<any>([]);

  const [hover1, setHover1] = useState<boolean>(false);
  const [hover2, setHover2] = useState<boolean>(false);

  const [showmodal, setShowmodal] = useState<boolean>(false);
  const [showcontactrequest, setShowcontactrequest] = useState<boolean>(false);
  const [showschedule, setShowschedule] = useState<boolean>(false);
  const [callprovider, setCallprovider] = useState<boolean>(true);

  const [dateandtime, setDateandtime] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [contactnumber, setContactnumber] = useState<string>("");
  const [bestdaymon, setBestdaymon] = useState<boolean>(false);
  const [bestdaytue, setBestdaytue] = useState<boolean>(false);
  const [bestdaywed, setBestdaywed] = useState<boolean>(false);
  const [bestdaythu, setBestdaythu] = useState<boolean>(false);
  const [bestdayfri, setBestdayfri] = useState<boolean>(false);
  const [bestdaysat, setBestdaysat] = useState<boolean>(false);
  const [bestdaysun, setBestdaysun] = useState<boolean>(false);
  const [besttime, setBesttime] = useState<string>("");

  const [isok, setIsok] = useState<boolean>(false);
  const [iserror, setIserror] = useState<boolean>(false);
  const [showloading, setShowloading] = useState<boolean>(false);
  const [messagelocal, setMessagelocal] = useState<string>("");

  const [showloadingindividualview, setShowloadingindividualview] = useState<boolean>(false);
  const [iserrorindividualview, setIserrorindividualview] = useState<boolean>(false);

  useEffect(() => {
    const platform: any = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";

    setPlatformusedglobal(platform);

    const isnative: any = localStorage.getItem("isnative")
      ? localStorage.getItem("isnative")
      : "";

    setIsnativeusedglobal(isnative);

    window.addEventListener("message", (event) => {
      if (event.data && event.data.sender === "socmed") {
        localStorage.setItem("socmed", event.data.message);
        window.location.reload();
      } else if (event.data && event.data.sender === "links") {
        localStorage.setItem("links", event.data.message);
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("gallery")) {
      var gallery: any = localStorage.getItem("gallery")
      ? localStorage.getItem("gallery")
      : {};
      var galleryJSON: any = JSON.parse(gallery);

      if (galleryJSON.galleryuseruniquenumber === carehomeid) {
        setGalleryuseruniquenumber(galleryJSON.galleryuseruniquenumber);
        setGalleryimage(galleryJSON.galleryimage);
        setGalleryviewname(galleryJSON.galleryviewname);
        setGallerydescription(galleryJSON.gallerydescription);
        setGalleryaddress(galleryJSON.galleryaddress);
        setGalleryzipcode(galleryJSON.galleryzipcode);
        setGallerycontactnumber1(galleryJSON.gallerycontactnumber1);
        setGallerycontactnumber2(galleryJSON.gallerycontactnumber2);
        setGalleryemail(galleryJSON.galleryemail);
        setGallerycity(galleryJSON.gallerycity);
        setGallerystate(galleryJSON.gallerystate);
        setGallerylocationlat(galleryJSON.gallerylat);
        setGallerylocationlng(galleryJSON.gallerylng);
        setGallerysection1(galleryJSON.gallerydetailslistingsection1);
        setGallerysection2(galleryJSON.gallerydetailslistingsection2);
        setGallerysection3(galleryJSON.gallerydetailslistingsection3);
        setGallerysubscription(galleryJSON.gallerysubscription);
      } else { 
        initialgatherofprovider();
      }
    } else { 
      initialgatherofprovider();
    }
  }, [galleryviewname]);

  useEffect(() => {}, []);

  function initialgatherofprovider() { 
      //carehomeid
      setShowloadingindividualview(true);

      AuthService.galleryviewbyuseruniquenumber(carehomeid)
        .then((data: any) => {
          setShowloadingindividualview(false);

          setDatavalue([]);
          const datavalueraw: any = localStorage.getItem("galleryraw");
          JSON.parse(datavalueraw).map((datasub: any) => {
            datavalue.push(datasub);
          });

          const datavaluetoview: any = {
            galleryuseruniquenumber:
            datavalue[0].useruniquenumber,
            galleryimage:
            datavalue[0].galleryimage.galleryimagelink +
              "" +
              datavalue[0].galleryimage.galleryimagetoken,
            galleryviewname: datavalue[0].galleryviewname,
            gallerydescription: datavalue[0].gallerydescription,
            galleryaddress: datavalue[0].galleryaddress,
            /* gallerycityandstateorlocation:
            datavalue[0].gallerycityandstateorlocation, */
            galleryzipcode: datavalue[0].galleryzipcode,
            gallerycontactnumber1:
            datavalue[0].gallerycontactnumber1,
            gallerycontactnumber2:
            datavalue[0].gallerycontactnumber2,
            galleryemail: datavalue[0].galleryemail,
            gallerylat: datavalue[0].gallerylat,
            gallerylng: datavalue[0].gallerylng,
            gallerycity: datavalue[0].gallerycity,
            gallerystate: datavalue[0].gallerystate,
            gallerydetailslistingsection1:
            datavalue[0].gallerydetailslistingdesc
                .gallerydetailslistingsection1,
            gallerydetailslistingsection2:
            datavalue[0].gallerydetailslistingdesc
                .gallerydetailslistingsection2,
            gallerydetailslistingsection3:
            datavalue[0].gallerydetailslistingdesc
                .gallerydetailslistingsection3,
            gallerysubscription: datavalue[0].gallerysubscription,
          };

          localStorage.setItem(
            "gallery",
            JSON.stringify(datavaluetoview)
          );

          var gallery: any = localStorage.getItem("gallery")
          ? localStorage.getItem("gallery")
          : {};
          var galleryJSON: any = JSON.parse(gallery);
    
          setGalleryuseruniquenumber(galleryJSON.galleryuseruniquenumber);
          setGalleryimage(galleryJSON.galleryimage);
          setGalleryviewname(galleryJSON.galleryviewname);
          setGallerydescription(galleryJSON.gallerydescription);
          setGalleryaddress(galleryJSON.galleryaddress);
          setGalleryzipcode(galleryJSON.galleryzipcode);
          setGallerycontactnumber1(galleryJSON.gallerycontactnumber1);
          setGallerycontactnumber2(galleryJSON.gallerycontactnumber2);
          setGalleryemail(galleryJSON.galleryemail);
          setGallerycity(galleryJSON.gallerycity);
          setGallerystate(galleryJSON.gallerystate);
          setGallerylocationlat(galleryJSON.gallerylat);
          setGallerylocationlng(galleryJSON.gallerylng);
          setGallerysection1(galleryJSON.gallerydetailslistingsection1);
          setGallerysection2(galleryJSON.gallerydetailslistingsection2);
          setGallerysection3(galleryJSON.gallerydetailslistingsection3);
          setGallerysubscription(galleryJSON.gallerysubscription);
        })
        .catch((error: any) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setIserrorindividualview(true);
          setShowloadingindividualview(false);
        });
  }

  function gallerycheck() { 
    var msubselected: any = localStorage.getItem("msubselected")
    ? localStorage.getItem("msubselected")
      : "";
    
    if (msubselected === "4" || msubselected === "0") {
      history.push("/gallery/arch");
    } else if (msubselected === "5") {
      history.push("/gallery/earch");
    } else if (msubselected === "6") {
      history.push("/gallery/afch");
    }
  }

  function togglehover1() {
    setHover1(!hover1);
  }
  function togglehover2() {
    setHover2(!hover2);
  }
  
  function togglebestday1() {
    setBestdaymon(!bestdaymon);
  }
  function togglebestday2() {
    setBestdaytue(!bestdaytue);
  }
  function togglebestday3() {
    setBestdaywed(!bestdaywed);
  }
  function togglebestday4() {
    setBestdaythu(!bestdaythu);
  }
  function togglebestday5() {
    setBestdayfri(!bestdayfri);
  }
  function togglebestday6() {
    setBestdaysat(!bestdaysat);
  }
  function togglebestday7() {
    setBestdaysun(!bestdaysun);
  }

  function toggleactive1() {
    setCallprovider(true);
    /* if (localStorage.getItem("communitycontactrequest")) { */
      launchTel();
    /* } else { 
      setShowcontactrequest(true);
    } */
  }
  function toggleactive2() {
    //setCallprovider(false);
    /* if (localStorage.getItem("communitycontactrequest")) { */
      //launchEmail();
    /* } else { 
      setShowcontactrequest(true);
    } */

    setShowschedule(true);
  }

  function contactrequestformsave() {
    if (!firstname) {
      setMessagelocal("Please enter a first name");
      setIserror(true);
      return;
    }
    if (!lastname) {
      setMessagelocal("Please enter a last name");
      setIserror(true);
      return;
    }
    if (!email) {
      setMessagelocal("Please enter a valid email");
      setIserror(true);
      return;
    }
    if (isEmail(email) === false) {
      setMessagelocal("Your email is invalid");
      setIserror(true);
      return;
    }
    if (!contactnumber) {
      setMessagelocal("Please enter a contact number");
      setIserror(true);
      return;
    }
    /* if (!bestdaymon && !bestdaytue && !bestdaywed && !bestdaythu && !bestdayfri && !bestdaysat && !bestdaysun) {
      setMessagelocal("Please select at least one best day to call you");
      setIserror(true);
      return;
    } */
    /* if (!besttime) {
      setMessagelocal("Please enter your best time to call"); 
      setIserror(true);
      return;
    } */

    // save code here
    /* if (bestdaymon) { 
      alert("monday - true");
    }
    if (!bestdaymon) { 
      alert("monday - false");
    }
    if (bestdaytue) { 
      alert("tuesday - true");
    }
    if (!bestdaytue) { 
      alert("tuesday - false");
    }
    if (bestdaywed) { 
      alert("wednesday - true");
    }
    if (!bestdaywed) { 
      alert("wednesday - false");
    }
    if (bestdaythu) { 
      alert("thursday - true");
    }
    if (!bestdaythu) { 
      alert("thursday - false");
    }
    if (bestdayfri) { 
      alert("friday - true");
    }
    if (!bestdayfri) { 
      alert("friday - false");
    }
    if (bestdaysat) { 
      alert("saturday - true");
    }
    if (!bestdaysat) { 
      alert("saturday - false");
    }
    if (bestdaysun) { 
      alert("sunday - true");
    }
    if (!bestdaysun) { 
      alert("sunday - false");
    } */

    setShowloading(true);
  }

  function schedule() {
    if (!dateandtime) {
      setMessagelocal("Please select Date and Time");
      setIserror(true);
      return;
    }
    if (!name) {
      setMessagelocal("Please enter a Name");
      setIserror(true);
      return;
    }
    if (email || contactnumber) {
      if (email) {
        if (isEmail(email) === false) {
          setMessagelocal("Your email is invalid");
          setIserror(true);
          return;
        }
      }
    } else { 
      setMessagelocal("Please enter a valid email or contact number");
      setIserror(true);
      return;
    }

    setShowloading(true);

    AuthService.schedule(galleryuseruniquenumber, galleryviewname, dateandtime, name, email, contactnumber) //
      .then((data: any) => {
        setShowloading(false);
        setIsok(true);
        setDateandtime("");
        setName("");
        setEmail("");
        setContactnumber("");
      })
      .catch((error: any) => {
        setShowloading(false);
        setIsok(true);
        setDateandtime("");
        setName("");
        setEmail("");
        setContactnumber("");
      });
  }

  async function launchEmail() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: `mailto:${galleryemail}`,
        },
        "*"
      );
    }
    else {
      window.open(`mailto:${galleryemail}`, "_system");
    }
  }

  async function launchTel() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message: `tel:${gallerycontactnumber1}`,
        },
        "*"
      );
    }
    else {
      window.open(`tel:${gallerycontactnumber1}`, "_system");
    }
  }

  async function launchLink1() {
    const platformused = localStorage.getItem("platform")
      ? localStorage.getItem("platform")
      : "";
    if (platformused) {
      window.parent.postMessage(
        {
          sender: "links",
          message:
            "https://www.google.com/maps/search/?api=1&query=" + gallerylocationlat + "," + gallerylocationlng + "&zoom=15",
        },
        "*"
      );
    } else {
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + gallerylocationlat + "," + gallerylocationlng + "&zoom=15",
        "_system"
      );
    }
  }

  let pagename = "seniorHUBsolutions";

  return (
    <IonContent>
      <div id="top" style={{ height: "0px" }}></div>
      <IonHeader className="fixed-top">
        <IonToolbar
          color="primary"
          id="backgroundwhitebrand"
          style={{
            height: "0px",
          }}
        ></IonToolbar>
        <nav
          className="navbar navbar-expand-md"
          aria-label="Main navigation"
          style={{
            height: "66px",
            backgroundImage: "url(" + require("../assets/Background.jpg").default + ")",
            backgroundSize: "cover",
            backgroundRepeat: "repeat",
          }}
        ></nav>
        <IonToolbar
          id="backgroundwhitebrand"
          /* color="primary" */
          style={{
            backgroundColor: "white",
          }}
        >
          <IonButtons
            slot="start"
            style={{
              width: "100vw",
            }}
          >
            {/* {platformused !== "ios" && ( */}
            <IonButton
              onClick={gallerycheck}
              style={{
                color: "black",
              }}
            >
              Back
            </IonButton>
            {/* )} */}
            <IonTitle
              style={{
                textAlign: "center",
              }}
            >
              <IonLabel
                style={{
                  textAlign: "center",
                  verticalAlign: "middle",
                  fontFamily: "Gill Sans Nova",
                  color: "black",
                  fontSize: "20px",
                  width: "100%",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  fontWeight: "normal",
                }}
              >
                &nbsp;senior
                <a
                  style={{
                    fontFamily: "Gill Sans Nova",
                    color: "black",
                    fontSize: "1.1em",
                    fontWeight: "bold",
                  }}
                >
                  HUB
                </a>
                solutions
              </IonLabel>
            </IonTitle>
            {/* {platformused !== "ios" && ( */}
            <a
              style={{
                color: "white", //#ffcc00
                width: "57px",
                marginLeft: "2px",
                marginRight: "2px",
                textAlign: "center",
                fontSize: "medium",
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            {/* )} */}
          </IonButtons>
        </IonToolbar>
        <div className="border-top border-bottom" />
      </IonHeader>

      <IonModal id="heightmodal" isOpen={showmodal} onDidDismiss={() => setShowmodal(false)} >
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle
              className="ion-text-center"
              style={{ fontSize: "large", color: "#8f6b00", }}
            >
            {`${galleryviewname}'s Map Location`}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonItem id="backgroundwhiteitem" style={{ width: "100%", height: "100%", padding: "0" }} >
          <IonCard id="backgroundwhiteitem" 
            style={{
              width: "100%",
              height: "100%",
              padding: "0",
            }}
          >
            <IonCardContent id="backgroundwhiteitem">
              {/* <Maps /> */}
              <div className="container" style={{ height: "100%", width: "100%", padding: "0px", margin: "0px", }}><div style={{ height: "100%", width: "100%", padding: "0px", margin: "0px", }}><iframe width="100%" height="500px" id="gmap_canvas" src={`https://maps.google.com/maps?q=${galleryaddress.replace(" ","%20")},${gallerycity},${gallerystate}&t=&z=15&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no" ></iframe></div></div>
            </IonCardContent>
            {/* <IonButton
              expand="block"
              onClick={launchLink1}
              style={{
                margin: "0px", 
                color: "#8f6b00",
                width: "100%",
                fontSize: "medium",
                //height: "56px",
              }}>
              View in Google Maps
            </IonButton> */}
            {/* <div style={{ height: "6px", }}></div> */}
            <IonButton
              expand="block"
              onClick={() => setShowmodal(false)}
              style={{
                margin: "0px", 
                color: "#8f6b00",
                width: "100%",
                fontSize: "medium",
                /* height: "56px", */
              }}>
              Close
            </IonButton>
          </IonCard>
        </IonItem>
        <IonLabel id="backgroundwhiteitem" className="border-top">&nbsp;</IonLabel>
      </IonModal>

      <IonLoading
        cssClass="ionbackgroundclass"
        isOpen={showloading}
        /* onDidDismiss={() => {
          AuthService.contactrequestform(firstname, lastname, email, contactnumber, bestdaymon, bestdaytue, bestdaywed, bestdaythu, bestdayfri, bestdaysat, bestdaysun, besttime, galleryuseruniquenumber) //
          .then((data: any) => {
            localStorage.setItem("communitycontactrequest", "yes");
            setShowcontactrequest(false);
            setShowloading(false);
            if (callprovider) {
              launchTel();
            } else { 
              launchEmail();
            }
          })
          .catch((error: any) => {
            setShowcontactrequest(false);
            setShowloading(false);
            if (callprovider) {
              launchTel();
            } else { 
              launchEmail();
            }
          });
        }} */
        message={"Please wait..."}
        duration={60000}
      />      

      <IonModal id="heightcontactrequest" /* isOpen={showcontactrequest} onDidDismiss={() => setShowcontactrequest(false)} */ isOpen={showschedule} onDidDismiss={() => setShowschedule(false)} >
        <IonAlert
          isOpen={iserror}
          onDidDismiss={() => setIserror(false)}
          cssClass="ionbackgroundclass"
          header={"Error!"}
          message={messagelocal}
          buttons={["Dismiss"]}
        />
        <IonAlert
          isOpen={isok}
          onDidDismiss={() => { setIsok(false); setShowschedule(false); }}
          cssClass="ionbackgroundclass"
          header={`Nice to meet you ${name},`}
          message={"Thanks for getting in touch — we’re excited you have chosen to tour our care home. I will be in contact with you to confirm the date and time."}
          buttons={["OK"]}
        />
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle
              className="ion-text-center"
              style={{ fontSize: "large", color: "#8f6b00", }}
            >
            Visit my home, schedule a Tour{/* Contact Request Form */}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{ height: "24px", }}></div>
        <IonItem id="backgroundfleshitem" style={{ width: "100%", height: "100%", padding: "0", }} >
          <IonCard id="backgroundfleshitem" 
            style={{
              width: "100%",
              height: "100%",
              padding: "0",
              backgroundColor: "#f4ecd5",
            }}
          >
            <IonCardContent id="backgroundfleshitem" style={{ backgroundColor: "#f4ecd5", }}>
              <main className="form-signin">
                <form>
                  {/* <h1
                    className="h3 mb-3 fw-normal"
                    style={{ fontFamily: "Abadi MT Std", fontSize: "medium", color: "black", }}
                  >
                    We look forward to helping you. We will be in touch with you shortly.
                  </h1> */}
                  <h1
                    className="h3 mb-3 fw-normal"
                    style={{ fontFamily: "Abadi MT Std", fontSize: "large", color: "black", /* fontWeight: "bold", */ }}
                  >
                    <b>{galleryviewname}</b>
                  </h1>
                  <h1
                    className="h3 mb-3 fw-normal"
                    style={{ fontFamily: "Abadi MT Std", fontSize: "medium", color: "black", }}
                  >
                    {galleryaddress}
                  </h1>

                  {/* <div className="form-floating input-group date" id="datetimepicker1">
                    <input type="text" className="form-control" />
                    <span className="input-group-addon" style={{ width: "20px", }}>
                    <span className="glyphicon glyphicon-calendar" style={{ width: "20px", }}></span>
                    </span>
                    <label style={{ fontFamily: "Abadi MT Std" }}>
                      Requested Date and Time of Tour:
                    </label>
                  </div> */}

                  {/* <div className="form-floating">
                    <IonDatetime className="form-control" style={{ fontFamily: "Abadi MT Std" }}
                        //presentation='date-time' value={new Date().toString()}
                        onIonChange={e => setDateandtime(formatDate(e.detail.value!))}
                    />
                    <label style={{ fontFamily: "Abadi MT Std" }}>
                      Requested Date and Time of Tour:
                    </label>
                  </div> */}
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Jan 01 2022 12:00:00 AM"
                      onChange={(e) => setDateandtime(e.target.value!)}
                      style={{ fontFamily: "Abadi MT Std" }}
                    />
                    <label style={{ fontFamily: "Abadi MT Std" }}>
                      Requested Date and Time of Tour:
                    </label>
                  </div>
                  <div style={{ height: "6px" }}></div>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="John"
                      onChange={(e) => setName(e.target.value!)}
                      style={{ fontFamily: "Abadi MT Std" }}
                    />
                    <label style={{ fontFamily: "Abadi MT Std" }}>
                      Your Name:
                    </label>
                  </div>
                  <div style={{ height: "6px" }}></div>
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                      onChange={(e) => setEmail(e.target.value!)}
                      style={{ fontFamily: "Abadi MT Std" }}
                    />
                    <label style={{ fontFamily: "Abadi MT Std" }}>
                      Email address:
                    </label>
                  </div>
                  <div style={{ height: "6px" }}></div>
                  <div className="form-floating">
                    <input
                      /* type="tel" */
                      className="form-control"
                      id="floatingInput"
                      /* id="phone" name="phone" */
                      placeholder="+1 (808) 000-0000"
                      /* pattern="[+][0-9]{1,2} [0-9]{3} [0-9]{3}-[0-9]{4}" */
                      onChange={(e) => setContactnumber(e.target.value!)}
                      style={{ fontFamily: "Abadi MT Std" }}
                    />
                    <label style={{ fontFamily: "Abadi MT Std" }}>
                      Your Phone Number:
                    </label>
                  </div>

                  {/* <div style={{ height: "16px" }}></div> */}
                  {/* <div className="form-floating"> */}
                    {/* <p style={{ fontFamily: "Abadi MT Std", color: "black", }}>
                    *We take your information and privacy very seriously. To protect confidentiality, DO NOT
                    communicate via this form regarding clinical information. Please call the Valued Care Provider
                    to discuss specific clinical questions or concerns. 
                    </p> */}
                    {/* <p style={{ fontFamily: "Abadi MT Std", color: "black", }}>
                    Best Day to call me: 
                    </p> */}
                    {/* <div className="row align-items-start" >
                      <div className="col-lg-2 text-center"
                      style={{ width: "80px", padding: "0px", }}>
                        <div className="container" style={{ width: "80px", margin: "0px", padding: "0px", }}>
                          <p style={{ margin: "0px", color: "black", fontFamily: "Abadi MT Std", }}>Mon</p>
                          <IonToggle slot="start" name="monday" color="primary" style={{ paddingTop: "4px", paddingBottom: "4px", paddingRight: "4px", }} onClick={togglebestday1}></IonToggle>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center"
                      style={{ width: "80px", padding: "0px", }}>
                        <div className="container" style={{ width: "80px", margin: "0px", padding: "0px", }}>
                        <p style={{ margin: "0px", color: "black", fontFamily: "Abadi MT Std", }}>Tue</p>
                          <IonToggle slot="start" name="tuesday" color="primary" style={{ paddingTop: "4px", paddingBottom: "4px", paddingRight: "4px", }} onClick={togglebestday2}></IonToggle>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center"
                      style={{ width: "80px", padding: "0px", }}>
                        <div className="container" style={{ width: "80px", margin: "0px", padding: "0px", }}>
                        <p style={{ margin: "0px", color: "black", fontFamily: "Abadi MT Std", }}>Wed</p>
                          <IonToggle slot="start" name="wednesday" color="primary" style={{ paddingTop: "4px", paddingBottom: "4px", paddingRight: "4px", }} onClick={togglebestday3}></IonToggle>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center"
                      style={{ width: "80px", padding: "0px", }}>
                        <div className="container" style={{ width: "80px", margin: "0px", padding: "0px", }}>
                        <p style={{ margin: "0px", color: "black", fontFamily: "Abadi MT Std", }}>Thu</p>
                          <IonToggle slot="start" name="thursday" color="primary" style={{ paddingTop: "4px", paddingBottom: "4px", paddingRight: "4px", }} onClick={togglebestday4}></IonToggle>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center"
                      style={{ width: "80px", padding: "0px", }}>
                        <div className="container" style={{ width: "80px", margin: "0px", padding: "0px", }}>
                        <p style={{ margin: "0px", color: "black", fontFamily: "Abadi MT Std", }}>Fri</p>
                          <IonToggle slot="start" name="wednesday" color="primary" style={{ paddingTop: "4px", paddingBottom: "4px", paddingRight: "4px", }} onClick={togglebestday5}></IonToggle>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center"
                      style={{ width: "80px", padding: "0px", }}>
                        <div className="container" style={{ width: "80px", margin: "0px", padding: "0px", }}>
                        <p style={{ margin: "0px", color: "black", fontFamily: "Abadi MT Std", }}>Sat</p>
                          <IonToggle slot="start" name="thursday" color="primary" style={{ paddingTop: "4px", paddingBottom: "4px", paddingRight: "4px", }} onClick={togglebestday6}></IonToggle>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center"
                      style={{ width: "80px", padding: "0px", }}>
                        <div className="container" style={{ width: "80px", margin: "0px", padding: "0px", }}>
                        <p style={{ margin: "0px", color: "black", fontFamily: "Abadi MT Std", }}>Sun</p>
                          <IonToggle slot="start" name="wednesday" color="primary" style={{ paddingTop: "4px", paddingBottom: "4px", paddingRight: "4px", }} onClick={togglebestday7}></IonToggle>
                        </div>
                      </div>
                    </div> */}
                  {/* </div> */}
                  {/* <div style={{ height: "16px" }}></div> */}
                  {/* <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Please fill in your available time"
                      onChange={(e) => setBesttime(e.target.value!)}
                      style={{ fontFamily: "Abadi MT Std" }}
                    />
                    //<label style={{ fontFamily: "Abadi MT Std" }}>
                    //Best Time to call me:
                    //</label>
                    <label style={{ fontFamily: "Abadi MT Std" }}>
                    Questions/Comments: Please DO NOT submit clinical concerns via
                    this form.
                    </label>
                  </div> */}
                </form>
              </main>

            </IonCardContent>
            <IonButton
              expand="block"
              /* onClick={contactrequestformsave} */
              onClick={schedule}
              style={{
                margin: "0px", 
                paddingLeft: "16px",
                paddingRight: "16px",
                color: "#8f6b00",
                width: "100%",
                fontSize: "medium",
              }}
            >
              Submit
            </IonButton>
            <div id="contactrequestdisappearclosebutton" style={{ height: "12px", }}></div>
            <IonButton
              id="contactrequestdisappearclosebutton"
              expand="block"
              /* onClick={() => setShowcontactrequest(false)} */
              onClick={() => setShowschedule(false)}
              style={{
                margin: "0px", 
                paddingLeft: "16px",
                paddingRight: "16px",
                color: "#8f6b00",
                width: "100%",
                fontSize: "medium",
              }}
            >
              Cancel
            </IonButton>

            <div style={{ height: "24px" }}></div>
            <IonCardContent>
              <p
                className="h3 mb-3 fw-normal"
                style={{ fontFamily: "Abadi MT Std", fontSize: "medium", color: "black", /* fontWeight: "bold", */ }}
              >
                <b>Agreement to be Contacted by Email or Telephone</b>
              </p>
              <p
                className="h3 mb-3 fw-normal"
                style={{ fontFamily: "Abadi MT Std", fontSize: "small", color: "black", /* fontWeight: "bold", */ }}
              >
                By submitting this form, you agree to be contacted by the Valued Care Provider and a seniorHUBsolutions Team Member Advisor to receive senior options and information at the email address and/or telephone number you have provided in the form.
              </p>
            </IonCardContent>
          </IonCard>
        </IonItem>
        <IonLabel id="backgroundfleshitem" className="border-top">&nbsp;</IonLabel>
      </IonModal>

      <IonAlert
          isOpen={iserrorindividualview}
          /* onDidDismiss={() => setIserrorindividualview(false)} */
          cssClass="ionbackgroundclass"
          header={"Error!"}
          message={"Failed loading Care Home Provider"}
          buttons={["Dismiss"]}
        />
      <IonLoading
        cssClass="ionbackgroundclass"
        isOpen={showloadingindividualview}
        onDidDismiss={() => setShowloadingindividualview(false)}
        message={"Please wait..."}
        duration={60000}
      />

      <div style={{ height: "20vh" }}></div>
      <div style={{ height: "100%", width: "100%", marginBottom: "-60px" }}>
        {iserrorindividualview && (
          <div className="container">
            <h1
              style={{
                fontSize: "large",
                textAlign: "center",
                fontFamily: "AvantGarde Bk BT",
                margin: "0px",
                padding: "0px",
                color: "#8A1F61",
                fontWeight: "bold",
              }}
            >
              ....Failed loading Care Home Provider....
            </h1>
            <div style={{ height: "36px" }}></div>
          </div>
        )}
        
        {!showloadingindividualview && !iserrorindividualview && (
          <div className="container" /* style={{ padding: "0px", }} */>
            <div className="container-fluid py-5" style={{ padding: "0px", }}>
              {/* <h1
                className="featurette-heading"
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  fontFamily: "AvantGarde Bk BT",
                  color: "#8A1F61",
                }}
              >
                {galleryviewname}
              </h1> */}

              {/* <div style={{ height: "12px" }}></div>
              <div
                style={{
                  borderBottom: "4px solid #ffcc00",
                }}
              /> */}
              
              {/* <div style={{ height: "24px", fontFamily: "Abadi MT Std" }}></div> */}

              <div className="container" style={{ textAlign: "left", padding: "0px", width: "100%", }}>
                <div className="row align-items-center" style={{ border: "1px solid gray", backgroundColor: "#f4ecd5", width: "100%", padding: "0px", margin: "0px", }}>
                  <div className="container col-lg-7"/*  align-top */ style={{
                    /* width: "100%", */ textAlign: "center", padding: "0px", margin: "0px", /* alignContent: "center", flexWrap: "wrap", */ /* verticalAlign: "top", */ /* paddingRight: `${() => { if (window.innerWidth > 976) { return ("12px"); } else { return ("0px"); }; }}`, */ }}>
                    <img
                      src={galleryimage}
                      style={{
                        /* border: "2px solid #8A1F61", */
                        width: "100%",
                        /* borderRadius: "6px", */
                      }}
                      />
                    {/* <div style={{ height: "12px" }}></div> */}
                  </div>
                  
                  <div className="container col-lg-5" style={{ padding: "0px", /* alignContent: "center", flexWrap: "wrap", */ /* width: "100%",  */ /* verticalAlign: "top", */ }}>
                    <div style={{ height: "12px" }}></div>
                    <div className="container" style={{ /* border: "1px solid gray", */ width: "100%", backgroundColor: "#f4ecd5", textAlign: "center", margin: "0px", }}>
                      <ul style={{ color: "black", margin: "0px", marginTop: "16px", marginBottom: "16px", padding: "0px", }}>
                      <h1
                        className="featurette-heading"
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                          fontFamily: "AvantGarde Bk BT",
                          color: "#8A1F61",
                        }}
                      >
                        SHS ID Number: PLACEHOLDER ID NUMBER
                        {/* `"SHS ID Number: "${galleryviewname}` */}
                        {/* {galleryviewname ? galleryviewname : `seniorHUBsolutions`} */}
                      </h1>
                        {/* {!galleryviewname && <div className="container" style={{ fontFamily: "Abadi MT Std", padding: "0px", paddingBottom: "6px", color: "#008080", fontSize: "large", }}><b>ID: </b><a style={{ fontFamily: "Abadi MT Std", color: "black", fontSize: "large", }} >{galleryuseruniquenumber ? galleryuseruniquenumber : "N/A"}</a></div>} */}
                        {/* {gallerysubscription && <div className="container" style={{ fontFamily: "Abadi MT Std", padding: "0px", paddingBottom: "6px", color: "#008080", fontSize: "large", }}><b>Address: </b><a style={{ fontFamily: "Abadi MT Std", color: "black", fontSize: "large", }} >{`${galleryaddress ? galleryaddress : "N/A"} `}</a><a style={{ fontFamily: "Abadi MT Std", textDecoration: "underline", cursor: "pointer", color: "#8A1F61", fontSize: "large", }} onClick={() => setShowmodal(true)}><b>View Map >></b></a></div>} */}
                      {/* <div className="container" style={{ fontFamily: "Abadi MT Std", padding: "0px", paddingBottom: "6px", color: "#008080", fontSize: "large", }}><b>Address: </b><a style={{ fontFamily: "Abadi MT Std", color: "black", fontSize: "large", }} >{`${galleryaddress ? galleryaddress : "N/A"} `}</a><a style={{ fontFamily: "Abadi MT Std", textDecoration: "underline", cursor: "pointer", color: "#8A1F61", fontSize: "large", }} onClick={() => setShowmodal(true)}><b>View Map >></b></a></div> */}
                      <div className="container" style={{ fontFamily: "Abadi MT Std", padding: "0px", paddingBottom: "6px", color: "#008080", fontSize: "large", }}><b>City: </b><a style={{ fontFamily: "Abadi MT Std", color: "black", fontSize: "large", }} >{`${gallerycity ? gallerycity : "N/A"},`}</a><b> State: </b><a style={{ fontFamily: "Abadi MT Std", color: "black", fontSize: "large", }} >{`${gallerystate ? gallerystate : "N/A"},`}</a><b> Zip Code: </b><a style={{ fontFamily: "Abadi MT Std", color: "black", fontSize: "large", }} >{galleryzipcode ? galleryzipcode : "N/A"}</a></div>
                      {/* <div className="container" style={{ fontFamily: "Abadi MT Std", padding: "0px", paddingBottom: "6px", color: "#008080", fontSize: "large", }}><b>Contact Number: </b><a style={{ fontFamily: "Abadi MT Std", color: "black", fontSize: "large", }} >{gallerycontactnumber1 ? gallerycontactnumber1 : "N/A"}</a></div> */}
                      {/* <div className="container" style={{ fontFamily: "Abadi MT Std", padding: "0px", paddingBottom: "6px", color: "#008080", fontSize: "large", }}><b>Email: </b><a style={{ fontFamily: "Abadi MT Std", color: "black", fontSize: "large", }} >{galleryemail ? galleryemail : "N/A"}</a></div> */}
                      </ul>
                    </div>
                    {/* <div style={{ height: "12px" }}></div> */}
                    <div className="container" style={{ width: "100%", padding: "0px", margin: "0px", }}>
                      <div className="align-items-center" style={{ /* border: "1px solid gray", */ width: "100%", backgroundColor: "#f4ecd5", margin: "0px", }}>
                        <div className="container text-left" style={{ backgroundColor: "#f4ecd5", color: "black", height: "156px", padding: "0px" }}>
                        <p
                            style={{
                              textAlign: "center",
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            <a
                              className="btn btn-lg btn-secondary col-md-12 order-md-1"
                              style={
                                hover1
                                ? {
                                    textAlign: "center",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "100%",
                                    margin: "0px",
                                    maxHeight: "56px",
                                    maxWidth: "360px",
                                    minWidth: "360px",
                                    backgroundColor: "#ffe685",
                                  }
                                : {
                                    textAlign: "center",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "100%",
                                    margin: "0px",
                                    maxHeight: "56px",
                                    maxWidth: "360px",
                                    minWidth: "360px",
                                    backgroundColor: "#ffcc00",
                                  }
                              }
                              onMouseEnter={togglehover1}
                              onMouseLeave={togglehover1}
                              onClick={toggleactive1}
                            >
                              Explore Your Options, give us a call!
                            </a>
                          </p>
                          <div style={{
                            height: "8px" }}></div>
                          <p
                            style={{
                              textAlign: "center",
                              padding: "0px",
                              margin: "0px",
                            }}
                          >
                            <a
                              className="btn btn-lg btn-secondary col-md-12 order-md-1"
                              style={
                                hover2
                                ? {
                                    textAlign: "center",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "100%",
                                    margin: "0px",
                                    maxHeight: "56px",
                                    maxWidth: "360px",
                                    minWidth: "360px",
                                    backgroundColor: "#ffe685",
                                  }
                                : {
                                    textAlign: "center",
                                    cursor: "pointer",
                                    color: "#8f6b00",
                                    width: "100%",
                                    margin: "0px",
                                    maxHeight: "56px",
                                    maxWidth: "360px",
                                    minWidth: "360px",
                                    backgroundColor: "#ffcc00",
                                  }
                              }
                              onMouseEnter={togglehover2}
                              onMouseLeave={togglehover2}
                              onClick={toggleactive2}
                            >
                              Visit my home, schedule a tour.
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ height: "12px" }}></div> */}
                  </div>
                </div>
                <div style={{ height: "12px" }}></div>
                <div className="container" style={{ border: "1px solid gray", width: "100%", padding: "0px", textAlign: "left", color: "black" }}>
                  <div className="row align-items-start" style={{  width: "100%", backgroundColor: "#f4ecd5", margin: "0px", padding: "0px", }}>
                    <div className="container text-left col-lg-6 align-top" style={{ backgroundColor: "#f4ecd5", color: "black", margin: "0px", verticalAlign: "top", }}>
                        {gallerysection1 &&
                          (<div style={{ height: "12px" }}></div>)}
                        {gallerysection1 &&
                          (<div style={{ textAlign: "left", /* textDecoration: "underline", */ }}><h1 style={{ fontFamily: "Abadi MT Std", color: "#008080", fontSize: "large", }}><b>ROOM DETAILS:</b></h1></div>)}
                        {gallerysection1 && 
                          (gallerysection1.map((data: any) => {
                            return (
                              <div key={data} style={{ fontFamily: "Abadi MT Std", textAlign: "left", }}>{data}</div>
                            );
                          }))}
                        {gallerysection1 &&
                          (<div style={{ height: "12px" }}></div>)}
                        {gallerysection2 && 
                          (<div style={{ textAlign: "left", /* textDecoration: "underline", */ }}><h1 style={{ fontFamily: "Abadi MT Std", color: "#008080", fontSize: "large", }}><b>ROOM FEATURES:</b></h1></div>)}
                        {gallerysection2 && 
                          (gallerysection2.map((data: any) => {
                            return (
                              <div key={data} style={{ fontFamily: "Abadi MT Std", textAlign: "left", }}>{data}</div>
                            );
                          }))}
                        {/* {gallerysection2 &&
                          (<div style={{ fontFamily: "Abadi MT Std", textAlign: "left", fontStyle: "italic", }}>*Consult with your Valued Care Provider for further information</div>)} */}
                        {gallerysection2 &&
                          (<div style={{ height: "12px" }}></div>)}
                      </div>
                      <div className="container text-left col-lg-6 align-top" style={{ backgroundColor: "#f4ecd5", color: "black", margin: "0px", verticalAlign: "top", }}>
                        {gallerysection3 &&
                          (<div style={{ height: "12px" }}></div>)}
                        {gallerysection3 &&
                          <div style={{ textAlign: "left", /* textDecoration: "underline", */ }}><h1 style={{ fontFamily: "Abadi MT Std", color: "#008080", fontSize: "large", }}><b>SERVICES PROVIDED:</b></h1></div>}
                        {gallerysection3 && 
                          (gallerysection3.map((data: any) => {
                            return (
                              <div key={data} style={{ textAlign: "left", paddingLeft: "24px", }}><li style={{ fontFamily: "Abadi MT Std", }}>{data}</li></div>
                            );
                          }))}
                        {/* {gallerysection3 &&
                          (<div style={{ textAlign: "left", paddingLeft: "24px", fontStyle: "italic", }}><li style={{ fontFamily: "Abadi MT Std", }}>Consult with your Valued Care Provider for further information</li></div>)} */}
                        {gallerysection3 &&
                          (<div style={{ height: "12px" }}></div>)}
                    </div>
                  </div>
                </div>
              </div>
            
              {/* <div className="container" style={{ fontFamily: "Abadi MT Std", width: "100%", padding: "0px", textAlign: "justify", color: "black" }}>The home features and services displayed on these pages contain marketing information provided by the Valued Care Providers. senior<b style={{fontFamily: "Abadi MT Std",}}>HUB</b>solutions cannot guarantee its accuracy, please consult with your Valued Care Provider for further information. If you feel we need to be updated with any information, please feel to reach out to us at memberservice@seniorhubsolutions.com.</div> */}

              <div style={{ height: "6px" }}></div>
              <h1
                className="text-center"
                style={{
                  color: "black",
                  fontSize: "small",
                  fontFamily: "Abadi MT Std",
                  margin: "0px",
                  textAlign: "center",
                }}
              >
                <b>Gallery of Homes Disclaimer</b>
              </h1>
              <div style={{ height: "6px" }}></div>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "small",
                  margin: "0px",
                }}
              >
                Information about the Adult Residential Care Home and Adult Foster
                Care Home room details, features and services displayed in the
                Gallery of Homes and through this site contain marketing
                information that is sourced and updated by the Valued Care
                Providers. At the time this information is submitted we recognize
                that availability can change and consequently suggest you, the
                reader, user, or browser review and confirm all information.
              </p>
              <div style={{ height: "6px" }}></div>
              <p
                style={{
                  color: "black",
                  textAlign: "justify",
                  fontFamily: "Abadi MT Std",
                  fontSize: "small",
                  margin: "0px",
                }}
              >
                seniorHUBsolutions makes no representation and assumes no
                responsibility for the accuracy of information contained on, or
                available through this site. Contact the Valued Care Provider
                direct via phone or email with your inquiries.
              </p>
              
              {/* <div className="border-top border-bottom" /> */}
              <div style={{ height: "36px" }}></div>
              {/* <div style={{ height: "200px" }}></div> */}
            </div>
          </div>
        )}

        <div>
          <FooterMain />
          <Footer />
          {localStorage.getItem("isnative") &&
            localStorage.getItem("isnative") === "true" && <FooterMobile />}
        </div>
      </div>
    </IonContent>
  );
};

export default FAQs;
